import React, { useState, useRef, useEffect } from 'react'

import { useSelector, shallowEqual } from 'react-redux'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import styled from 'styled-components'

import colors from '../../css/colors.scss'
import { PrimaryButton, devices } from '../Common'


export const FeaturedEventsCarousel = ({ windowWidth }) => {
    const carousel = useRef(null)
    const [dotPosition, setDotPosition] = useState(0)

    const { featuredEvents } = useSelector(state => state.event, shallowEqual)

    const sliderSettings = {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        touchThreshold: 20,
    }

    useEffect(() => {
        if (carousel.current) setDotPosition((0.5625 * carousel.current.offsetWidth) + 20)
    }, [windowWidth, featuredEvents])

    return (
        <CarouselWrap ref={carousel}>
            {featuredEvents && dotPosition ? (
            <StyledCarousel
                dotPos={dotPosition}
                {...sliderSettings}
            >
                {featuredEvents.map(e => (
                    <Slide key={e.title}>
                        <CarouselImage imageratio={'56.25'} background={e.landscape_image} />
                        <Grid columns={2} totalmargin={15} margintop={40}>
                            <Column>
                                <CarouselHeader>{e.title}</CarouselHeader>
                                <EventSubtitle>{e.subtitle}</EventSubtitle>
                                <EventDate>{e.date[1]}&nbsp;{e.date[0]},&nbsp;{e.date[2]}<br />{e.start_time ? <>{e.start_time}<br /></> : ''}{e.category}<br />{e.ticket_price === 0 ? 'Free with General Admission' : `$${e.ticket_price}`}</EventDate>
                            </Column>
                            <Detail>
                                <Desc dangerouslySetInnerHTML={{ __html: e.description }} />
                                <PrimaryButton as={Link} to={e.url}>Learn More</PrimaryButton>
                            </Detail>
                        </Grid>
                    </Slide>
                ))}
            </StyledCarousel>
            ) : ''}
        </CarouselWrap>
    )
}

const CarouselHeader = styled.h3`
    line-height: 1.2;
`

const CarouselWrap = styled.div`
    margin: 0 0 50px;
    padding: 0 0 50px;
    border-bottom: 1px solid ${colors.darkGreen};
`

const EventDate = styled.div`
    margin-bottom: 20px;
`

const CarouselImage = styled.div`
    width: 100%;
    height: 0;
    padding-bottom: ${props => props.imageratio}%;
    background: url(${({ background }) => background}) no-repeat 50% 50%/cover;
    margin: 0 0 60px;

    @media ${devices.small} {
        padding-bottom: 100%;
    }
`

const Column = styled.div`
`

const Detail = styled(Column)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
`

const Grid = styled.div`
    display: flex;
    flex-direction: row;

    & > ${Column} {
        width: ${props => 100 / props.columns}%;
    }

    @media ${devices.small} {
        flex-direction: column;
        & > ${Column} {
            width: 100%;
        }
    }
`

const EventSubtitle = styled.div`
    margin: 0 0 30px;
    font-weight: 700;
`

const Desc = styled.div`
    margin: 0 0 30px;
`

const Slide = styled.div``

const StyledCarousel = styled(Slider)`
    .slick-dots {
        top: ${props => props.dotPos}px;
        height: 40px;

        li {
            display: inline-block;
            width: 16px;
            height: 16px;
            border-radius: 16px;
            border: 1px solid ${colors.darkGreen};
            margin-bottom: 30px;
            margin-right: 17px;
            cursor: pointer;

            &.slick-active {
                background: ${colors.yellow};
            }
        }

        @media ${devices.small} {
            top: calc((${props => props.dotPos}px / 0.5625) - 20px);
        }
    }
`
