import { lightscapeTypes } from '../_constants'

const initialState = {
    poiList: [],
}

export function lightscape(state = initialState, action) {
    switch (action.type) {
        case lightscapeTypes.POI_LIST_SUCCESS:
            return {
                ...state,
                poiList: action.payload,
            }
        default:
            return state
    }
}
