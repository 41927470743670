import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import styled from 'styled-components'

import colors from '../../css/colors.scss'
import {
    MenuLink,
} from '../Common'

export const DropdownNav = ({ menu, active }) => {
    const [open, setOpen] = useState(false)
    return (
        <Dropdown open={open}>
            <div onClick={() => setOpen(!open)}>{active.title}</div>
            {open &&
                <DropdownList>
                    {menu.map((menuPage, i) => (
                        <MenuListItem key={i}>
                            <MenuLink
                                // active={menuPage.active ? 1 : 0}
                                as={menuPage.url.includes('http') ? Link : 'a'}
                                to={menuPage.url}
                                href={menuPage.url}
                            >
                                {menuPage.title}
                            </MenuLink>
                        </MenuListItem>
                    )
                    )}
                </DropdownList>
            }
        </Dropdown>
    )
}

const Dropdown = styled.div`
    background-color: transparent;
    font-family: inherit;
    font-size: 17px;
    font-weight: bold;
    border-radius: 25px;
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px 9px;
    border: 1px solid ${colors.darkGreen};
    width: 100%;
    transition: height 0.2s ease-in;
    position: relative;
    margin: 20px 0;

    &:after {
        height: 12px;
        width: 21px;
        content: '';
        background: url(/static/images/sprite.svg) -34px -409px;
        position: absolute;
        top: 15px;
        right: 20px;
        pointer-events: none;
        transition: transform .3s;
        transform: rotate(${({ open }) => open ? '-180deg' : '0'})
    }
`

const DropdownList = styled.ul`
    margin-top: 5px;
    padding-left: 0;
`

const MenuListItem = styled.div`
    list-style: none;
    border-top: 1px solid ${colors.darkGreen};
    padding: 5px 0;
`
