import React from 'react'

import styled from 'styled-components'

import colors from '../../css/colors.scss'
// import { devices } from '../Common'


// TODO: add submit handler prop
export const TextInput = ({ placeholder, color, onSubmit, onChange, value, label, width, marginbottom, type='text', required=false }) => (
    <>
        {label && <Label>{label}</Label>}
        <InputBox onSubmit={onSubmit} color={color} width={width} marginbottom={marginbottom}>
            <input
                type={type}
                aria-label={label}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                required={required}
            />
            <input type="submit" />
        </InputBox>
    </>
)

const Label = styled.label`
    display: block;
    margin: 0 0 5px 4px;
    line-height: 1.2;
    font-weight: bold;
`

export const InputBox = styled.form`
    border: 1px solid ${({color=colors.darkGreen}) => color};
    border-radius: 50px;
    padding: 0 20px;
    position: relative;
    margin-bottom: ${({marginbottom=20}) => marginbottom}px;
    ${({width}) => width ? `width: ${width};` : ''}

    input {
        &[type="text"],
        &[type="email"],
        &[type="number"] {
            color: ${({color=colors.darkGreen}) => color};
            height: 43px;

            &::placeholder {
                color: ${({color=colors.darkGreen}) => color};
                opacity: .5;
            }

            &::-webkit-input-placeholder {
                color: ${({color=colors.darkGreen}) => color};
                opacity: .5;
            }
        }
    }

    input[type="submit"] {
        border-color: ${({color=colors.darkGreen}) => color};
        border-style: solid;
    }
`

export const FormError = styled.div`
    display: block;
    color: ${colors.red};
    font-weight: bold;
    font-size: 10px;
    letter-spacing: 0px;
    margin-bottom: 10px;
`

export const InlineFormError = styled(FormError)`
    display: inline;
`

export const Form = styled.form`
`

export const SubmitText = styled.span``

export const InputWrap = styled.div`
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .react-date-picker {
        width: 90%;

        .react-date-picker__wrapper {
            height: 43px;
            border: 1px solid ${colors.darkGreen};

            input {
                padding: 0;
                margin: 0;
                border: 0;
                height: 41px;
                top: -15px;
            }

            span {
                top: -15px;
                position: relative;
            }

            .react-date-picker__inputGroup {
                padding: 15px 20px 14px;
            }
        }

        .react-calendar {
            background-color: ${colors.lightGreen} !important;

            .react-calendar__tile {
                abbr {
                    color: ${colors.darkGreen};
                }

                &:disabled abbr {
                    opacity: 0.5;
                }
            }
        }

        .react-calendar__navigation__arrow,
        .react-calendar__tile,
        .react-calendar__navigation__label {
            &:hover, &:disabled, &:focus {
                background-color: ${colors.lightGreen};
            }
        }

        .react-date-picker__inputGroup__input:invalid {
            background-color: ${colors.lightGreen};
        }

        .react-calendar__month-view__weekdays__weekday {
            abbr {
                text-decoration: none;
            }
        }
    }
`

export const FormLabel = styled.label`
`

export const InputContainer = styled.div``

export const Input = styled.input`
    color: inherit;
    background-image: none;
    outline: none !important;
    width: 100%;
    border: 1px solid ${colors.darkGreen};
    border-radius: 25px;
    font-size: 16px;
    padding: 15px 40px 14px;
    background: transparent;

    &:active,
    &:focus,
    &:hover {
        outline: 0 !important;
        -webkit-appearance: none;
    }
`

export const TextArea = styled.textarea`
    color: inherit;
    border: 1px solid ${colors.darkGreen};
    height: 100px;
    width: 100%;
    resize: unset;
    font-size: 18px;
    background: transparent;

    &:active,
    &:focus,
    &:hover {
        outline: 0 !important;
        -webkit-appearance: none;
    }
`

export const Select = styled.select`
    color: inherit;
    background-image: none;
    border: 1px solid ${colors.darkGreen};
    background: transparent;
    border-radius: 25px;
    width: 100%;
    padding: 15px 40px 14px;
    font-size: 16px;
    appearance: none;
`

export const Option = styled.option`

`

export const FormGroup = styled.div`
    margin-bottom: 50px;

    &:last-of-type {
        margin-bottom: 0;
    }
`

export const TextAreaWrap = styled(InputWrap)`
    min-height: 120px;
    height: auto;
`

export const SelectWrap = styled.div`
    position: relative;
    ${({width}) => width ? `width: ${width};` : ''}
    ${({margin}) => margin ? `margin: ${margin};` : ''}

    &:after {
        height: 12px;
        width: 21px;
        content: '';
        background: url(/static/images/sprite.svg) -34px -409px;
        position: absolute;
        top: 20px;
        right: ${({right=15}) => `${right}px;`};
        pointer-events: none;
    }
`

export const SubmitMessage = styled.div`

`

export const Required = styled.span`
    color: ${colors.red};
    display: contents;
`

export const ExtraInfo = styled.div`
    font-style: italic;
    /* margin-bottom: 20px; */
`

export const Checkbox = styled.div`
    label {
        margin-left: 10px;
    }
    margin: 10px 0;
`

export const ButtonText = styled.button`
    display: block;
    padding: 5px 0 5px 0;
    background: rgba(0,0,0,0);
    border: 0;
    font-family: 'NB International Pro';
    font-size: 17px;
    font-weight: bold;
    color: ${colors.darkGreen};
    white-space: nowrap;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
`

export const validators = {
    required: value => value ? undefined : 'Required',
    email: value => !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(value) ? 'Invalid email address' : undefined,
    phoneNumber: value => value && !/^(0|[1-9][0-9]{9})$/i.test(value) ? 'Invalid phone number, it must be 10 digits' : undefined,
}
