import { formTypes } from '../_constants'
import { createAsyncAction } from '../_helpers/utils'
import { SpeakerRequest, TourRequest } from '../_services'

export const postSpeakerRequestForm = createAsyncAction(
    formTypes.POST_SPEAKER_FORM_REQUEST,
    formTypes.POST_SPEAKER_FORM_SUCCESS,
    SpeakerRequest.post,
    formTypes.POST_SPEAKER_FORM_FAILURE,
)

export const postTourRequestForm = createAsyncAction(
    formTypes.POST_SPEAKER_FORM_REQUEST,
    formTypes.POST_SPEAKER_FORM_SUCCESS,
    TourRequest.post,
    formTypes.POST_SPEAKER_FORM_FAILURE,
)
