import React, { Component, Fragment } from 'react'

import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'

import {
    Form,
    FormError,
    FormGroup,
    PrimaryButton,
    TextField,
    validators,
    SelectField,
    SubmitMessage,
} from '../'
import { submitSupportForm, resetSupportEmailMessage } from '../../_actions/'


class SupportEmailForm extends Component {
    state = {
        options: this.props.selectCategories,
        toEmail: this.props.toEmail,
        subject: this.props.subject,
        fromName: this.props.fromName,
    }

    submit = data => {
        const { dispatch } = this.props
        data.to_email = this.state.toEmail
        data.subject = this.state.subject
        data.from_name = this.state.fromName
        dispatch(submitSupportForm(data))
    }

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(resetSupportEmailMessage())
    }

    render() {
        const { handleSubmit, submitting, error, submitFailed, formData, valid, submitError, submitMessage } = this.props
        const { required, email, phoneNumber } = validators
        const formErrors = submitFailed && formData && formData.syncErrors ? !!Object.keys(formData.syncErrors).length : false

        return (
            <Form onSubmit={handleSubmit(this.submit)}>
                {submitMessage ? (
                    <SubmitMessage>{submitMessage}</SubmitMessage>
                ) : (
                    <Fragment>
                        <FormGroup>
                            <Field
                                name='name'
                                label='Name'
                                auto='name'
                                component={TextField}
                                required
                                validate={[required]}
                            />
                            <Field
                                name='email'
                                label='Email'
                                auto='email'
                                type='email'
                                component={TextField}
                                required
                                validate={[required, email]}
                            />
                            <Field
                                name='phone'
                                label='Telephone Number'
                                auto='phone'
                                type='tel'
                                component={TextField}
                                validate={[phoneNumber]}
                            />
                            <Field
                                name='organization'
                                label='Organization/Company Name'
                                component={TextField}
                            />
                            <Field
                                name='interest'
                                label='I am interested in…'
                                component={SelectField}
                                options={this.state.options}
                                required
                                validate={[required]}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormError>
                                {formErrors || submitError || error ? <strong>{submitError || 'Please check all fields'}</strong> : ''}
                            </FormError>
                            <PrimaryButton type='submit' disabled={submitting || !valid} width='200px'>
                                Submit
                            </PrimaryButton>
                        </FormGroup>
                    </Fragment>
                )}
            </Form>
        )
    }
}


const SupportEmailFormWithError = reduxForm({form: 'supportEmailForm'})(SupportEmailForm)
const ConnectedSupportEmailFormWithError = connect(state => ({
    formData: state.form.supportEmailForm,
    submitError: state.support.supportEmail.error,
    submitMessage: state.support.supportEmail.message,
}))(SupportEmailFormWithError)
export { ConnectedSupportEmailFormWithError as SupportEmailForm }
