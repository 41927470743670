import { contentTypes } from '../_constants'
import { BrowserDetect } from '../_helpers/utils'

const initialState = {
    page: {},
    previousURL: '',
    menu: [],
    ie: BrowserDetect() === 'Explorer',
    menuOpen: false,
    notFound: false,
    newsletterMessage: '',
    newsletterError: '',
    weather: {},
    news: [],
    newsPage: 0,
    videos: [],
    searchResults: [],
}

export function content(state = initialState, action) {
    switch (action.type) {
        case contentTypes.PAGE_DATA_SUCCESS:
            return {
                ...state,
                previousURL: action.payload.url,
                page: action.payload,
                notFound: false,
            }
        case contentTypes.CLEAR_PAGE:
            if (
                window.location.pathname.split('/')[1] === state.previousURL.split('/')[1]
            ) {
                return {
                    ...state,
                    page: {
                        page_menu: state.page.page_menu,
                    },
                }
            }
            return {
                ...state,
                page: {},
            }
        case contentTypes.PAGE_DATA_FAILURE:
            return {
                ...state,
                notFound: true,
            }
        case contentTypes.MENU_DATA_SUCCESS:
            return {
                ...state,
                menu: action.payload,
            }
        case contentTypes.SUBSCRIBE_SUCCESS:
            return {
                ...state,
                newsletterMessage: action.payload,
                newsletterError: '',
            }
        case contentTypes.SUBSCRIBE_FAILURE:
            return {
                ...state,
                newsletterMessage: '',
                newsletterError: action.payload,
            }
        case contentTypes.TOGGLE_MENU:
            return {
                ...state,
                menuOpen: action.payload,
            }
        case contentTypes.WEATHER_SUCCESS:
            return {
                ...state,
                weather: action.payload[0],
            }
        case contentTypes.NEWS_LIST_SUCCESS:
            return {
                ...state,
                news: action.payload,
            }
        case contentTypes.LEARN_VIDEO_LIST_SUCCESS:
            return {
                ...state,
                videos: action.payload,
            }
        case contentTypes.SEARCH_SUCCESS:
            return {
                ...state,
                searchResults: action.search,
                query: action.query,
            }
        case contentTypes.UPDATE_QUERY:
            return {
                ...state,
                query: action.query,
            }
        default:
            return state
    }
}
