import React, { useState, useRef, useEffect } from 'react'

import Slider from 'react-slick'
import styled from 'styled-components'

import { VideoModal } from '../../'
import colors from '../../../css/colors.scss'
import { ArticleTextWrap, UpperHeader, devices } from '../../Common'


export const SimpleCarousel = ({ slides, content, smallTitle }) => {
    //TODO CHECK HEIGHT

    const [showModal, setShowModal] = useState(false)
    const [videoUrl, setVideoUrl] = useState('')
    const carousel = useRef()

    const openModal = video_url => {
        setShowModal(true)
        setVideoUrl(video_url)
    }

    const closeModal = () => setShowModal(false)

    const sliderSettings = {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
        touchThreshold: 20,
    }

    useEffect(() => {
    }, [])

    return (
        <CarouselContainer ref={carousel}>
            {smallTitle ? <UpperHeader marginbottom='10'>{smallTitle}</UpperHeader> : ''}

            {slides ? (
                <StyledCarousel {...sliderSettings}>
                    {slides && slides.map(slide =>
                        <Slide key={slide.image}>
                            <SlideImage
                                image={slide.image_large ? slide.image_large : slide.image}
                                aria-label={slide.alt_text}
                            />

                            {slide.video_url ? (
                                <VideoThumbnail image={slide.video_thumbnail || slide.youtube_thumbnail || slide.vimeo_thumbnail} alt={slide.alt_text}>
                                    <VideoPlayButton onClick={() => openModal(slide.video_url)} />
                                </VideoThumbnail>
                            ) : ''}

                            {/* {slide.caption && <SlideCaption>{slide.caption}</SlideCaption>} */}
                        </Slide>
                    )}
                </StyledCarousel>
            ) : ''}

            {content ? <ArticleTextWrap margintop='10' dangerouslySetInnerHTML={{ __html: content }} /> : ''}

            <VideoModal
                url={videoUrl}
                showModal={showModal}
                closeModal={closeModal}
            />
        </CarouselContainer>
    )
}

const CarouselContainer = styled.div`
    margin-bottom: 30px;
    position: relative;

    @media ${devices.small} {
        margin-bottom: 20px;
    }
`

const StyledCarousel = styled(Slider)`
    .slick-dots {
        margin: 26px 0 0;
        position: unset;
        li {
            display: inline-block;
            vertical-align: top;
            width: 16px;
            height: 16px;
            margin: 0 17px 0 0;
            border-radius: 16px;
            border: 1px solid ${colors.darkGreen};
            cursor: pointer;
            &.slick-active {
                background: ${colors.yellow};
            }
        }
    }
`

const Slide = styled.div`
    position: relative;
`

const SlideImage = styled.div`
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    background: url(${props => props.image}) no-repeat 50% 50%/cover;
`

/*
const SlideCaption = styled.div`
    margin: 10px 0 0;
    text-align: left;
    font-style: italic;
`
*/

export const VideoThumbnail = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url(${({ image }) => image}) 50% 50%/cover;
`

export const VideoPlayButton = styled.div`
    height: 55px;
    width: 47px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -27px;
    margin-left: -23px;
    background: url(/static/images/sprite.svg) -1px -570px;
    cursor: pointer;
`
