import React, { useState, useEffect, useRef } from 'react'

import moment from 'moment'
import { DateRangePicker, isInclusivelyAfterDay } from 'mwm-react-dates'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import styled from 'styled-components'

import { searchEvents, setFilteredEventsQuery, clearFilteredEvents } from '../../_actions'
import { buildEventSearchQuery, useOnClickOutside } from '../../_helpers'
import colors from '../../css/colors.scss'
import { ButtonText, devices } from '../Common'
import 'mwm-react-dates/initialize'
import 'mwm-react-dates/lib/css/_datepicker.css'


const CalendarFilter = ({ options, updateQuery, selectedOption, setSelectedOption, index }) => {
    const [isOpen, setIsOpen] = useState(false)
    const toggling = () => setIsOpen(!isOpen)
    const ref = useRef(null)
    const onOptionClicked = value => () => {
        setSelectedOption(value[1])
        updateQuery(value[0])
        setIsOpen(false)
    }

    useOnClickOutside(ref, () => setIsOpen(false))

    return (
        <DropdownFilter ref={ref}>
            <DropDownHeader onClick={toggling}>{selectedOption} <DropDownIcon /></DropDownHeader>
            {isOpen ? (
                <DropDownList index={index}>
                    {options.filter(e => e[1] !== selectedOption).map(e => <ListItem onClick={onOptionClicked(e)} key={e[1]}>{e[1]}</ListItem>)}
                </DropDownList>
            ) : ''}
        </DropdownFilter>
    )
}

export const CalendarFilters = () => {
    const dispatch = useDispatch()
    const { eventCategories, eventLocations, current } = useSelector(state => state.event, shallowEqual)
    const emptyQuery = {
        dates: { start: '', end: '' },
        category: '',
        location: '',
        current,
    }
    const initialDates = { startDate: null, endDate: null }
    const [query, setQuery] = useState(emptyQuery)
    const [dates, setDates] = useState(initialDates)
    const [focusedInput, setFocusedInput] = useState(null)
    const [selectedCategoryOption, setSelectedCategoryOption] = useState('All Categories')
    const [selectedLocationOption, setSelectedLocationOption] = useState('All Locations')
    const updateQuery = (key, value) => setQuery({ ...query, [key]: value })
    const getFilteredDates = dates => {
        if (dates.startDate) {
            const format = 'MM/DD/YYYY'
            const start = dates.startDate.format(format)
            const end = dates.endDate.format(format)
            updateQuery('dates', { start, end })
        }
    }
    const clearFilter = () => {
        setQuery(emptyQuery)
        setDates(initialDates)
        setSelectedCategoryOption('All Categories')
        setSelectedLocationOption('All Locations')
        dispatch(clearFilteredEvents())
    }

    useEffect(() => {
        clearFilter()
    }, [current])

    useEffect(() => {
        const { dates, location, category } = query
        if (dates.start || location || category) {
            const start = dates.start.replace(/\//g, '-')
            const end = dates.end.replace(/\//g, '-')
            const q = buildEventSearchQuery({ ...query, dates: { start, end } })
            dispatch(searchEvents({ query: q }))
            dispatch(setFilteredEventsQuery(q))
        } else if (!current) {
            const q = buildEventSearchQuery({ ...query })
            dispatch(searchEvents({ query: q }))
        }
    }, [query, current])

    return (
        <FilterContainer>
            <CalendarFilter
                options={[['', 'All Categories'], ...eventCategories]}
                selectedOption={selectedCategoryOption}
                setSelectedOption={setSelectedCategoryOption}
                updateQuery={value => updateQuery('category', value)}
                index={1}
            />
            <CalendarFilter
                options={[['', 'All Locations'], ...eventLocations]}
                selectedOption={selectedLocationOption}
                setSelectedOption={setSelectedLocationOption}
                updateQuery={value => updateQuery('location', value)}
                index={2}
            />
            <Filter onClick={() => setFocusedInput('startDate')}>{query.dates.start ? `${query.dates.start} — ${query.dates.end}` : 'All Dates'}<CalendarDropDownIcon /></Filter>
            <StyledDatePicker>
                <DateRangePicker
                    startDate={dates.startDate}
                    startDateId='start'
                    endDate={dates.endDate}
                    endDateId='end'
                    onDatesChange={({ startDate, endDate }) => setDates({ startDate, endDate })}
                    focusedInput={focusedInput}
                    onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                    numberOfMonths={1}
                    enableOutsideDays={true}
                    onClose={getFilteredDates}
                    isOutsideRange={day => current ? !isInclusivelyAfterDay(day, moment()) : isInclusivelyAfterDay(day, moment())}
                />
            </StyledDatePicker>
            <FilterClearButton onClick={clearFilter}>Clear</FilterClearButton>
        </FilterContainer>
    )
}

const FilterClearButton = styled(ButtonText)`
    margin: 0 15px;
    @media ${devices.small} {
        margin: 15px 0 0;
        text-align: left;
    }
`

const Filter = styled.div`
    width: 100%;
    height: 40px;
    margin: 0 0 0 15px;
    padding: 9px 0 0 0;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border: none;
    border-bottom: 1px solid ${colors.darkGreen};
    font-family: 'NB International Pro';
    font-size: 17px;
    font-weight: bold;
    @media ${devices.small} {
        margin: 0;
    }
`

const DropdownFilter = styled(Filter)`
    position: unset;
`

const FilterContainer = styled.div`
    display: flex;
    margin: 0 -15px;
    position: relative;

    @media ${devices.small} {
        flex-direction: column;
        margin: 0;
    }
`

const DropDownHeader = styled('div')`
    display: flex;
    justify-content: space-between;
`

const DropDownIcon = styled.div`
    display: block;
    width: 22px;
    height: 22px;
    background: url('/static/images/sprite.svg') no-repeat -34px -403px;
`

const CalendarDropDownIcon = styled(DropDownIcon)`
    position: absolute;
    top: 10px;
    right: 0;
`

const DropDownList = styled.ul`
    position: absolute;
    width: calc(100% / 3 - 33px);
    z-index: 10;
    top: 39px;
    padding: 13px 0 0 20px;
    margin: 0;
    background: ${colors.lightGreen};
    border: 1px solid ${colors.darkGreen};
    /* font-size: 1.3rem; */
    font-size: 16px;
    font-weight: 500;
    &:first-child {
        padding-top: 0.8em;
    }

    @media ${devices.small} {
        width: 100%;
        top: ${({ index }) => 39 * index}px;
    }
`

const ListItem = styled.li`
    list-style: none;
    margin-bottom: 10px;

    /* &:hover {
        background-color: ${colors.darkGreen};
    } */
`

const StyledDatePicker = styled.div`
    position: relative;

    .DateInput, .DateRangePickerInput_arrow, .DayPickerKeyboardShortcuts_buttonReset {
        display: none;
    }

    .DateRangePicker {
        position: absolute;
        left: -317px;
        top: -20px;
        z-index: 10;

        @media screen and (max-width: 800px) {
            position: absolute;
            left: calc(0% - 15px);
        }

        .DateRangePicker_picker {
            @media screen and (max-width: 800px) {
                left: 0;
            }

            .DayPicker_weekHeader {
                top: 47px;
                margin-left: 3px;
                padding: 0 !important;

                .DayPicker_weekHeader_ul {
                    padding-bottom: 5px;
                    border-bottom: 1px solid ${colors.darkGreen};
                    width: 338px;

                    .DayPicker_weekHeader_li {
                        color: ${colors.darkGreen};
                        margin-right: 10px;

                        small {
                            display: none;
                        }

                        &:nth-of-type(1)::after, &:nth-of-type(7)::after {
                            content: 'S';
                        }

                        &:nth-of-type(2)::after {
                            content: 'M';
                        }

                        &:nth-of-type(3)::after, &:nth-of-type(5)::after {
                            content: 'T';
                        }

                        &:nth-of-type(4)::after {
                            content: 'W';
                        }

                        &:nth-of-type(6)::after {
                            content: 'F';
                        }

                        &:nth-of-type(1) {
                            width: 31px !important;
                            margin-right: 14px;
                            margin-left: 7px;
                        }

                        &:nth-of-type(6) {
                            margin-right: 12px;
                        }

                        &:nth-of-type(7) {
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        .DayPicker {
            border: 1px solid ${colors.darkGreen};
            background: ${colors.lightGreen};
            border-radius: 0;
            width: 365px !important;

            &.DayPicker__withBorder {
                box-shadow: none;
            }

            .DayPicker_focusRegion, .DayPicker_transitionContainer {
                width: 363px !important;
                background: ${colors.lightGreen};
                border-radius: 0;

                .DayPickerNavigation {
                    .DayPickerNavigation_button {
                        border: 0;
                        background: ${colors.lightGreen};

                        &.DayPickerNavigation_leftButton__horizontalDefault {
                            top: 9px;
                            left: 0;
                        }

                        &.DayPickerNavigation_rightButton__horizontalDefault {
                            top: 9px;
                            right: 0;
                        }
                    }

                    .DayPickerNavigation_svg__horizontal path {
                        fill: ${colors.darkGreen};
                    }
                }

                .CalendarMonthGrid {
                    background: ${colors.lightGreen};

                    .CalendarMonth {
                        padding: 0px !important;
                        background: ${colors.lightGreen};

                        .CalendarMonthGrid {
                            background: ${colors.lightGreen};
                        }

                        .CalendarMonth_caption {
                            padding-top: 17px;
                            padding-bottom: 42px;
                            text-transform: uppercase;
                            color: ${colors.darkGreen};
                            font-size: 13px;
                        }

                        .CalendarMonth_table {
                            width: 345px;
                        }

                        .CalendarDay {
                            font-weight: 300;
                            vertical-align: middle;
                            border: 0;
                            line-height: 1;
                            height: 37px !important;
                            width: 49px !important;
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            color: ${colors.darkGreen};
                            background: ${colors.lightGreen};

                            &:hover {
                                background: ${colors.green};
                            }

                            &.CalendarDay__selected {
                                color: ${colors.darkGreen};
                                background: ${colors.lightGreen};

                                &.CalendarDay__selected_start,
                                &.CalendarDay__selected_end {
                                    .circle-container {
                                        background: ${colors.darkGreen};
                                        height: 100%;
                                        width: 100%;
                                        display: flex;
                                        align-items: center;
                                    }

                                    .date-circle {
                                        width: 33px;
                                        height: 33px;
                                        background: ${colors.lightGreen};
                                        vertical-align: middle;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        border-radius: 50%;
                                    }
                                }

                                &.CalendarDay__selected_start {
                                    border-top-left-radius: 50px;
                                    border-bottom-left-radius: 50px;

                                    .circle-container {
                                        border-top-left-radius: 40px;
                                        border-bottom-left-radius: 40px;
                                        margin-left: 8px;
                                    }

                                    .date-circle {
                                        margin-left: 2px;
                                    }
                                }

                                &.CalendarDay__selected_end {
                                    border-top-right-radius: 50px;
                                    border-bottom-right-radius: 50px;

                                    .circle-container {
                                        border-top-right-radius: 40px;
                                        border-bottom-right-radius: 40px;
                                        justify-content: flex-end;
                                    }

                                    .date-circle {
                                        margin-right: 2px;
                                    }
                                }
                            }

                            &.CalendarDay__selected_span {
                                color: ${colors.lightGreen};
                                background: ${colors.darkGreen};
                            }

                            &.CalendarDay__hovered_span {
                                background: ${colors.darkGreen};
                                color: ${colors.lightGreen};
                            }

                            &.CalendarDay__blocked_out_of_range {
                                color: ${colors.green};

                                &:hover {
                                    background: ${colors.lightGreen};
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
