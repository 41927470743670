import React from 'react'

import DocumentMeta from 'react-document-meta'
import styled from 'styled-components'


export const NotFound = () => (
    <NotFoundContainer>
        <DocumentMeta title='Houston Botanic Garden | Not Found' />
        <h2>Not Found</h2>
        <p>The page you&apos;re looking for does not exist.</p>
    </NotFoundContainer>
)

const NotFoundContainer = styled.div`
    height: calc(100vh - 435px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
