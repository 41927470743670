import styled, { css } from 'styled-components'

import colors from '../../css/colors.scss'


const devices = {
    tiny: '(max-width: 480px)',
    small: '(max-width: 740px)',
    medium_portrait: '(max-width: 940px)',
    medium: '(max-width: 1040px)',
    large: '(max-width: 1340px)',
    extralarge: '(max-width: 1420px)',
}

const minDevices = {
    tiny: '(min-width: 480px)',
    small: '(min-width: 740px)',
    medium_portrait: '(min-width: 940px)',
    medium: '(min-width: 1040px)',
    large: '(min-width: 1340px)',
    extralarge: '(min-width: 1420px)',
}

const margins = css`
    margin-top: ${({ margintop = 0 }) => margintop}px;
    margin-bottom: ${({ marginbottom = 0 }) => marginbottom}px;
    margin-left: ${({ marginleft = 0 }) => marginleft}px;
    margin-right: ${({ marginright = 0 }) => marginright}px;
    @media ${devices.small} {
        ${({ margintop }) => margintop && `margin-top: ${margintop / 2}px;`}
        ${({ marginbottom }) => marginbottom && `margin-bottom: ${marginbottom / 2}px;`}
    }
`

const paddings = css`
    padding-top: ${({ paddingtop = 0 }) => paddingtop}px;
    padding-bottom: ${({ paddingbottom = 0 }) => paddingbottom}px;
    @media ${devices.small} {
        ${({ paddingtop }) => paddingtop && `padding-top: ${paddingtop / 2}px;`}
        ${({ paddingbottom }) => paddingbottom && `padding-bottom: ${paddingbottom / 2}px;`}
    }
`

const richtext = css`
    font-family: 'NB International Pro';
    font-size: 17px;
    line-height: 1.4;
    .richtext-header {
        margin: 0 0 15px;
        font-family: 'Canela';
        font-size: 31px;
        &:last-child {
            margin-bottom: 0;
        }
        & + *:not(.richtext-header) {
            margin-top: 50px;
        }
    }
    .richtext-smallest {
        font-size: 17px;
    }
    p {
        margin: 0 0 15px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    ul,
    ol {
        margin: 0 0 0 20px;
        padding: 0;
        &:last-child {
            margin-bottom: 0;
        }
    }
    li {
        margin: 0 0 10px;
        padding: 0;
        &:last-child {
            margin-bottom: 0;
        }
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
        width: auto !important;
        th, td {
            padding: 5px;
            border: 1px solid #414042;
        }
    }
    a {
        text-decoration: underline;
        color: ${colors.darkGreen};
    }
    @media ${devices.small} {
        font-size: calc(17px * .9);
        .richtext-smallest {
            font-size: calc(17px * .9);
        }
        .richtext-header {
            font-size: calc(31px * .9);
        }
    }
`

const Container = styled.div`
    display: block;
    width: 100%;
    max-width: calc(1180px + 80px + 80px);
    margin-left: auto;
    margin-right: auto;
    padding-left: 80px;
    padding-right: 80px;

    ${({no_margins}) => no_margins && `
        padding-left: 15px;
        padding-right: 15px;
    `}

    @media ${devices.large} {
        max-width: calc(1180px + 40px + 40px);
        padding-left: 40px;
        padding-right: 40px;
    }

    @media ${devices.medium} {
        max-width: calc(1180px + 20px + 20px);
        padding-left: 20px;
        padding-right: 20px;
    }

    @media ${devices.small} {
        padding-left: 0;
        padding-right: 0;
    }
`

const ContainerFull = styled.div`
    ${({page_menu}) => !page_menu && `
        margin-left: calc(-120px + 15px);
        margin-right: calc(-120px + 15px);

        @media ${devices.extralarge} {
            margin-left: calc(-80px + 15px);
            margin-right: calc(-80px + 15px);
        }

        @media ${devices.large} {
            margin-left: calc(-40px + 15px);
            margin-right: calc(-40px + 15px);
        }

        @media ${devices.medium} {
            margin-left: calc(-20px + 15px);
            margin-right: calc(-20px + 15px);
        }
    `}
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: space-between;

    ${props => props.margintop && `
        margin-top: ${props.margintop}px;
    `}

    ${props => props.marginbottom && `
        margin-bottom: ${props.marginbottom}px;
    `}

    @media ${devices.small} {}
`

const MenuColumn = styled.div`
    width: 220px;

    @media ${devices.medium} {
        width: 200px;
    }

    @media ${devices.small} {}
`

const ContentWrap = styled.div`
    ${props => props.has_menu ? `
        width: calc(100% - 220px - 60px);

        @media ${devices.medium} {
            width: calc(100% - 200px - 30px);
        }
    ` : `
        width: 100%;
    `}

    @media ${devices.small} {
        width: 100%;
        padding: 0 15px;
    }
`

const Image = styled.img`
    position: relative;
    display: block;
    vertical-align: middle;
`

const tabletWidthCss = css`
    @media ${devices.tablet} {
        width: ${({ tabletWidth=100 }) => tabletWidth}%;
    }
`

const GridColumn = styled.div`
    position: relative;
    display: inline-block;
    width: ${({ width=100 }) => width}%;
    ${({ floated }) => floated
        ? floated === 'right'
            ? 'margin-left: auto;'
            : 'margin-rigth: auto;'
        : ''
    }
    ${({ tabletWidth }) => tabletWidth && tabletWidthCss}
    @media ${devices.small} {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    ${margins}
`

const tabletColumnsCss = css`
    @media ${devices.tablet} {
        width: ${({ columnsTablet=1 }) => 100/columnsTablet}%;
    }
`

const columnsCss = css`
    & > ${GridColumn} {
        width: ${({ columns=1, totalmargin }) => totalmargin ? `calc(${100/columns}% - ${Math.ceil(totalmargin/columns)}px)` : `${100/columns - 2}%`};
        ${({ columnsTablet }) => columnsTablet && tabletColumnsCss}
        @media ${devices.small} {
            width: 100%;
        }
    }
`

const Grid = styled.div`
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap;
    align-items: stretch; */
    ${props => props.width ? 'width:' + props.width + ';' : ''}
    ${margins}
    /* ${({ columns }) => columns && columnsCss} */

    margin-left: -30px;
    & > ${GridColumn} {
        width: ${({ columns=1 }) => `calc(${100/columns}%)`};
    }

    @media ${devices.small} {
        flex-direction: column;
        margin-left: 0;
        & > ${GridColumn} {
            width: 100%;
        }
    }
`

Grid.Column = GridColumn

const Hero = styled.div`
    height: 70vh;
    max-height: 600px;
    width: 100%;
    background: center / cover no-repeat url(${props => props.background});
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`

const ArticleWrapColumn = styled(Grid.Column)`
    @media (min-width: 800px) {
        width: 100%;
    }
`

export {
    Container,
    ContainerFull,
    Row,
    Image,
    Grid,
    minDevices,
    devices,
    margins,
    paddings,
    richtext,
    ContentWrap,
    Hero,
    MenuColumn,
    ArticleWrapColumn,
}
