import React, { Fragment, useState, useRef, useEffect } from 'react'

import styled from 'styled-components'

import { useEventListener } from '../../../_helpers'
import { devices, Grid } from '../../Common'
import { SocialIcons } from './SocialIcons'


export const Instagram = ({ images, title, text, mobile }) => {
    const [height, setHeight] = useState(0)

    const ref = useRef()

    const resizeImages = () => {
        const width = ref.current.offsetWidth
        const columns = width > 900 ? 3 : 2
        let height = (width / columns)
        if (columns === 3) height -= 40
        else if (width > 650) height -= 105
        else height *= columns
        setHeight(height)
    }

    useEffect(() => resizeImages(), [])

    useEventListener('resize', resizeImages)

    if (mobile) images = images.slice(0, 6)

    return (
        <Fragment>
            <Header>{title}</Header>
            <TextBox>
                <div style={{ flex: '1 0 50%' }} dangerouslySetInnerHTML={{ __html: text }} />
                <Icons><SocialIcons /></Icons>
            </TextBox>
            <ImageGrid columns={1} columnsTablet={1} ref={ref}>
                {images && images.map((e, i) => <Image key={i} height={height} image={e.image} alt={e.alt_text || e.caption} />)}
            </ImageGrid>
        </Fragment>
    )
}

const ImageGrid = styled(Grid)`
    /* padding: 0 35px; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;

    @media only screen and (max-width: 650px) {
        padding: 20px 15px;
        flex-direction: column;
    }
`

const Image = styled.div`
    background: center / cover no-repeat url(${({ image }) => image});
    /* width: ${({ height }) => Math.floor(height)}px;
    height: ${({ height }) => Math.floor(height)}px; */
    width: 100%;
    height: 0;
    padding-bottom: 30%;
    /* flex: 0.33 1 33%; */
    max-width: calc(33% - 10px);
    margin-top: 20px;

    @media ${devices.small} {
        max-width: 100%;
        padding-bottom: 100%;
    }
`

const Header = styled.h2`
    @media ${devices.small} {
        margin-left: 20px;
        margin-top: 20px;
        font-size: 50px;
    }
`

const Icons = styled.div`
    display: flex;
    @media ${devices.small} {
        margin-top: 20px;
    }
`

const TextBox = styled.div`
    display: flex;
    justify-content: space-between;

    p {
        width: 60%;
    }

    @media ${devices.small} {
        flex-direction: column;
        padding: 0 20px;

        p {
            width: 100%;
        }
    }
`
