import React from 'react'

import DocumentMeta from 'react-document-meta'


export const Meta = ({ page }) => {
    const meta = {
        title: `Houston Botanic Garden | ${page.meta_title || page.title}`,
        description: page.meta_description,
        canonical: page.meta_cannonical_url,
        meta: {
            charset: 'utf-8',
            name: {
                keywords: page.meta_keywords
            }
        }
    }

    return <DocumentMeta {...meta} />
}
