import React, { useEffect } from 'react'

import moment from 'moment'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import styled from 'styled-components'

import { PlantCategory } from '..'
import { loadPlantList } from '../../_actions'
import { useWindowSize } from '../../_helpers'
import colors from '../../css/colors.scss'
import { devices } from '../Common'
import { PageWithMenu } from '../Content'


const current_month = parseInt(moment().format('M'))

const BloomSeason = ({ season_name, plants }) => {
    const { width } = useWindowSize()

    const monthNames = {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December',
    }

    const season_month_numbers = {
        'Winter': [12, 1, 2],
        'Spring': [3, 4, 5],
        'Summer': [6, 7, 8],
        'Fall': [9, 10, 11]
    }

    const gardenColors = [
        colors.red,
        colors.yellow,
        colors.blue,
        colors.green,
        colors.pink,
        colors.red,
    ]

    return (
        <SeasonWrap>
            <SeasonName>{season_name || 'Season Name'}</SeasonName>
            <Months>
                {season_month_numbers[season_name].map(m =>
                    <Month key={m}>{width <= 740 ? monthNames[m].substring(0, 3) : monthNames[m]}</Month>
                )}
            </Months>

            {plants && Object.entries(plants).map(([garden, plants], i) =>
                plants.length ?
                    <PlantCategory
                        key={i}
                        color={gardenColors[i] || colors.red}
                        category_name={garden}
                        plants={plants}
                        months={season_month_numbers[season_name]}
                    /> : ''
            )}
        </SeasonWrap>
    )
}

const BloomList = () => {
    const dispatch = useDispatch()
    const { plantList } = useSelector(state => state.garden, shallowEqual)
    let seasons

    useEffect(() => {
        dispatch(loadPlantList())
    }, [])

    if ([12, 1, 2].includes(current_month)) {
        seasons = ['Winter', 'Spring', 'Summer', 'Fall']
    } else if ([3, 4, 5].includes(current_month)) {
        seasons = ['Spring', 'Summer', 'Fall', 'Winter']
    } else if ([6, 7, 8].includes(current_month)) {
        seasons = ['Summer', 'Fall', 'Winter', 'Spring']
    } else if ([9, 10, 11].includes(current_month)) {
        seasons = ['Fall', 'Winter', 'Spring', 'Summer']
    }

    return seasons.map(s => <BloomSeason key={s} plants={plantList[s.toLowerCase()]} season_name={s} />)
}

const SeasonWrap = styled.div`
    margin-bottom: 50px;
    &:last-child {
        margin-bottom: 0;
    }
`

const SeasonName = styled.h2`
    margin: 0 0 20px;
    text-align: center;
`

const Months = styled.div`
    display: flex;
    width: calc(100% - 200px);
    margin: 0 0 10px auto;

    @media ${devices.small} {
        width: 45%;
    }
`

const Month = styled.div`
    width: 33.3333%;
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
        @media ${devices.small} {
            font-size: 14px;
        }

`
const BloomListPage = () => <PageWithMenu ChildComponent={BloomList} />
export default BloomListPage
