import React, { useRef, useState } from 'react'

import Slider from 'react-slick'
import styled from 'styled-components'

import { VideoModal } from '../../'
import { useWindowSize } from '../../../_helpers'
import colors from '../../../css/colors.scss'
import { ArticleTextWrap, UpperHeader, devices, Container, PrimaryButton } from '../../Common'


const SlideTextGroup = (slide) => {
    return (
        <>
            {location.pathname === '/' && slide.title === 'Lightscape' || location.pathname === '/lightscape/' ? (
                <CarouselLogo aria-label={slide.title} />
            ) : (
                <CarouselHeader>{slide.title}</CarouselHeader>
            )}
            <UpperHeader marginbottom={10}>{slide.subtitle}</UpperHeader>
            {location.pathname !== '/lightscape/' && <SlideBody dangerouslySetInnerHTML={{ __html: slide.body }} />}
        </>
    )
}

export const HeroCarousel = ({ slides, content, gradient=false }) => {
    const sliderRef = useRef(null)

    const [activeSlide, setActiveSlide] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const [videoUrl, setVideoUrl] = useState('')

    const { width } = useWindowSize()

    const openModal = video_url => {
        setShowModal(true)
        setVideoUrl(video_url)
    }

    const closeModal = () => setShowModal(false)

    const sliderSettings = {
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
        speed: 1500,
        touchThreshold: 20,
        beforeChange: (current, next) => {
            setActiveSlide(next)
        },
    }

    const getVimeoId = (url) => {
        const match = /vimeo.*\/(\d+)/i.exec(url)
        if (match) {
            return match[1]
        }
    }

    let carouselHeight
    if (width <= 740) carouselHeight = 280
        else if (width < 900) carouselHeight = 480
        else if (width < 1240) carouselHeight = 647
        else if (width < 2200) carouselHeight = 800
        else carouselHeight = 1100

    return (
        <CarouselContainer>
            <StyledCarousel
                ref={sliderRef}
                height={carouselHeight - 155}
                {...sliderSettings}
            >
                {slides.map(slide =>
                    <Slide
                        key={slide.image}
                        height={carouselHeight - 155}
                        background={slide.image}
                        role='img'
                        aria-label={`${slide.title ? `${slide.title}: ` : ''}${slide.subtitle ? `${slide.subtitle}.  ` : ''}${slide.body}`}
                    >
                        <Gradient gradient={gradient}>
                            {slide.video_url && location.pathname === '/lightscape/' && <VideoWrap>
                                <Video frameborder="0" src={`https://player.vimeo.com/video/${getVimeoId(slide.body)}?autoplay=1&loop=1&autopause=0&background=1`}/>
                            </VideoWrap>}

                            {slide.video_url && <VideoPlayButton onClick={() => openModal(slide.video_url)} />}

                            <Caption>{slide.caption}</Caption>
                            <SlideText>
                                {slide.external_url ?
                                    <a href={slide.external_url} target="_blank" rel="noopener noreferrer">
                                        {SlideTextGroup(slide)}
                                    </a>
                                : ''}
                                {slide.linked_page ?
                                    <a href={slide.linked_page}>
                                        {SlideTextGroup(slide)}
                                    </a>
                                : ''}
                                {!slide.linked_page && !slide.external_url ?
                                    SlideTextGroup(slide)
                                : ''}
                                {slide.external_url && location.pathname === '/lightscape/' && <Button as='a' target="_blank" href={slide.external_url}>Get Lightscape Tickets</Button>}
                            </SlideText>
                        </Gradient>
                    </Slide>
                )}
            </StyledCarousel>
            {slides.length > 1 ?
                <CarouselCounter>
                    <CarouselCounterWrap>
                        <LeftArrow onClick={() => sliderRef.current.slickPrev()} />
                        <CarouselCount>{`${activeSlide + 1} of ${slides.length}`}</CarouselCount>
                        <RightArrow onClick={() => sliderRef.current.slickNext()} />
                    </CarouselCounterWrap>
                </CarouselCounter>
                : ''}
            {content ? <ArticleTextWrap margintop='10' dangerouslySetInnerHTML={{ __html: content }} /> : ''}

            <VideoModal
                url={videoUrl}
                showModal={showModal}
                closeModal={closeModal}
            />
        </CarouselContainer>
    )
}

const CarouselContainer = styled.div`
    position: relative;
`

const Gradient = styled.div`
    background: ${({gradient}) => gradient ? 'rgba(0, 0, 0, 0.2)' : 'none'};
    background: ${({gradient}) => gradient ? `linear-gradient(-90deg, rgba(0,0,0,0) 0%, rgba(50, 85, 67, 0.8) 100%)` : 'none'};
    display: flex;
    height: 100%;
    padding-top: 80px !important;
    text-align: left;
    position: relative;
    padding-bottom: 80px !important;
    text-decoration: none;

    &::before {
        background: linear-gradient(-90deg, rgba(0,0,0,0) 0%, ${colors.darkGreen} 100%);
    }

    @media ${devices.medium} {
        height: calc(60vh);
        padding-top: 60px !important;
        padding-bottom: 60px !important;
    }

    @media ${devices.small} {
        height: calc(100vh - 60px);
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }
`

const CarouselHeader = styled.h1`
    margin: 0;
    margin-bottom: 20px;
`

const CarouselLogo = styled.div`
    width: 570px;
    height: 74px;
    margin: 0 0 15px;
    background: url('/static/images/lightscape-logo.svg?v=3') no-repeat 0% 100%/contain;

    @media ${devices.large} {
        width: 508px;
    }
    @media ${devices.medium} {
        width: 408px;
    }
    @media ${devices.small} {
        width: 308px;
    }
`


const SlideBody = styled.div`
    width: 30%;

    @media ${devices.small} {
        width: 100%;
    }
`

const Slide = styled.div`
    background: center / cover no-repeat url(${props => props.background});
    height: ${({ height }) => height}px;

    @media ${devices.medium} {
        height: 100%;
    }

    @media ${devices.small} {
        height: 100%;
    }
`

const StyledCarousel = styled(Slider)`
    height: ${props => props.height}px;

    .slide {
        height: ${props => props.height}px;

        @media ${devices.medium} {
            height: calc(60vh);
        }

        @media ${devices.small} {
            height: calc(100vh - 60px);
        }
    }

    @media ${devices.medium} {
        height: calc(60vh);
    }

    @media ${devices.small} {
        height: calc(100vh - 60px);
    }
`

const SlideText = styled(Container)`
    z-index: 100;
    position: relative;
    align-self: flex-end;
    color: ${colors.white};

    a {
        display: table;
        text-decoration: none;
    }

    @media ${devices.medium} {
        margin-left: 0;
        padding-left: 20px;
        padding-right: 20px;
    }

    @media ${devices.small} {
        margin-left: 0;
        padding-left: 15px;
        padding-right: 15px;
    }
`

const VideoWrap = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: black;
    overflow: hidden;
`

const Video = styled.iframe`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    transform: translate(-50%, -50%);
    @media (min-aspect-ratio: 16/9) {
        & {
            height: 56.25vw;
        }
    }
    @media (max-aspect-ratio: 16/9) {
        & {
            width: 177.78vh;
        }
    }
`

const CarouselCounter = styled(Container)`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    text-align: right;
    color: ${colors.white};
`

const CarouselCounterWrap = styled.div`
    display: flex;
    align-items: center;
    width: 30%;

    @media ${devices.small} {
        width: 100%;
        left: 0;
        justify-content: center;
    }
`

const CarouselCount = styled.div`
    min-width: 44px;
    font-size: 16px;
    line-height: 1;
    font-weight: bold;
    text-align: right;
    white-space: nowrap;
`

const Arrow = styled.div`
    background: url('/static/images/sprite.svg') no-repeat 0 0;
    width:  34px;
    height: 34px;
    border: 1px solid ${colors.white};
    border-radius: 100%;
    cursor: pointer;
`

const LeftArrow = styled(Arrow)`
    margin-right: 18px;
    background-position: -376px -312px;
`

const RightArrow = styled(Arrow)`
    margin-left: 20px;
    background-position: -411px -312px;
`

const Caption = styled.div`
    text-align: left;
    margin-top: 10px;
`

const Button = styled(PrimaryButton)`
    display: table !important;
    margin-top: 20px;
`

const VideoPlayButton = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    &:before {
        content: "";
        display: block;
        width: 47px;
        height: 55px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -27px;
        margin-left: -23px;
        background: url(/static/images/sprite.svg) -1px -570px;
    }
`
