import { supportTypes } from '../_constants'

const initialState = {
    donationList: [],
    membershipList: [],
    sponsorshipList: [],
    supportEmail: {
        message: '',
        error: '',
    },
    volunteer: {},
    volunteerList: [],
    jobs: [],
    staff: [],
    departments: [],
}

export function support(state = initialState, action) {
    switch (action.type) {
        case supportTypes.DONATION_LIST_SUCCESS:
            return {
                ...state,
                donationList: action.payload,
            }
        case supportTypes.MEMBERSHIP_LIST_SUCCESS:
            return {
                ...state,
                membershipList: action.payload,
            }
        case supportTypes.SPONSORSHIP_LIST_SUCCESS:
            return {
                ...state,
                sponsorshipList: action.payload,
            }
        case supportTypes.VOLUNTEER_DATA_SUCCESS:
            return {
                ...state,
                volunteer: action.payload,
            }
        case supportTypes.VOLUNTEER_LIST_SUCCESS:
            return {
                ...state,
                volunteerList: action.payload,
            }
        case supportTypes.SPONSORSHIP_EMAIL_POST_SUCCESS:
            return {
                ...state,
                supportEmail: {
                    message: action.payload,
                    error: '',
                },
            }
        case supportTypes.SPONSORSHIP_EMAIL_POST_FAILURE:
            return {
                ...state,
                supportEmail: {
                    message: '',
                    error: 'There was an error processing your request, please try again later',
                },
            }
        case supportTypes.SPONSORSHIP_EMAIL_POST_RESET_MESSAGES:
            return {
                ...state,
                supportEmail: {
                    message: '',
                    error: '',
                },
            }
        case supportTypes.JOB_LIST_SUCCESS:
            return {
                ...state,
                jobs: action.payload.length ? action.payload : false,
            }
        case supportTypes.STAFF_LIST_SUCCESS:
            return {
                ...state,
                staff: action.payload,
            }
        case supportTypes.DEPARTMENT_LIST_SUCCESS:
            return {
                ...state,
                departments: action.payload,
            }
        default:
            return state
    }
}
