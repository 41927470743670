import axios from 'axios'

export const Location = {
    list: () => axios.get('/api/location/'),
}

export const Plant = {
    list: () => axios.get(`/api/plant/`),
}

export const Tour = {
    list: () => axios.get(`/api/tour/`),
}
