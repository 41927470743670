import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { useWindowSize } from '../../_helpers'
import colors from '../../css/colors.scss'
import {
    ContainerFull,
    Image,
    UnstyledLink,
    devices,
    UpperHeader,
    minDevices,
} from '../Common'


const GardenList = ({ width, list }) => {
    const [activeGarden, setActiveGarden] = useState(null)
    const [imagePos, setImagePos] = useState(0)

    const mouseEnter = (g) => {
        setImagePos(Math.floor(Math.random() * Math.floor(50)))
        setActiveGarden(g)
    }

    return (
        <List>
            {width > 740 && <GardenImage
                src={activeGarden?.featured_image}
                left={imagePos}
                alt={activeGarden?.alt_text}
                active={activeGarden ? 1 : 0}
            />}
            {list.map((g, i) => (
                <Garden key={i}>
                    <GardenLink
                        as={width > 740 ? Link : 'a'}
                        to={width > 740 ? g.url : undefined}
                        onMouseEnter={() => width > 740 && mouseEnter(g)}
                        onMouseLeave={() => width > 740 && setActiveGarden(null)}
                        onClick={() => width <= 740 && mouseEnter(g)}
                        opacity={activeGarden && activeGarden.title !== g.title ? 0.5 : 1}
                        active={activeGarden && activeGarden.title === g.title ? 1 : 0}
                    >
                        {g.title}
                    </GardenLink>
                    {width <= 740 && <GardenImage
                        src={g.featured_image}
                        left={10}
                        alt={g.alt_text}
                        active={activeGarden === g ? 1 : 0}
                    />}
                </Garden>
            ))}
        </List>
    )
}

export const FeaturedGardens = ({ gardens, page_menu }) => {
    const { width } = useWindowSize()

    // separate into categories
    let theIsland = {
        "Gardens": [],
        "Natural Ecosystems": [],
    }

    let southGardens = []

    for (const garden of gardens) {
        const { title } = garden
        if (['Susan Garver Family Discovery Garden', 'Community Garden'].includes(title)) {
            southGardens.push(garden)
        } else if (['Coastal Prairie', 'Stormwater Wetlands'].includes(title)) {
            theIsland['Natural Ecosystems'].push(garden)
        } else if (['Global Collection Garden', 'Culinary Garden', 'Woodland Glade', 'Pine Grove'].includes(title)) {
            theIsland['Gardens'].push(garden)
        }
    }

    return (
        <Gardens page_menu={page_menu}>
            <div style={{marginBottom: '50px'}}>
                <Header>The Island</Header>
                <SubHeader>Gardens</SubHeader>
                <GardenList width={width} list={theIsland['Gardens']} />

                <SubHeader>Natural Ecosystems</SubHeader>
                <GardenList width={width} list={theIsland['Natural Ecosystems']} />
            </div>

            <Header>South Gardens</Header>
            <GardenList width={width} list={southGardens} />
        </Gardens>
    )
}

const Header = styled.h2`
    text-align: center;
    position: relative;
    z-index: 1;

    @media ${devices.small} {
        font-size: 50px;
        margin-top: 20px;
    }
`

const SubHeader = styled(UpperHeader)`
    margin-top: 30px;
    position: relative;
    z-index: 1;

    @media ${minDevices.small} {
        text-align: center;
    }

    @media ${devices.small} {
        padding-left: 15px;
    }
`

const Gardens = styled(ContainerFull)`
    overflow: hidden;

    @media ${devices.small} {
        margin: 0;
    }
`

const GardenImage = styled(Image)`
    height: 300px;
    position: absolute;
    z-index: 0;
    transition: opacity .4s ease-in-out;
    opacity: ${props => props.active ? 1 : 0};
    bottom: 0;
    left: ${props => props.left}%;

    @media ${devices.small} {
        height: 120px;
        left: unset;
        right: 5%;
    }
`

const List = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
    position: relative;

    @media ${devices.small} {
        flex-wrap: nowrap;
        flex-direction: column;
        padding: 0 15px 20px;
    }
`

const GardenLink = styled(UnstyledLink)`
    color: ${colors.darkGreen};
    position: relative;
    transition: all .2s ease;
    z-index: 1;
    opacity: ${props => props.opacity};
    text-decoration: none;
`

const Garden = styled.h3`
    display: inline;
    padding: 10px 0;

    &:hover {
        ${GardenLink} { color: ${colors.yellow}; }
    }

    &:not(:last-child):after {
        content: "/";
        padding: 0 20px;
        color: ${colors.darkGreen};
    }

    @media ${devices.small} {
        font-size: 30px;
        position: relative;

        &:focus {
            ${GardenLink} { color: ${colors.yellow}; }
        }

        &:not(:last-child):after {
            content: "";
        }

        &:last-child, &:nth-last-child(2), &:nth-last-child(3) {
            ${GardenImage} {
                top: unset;
                bottom: 0;
            }
        }
    }
`
