import React from 'react'

// import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { useWindowSize } from '../../_helpers'
import colors from '../../css/colors.scss'
import { Container, ContentWrap, SecondaryButton, devices } from '../Common'


export const PlantDetail = ({ color, setShowdetail, plant }) => {
    const { width } = useWindowSize()
    return (
        <PlantDetailWrap text={color === colors.green || color === colors.pink ? colors.darkGreen : '#fff'} background={color}>
            <Container>
                <WrapInner>
                    <CloseButton color={color === colors.green || color === colors.pink ? 'green' : 'white'} aria-label="Close" onClick={() => setShowdetail(false)} />
                    {width > 740 && <CategoryLabel>Global Collection</CategoryLabel>}
                    <Columns has_image={plant.featured_image.image}>
                        <Aside has_image={plant.featured_image.image}>
                            <PlantLabelHeader>{plant.scientific_name.replace(`'`,`‘`).replace(`'`,`’`)}</PlantLabelHeader>
                            {width <= 740 && plant.featured_image.image && <Image bg={plant.featured_image.image} />}
                            <Infotitle>Common Name</Infotitle>
                            <Info>{plant.name}</Info>
                            <Infotitle>Scientific Name</Infotitle>
                            <Info>{plant.scientific_name}</Info>
                            {plant.family &&
                                <>
                                    <Infotitle>Family</Infotitle>
                                    <Info>{plant.family}</Info>
                                </>
                            }
                            {plant.bloom_season &&
                                <>
                                    <Infotitle>Bloom Season</Infotitle>
                                    <Info>{plant.bloom_season}</Info>
                                </>
                            }
                        </Aside>
                        <Main has_image={plant.featured_image.image}>
                            {width > 740 && plant.featured_image.image && <Image bg={plant.featured_image.image} />}
                            <Desc dangerouslySetInnerHTML={{ __html: plant.description }} />
                            {plant.learn_more_url &&
                                <SecondaryButton as={'a'} href={plant.learn_more_url} color={color === colors.green || color === colors.pink ? colors.darkGreen : '#fff'}>Learn More</SecondaryButton>
                            }
                        </Main>
                    </Columns>
                </WrapInner>
            </Container>
        </PlantDetailWrap>
    )
}

const CategoryLabel = styled.div`
    margin: 0 0 10px;
    font-size: 18px;
    font-weight: 700;
`

const WrapInner = styled(ContentWrap)`
    width: calc(100% - 220px - 60px);
    margin: 0 0 0 auto;
    position: relative;

    ${CategoryLabel} {
        margin: 0 0 50px;
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
    }

    @media ${devices.medium} {
        width: calc(100% - 170px - 60px);
    }

    @media ${devices.small} {
        width: 100%;
        padding: 0 15px;
    }
`

const CloseButton = styled.button`
    width: 45px;
    height: 45px;
    background: transparent url(/static/images/sprite.svg);
    background-position-x: ${({ color }) => color === 'green' ? '10px' : '-40px'};
    background-position-y: -447px;
    border: 1px solid ${({ color }) => color === 'green' ? colors.darkGreen : colors.white};
    border-radius: 100%;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    @media ${devices.small} {
        right: 15px;
    }
`

const PlantDetailWrap = styled.div`
    width: 100vw;
    margin: 20px 0;
    margin-left: calc((50vw - 310px) * -1);
    padding: 50px 0;
    position: relative;
    color: ${props => props.text || '#fff'};
    background: ${props => props.background || '#000'};
    &:before,
    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 15px;
        position: absolute;
        left: 0;
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
        opacity: .2;
    }
    &:before {
        top: 0;
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    }
    &:after {
        bottom: 0;
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    }
    @media (max-width: 1260px) {
        margin-left: -320px;
    }
    @media ${devices.medium} {
        margin-left: -250px;
    }
    @media ${devices.small} {
        margin-left: -15px;
        padding: 18px 0 50px;
    }
`

const Columns = styled.div`
    display: flex;
    flex-direction: ${props => (props.has_image ? 'row' : 'column')};
    margin-bottom: ${props => (props.has_image ? '0' : '-15px')};

    @media ${devices.medium} {
        flex-direction: column;
        margin-bottom: -15px;
    }
`

const Aside = styled.div`
    width: ${props => (props.has_image ? '300px' : '100%')};
    margin: ${props => (props.has_image ? '0' : '0 0 15px')};

    @media ${devices.large} {
        width: ${props => (props.has_image ? '260px' : '100%')};
    }

    @media ${devices.medium} {
        width: 100%;
        margin: 0 0 15px;
    }
`

const Main = styled.div`
    width: ${props => (props.has_image ? 'calc(100% - 300px)' : '100%')};
    padding: ${props => (props.has_image ? '0 0 0 30px' : '0')};

    @media ${devices.large} {
        width: ${props => (props.has_image ? 'calc(100% - 260px)' : '100%')};
        padding: 0;
    }

    @media ${devices.medium} {
        width: 100%;
        padding: 0;
    }

    @media ${devices.small} {
        ${SecondaryButton} {
            margin: 30px auto 0;
            width: 100%;
        }
    }
`

const PlantLabelHeader = styled('h3')`
    margin: 0 0 10px;
    line-height: 1.2;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;

    @media not all and (min-resolution:.001dpcm) { @supports (-webkit-appearance:none) and (display:flow-root) {
        & {
            hyphens: none;
        }
    }}

    @media ${devices.large} {
        font-size: calc(48px * .8);
    }

    @media ${devices.small} {
        padding: 55px 0 0;
        font-size: calc(48px * .7);
    }
`

const Image = styled.div`
    height: 0;
    padding-bottom: 56.25%;
    background: ${props => `url(${props.bg}) no-repeat 50% 50%/cover`};
    margin: 0 0 15px;

    @media ${devices.small} {
        padding-bottom: 100%;
    }
`

const Desc = styled.div`
    & + * {
        margin: 20px 0 0;
    }
`

const Infotitle = styled.div`
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    & + * + * {
        margin: 15px 0 0;
    }
`

const Info = styled.div``
