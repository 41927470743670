import { formTypes } from '../_constants'

const initialState = {
    message: '',
    error: '',
}

export const form = (state = initialState, action) => {
    switch(action.type) {
        case formTypes.POST_SPEAKER_FORM_SUCCESS:
            return {
                ...state,
                message: action.payload,
                error: '',
            }
        case formTypes.POST_SPEAKER_FORM_FAILURE:
            return {
                ...state,
                message: '',
                error: action.payload,
            }
        case formTypes.RESET:
            return {
                ...state,
                message: '',
                error: '',
            }
        default:
            return state
    }
}