import React from 'react'

import { useForm } from 'react-hook-form'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import styled from 'styled-components'

import { PageWithMenu, HookTextInput } from '../'
import { postSpeakerRequestForm } from '../../_actions'
import { email } from '../../_helpers'
import colors from '../../css/colors.scss'
import {
    PrimaryButton,
    ExtraInfo,
    InputWrap,
    FormLabel,
    Checkbox,
} from '../Common'


const SpeakerForm = () => {
    const dispatch = useDispatch()
    const { message, error } = useSelector(state => state.customForm, shallowEqual)
    const { register, handleSubmit, errors, control } = useForm({ mode: 'onBlur' })
    const onSubmit = data => dispatch(postSpeakerRequestForm(data))

    const registerOptions = { required: 'This field is required' }

    return (
        <>
            {message || error ? (
                <div>
                    {message || error}
                </div>
            ) : (
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <HookTextInput
                            errors={errors}
                            label='Organization Requesting Speaker'
                            name='organization'
                            required
                            ref={register(registerOptions)}
                        />
                        <HookTextInput
                            errors={errors}
                            label='Contact Name'
                            name='contact_name'
                            required
                            ref={register(registerOptions)}
                        />
                        <HookTextInput
                            errors={errors}
                            label='Contact Email Address'
                            type='email'
                            name='contact_email'
                            required
                            ref={register({ ...registerOptions, validate: val => email(val) })}
                        />
                        <HookTextInput
                            errors={errors}
                            label='Contact Phone Number'
                            type='tel'
                            control={control}
                            name='contact_phone'
                            required
                        />
                        <HookTextInput
                            errors={errors}
                            label='Address'
                            name='address'
                            extra='Location where presentation would be held.'
                            required
                            ref={register(registerOptions)}
                        />
                        <HookTextInput
                            errors={errors}
                            label='Event Description'
                            type='textarea'
                            name='event_description'
                            extra='Please include details about the purpose of event, basic reasons for requesting a speaker, requested topic, etc.'
                            required
                            ref={register(registerOptions)}
                        />
                        <HookTextInput
                            errors={errors}
                            type='date'
                            label='Date Requesting Speaker'
                            name='date_requesting'
                            control={control}
                            required
                            ref={register(registerOptions)}
                        />
                        <HookTextInput
                            errors={errors}
                            label='Are there additional dates that would work if the first preference is unavailable?'
                            name='additional_dates'
                            required
                            ref={register(registerOptions)}
                        />
                        <HookTextInput
                            errors={errors}
                            type='time'
                            label='Time Speaker is Expected to Arrive'
                            name='arrival_time'
                            extra='In order to set up'
                            required
                            ref={register(registerOptions)}
                        />
                        <HookTextInput
                            errors={errors}
                            label='Time of Event'
                            name='event_time'
                            extra='Please provide the expected start and end times for your event. <br /> NOTE: Whenever possible, the Houston Botanic Garden should be the first item on the agenda, so our speakers can present and then leave, to minimize interference with other business.'
                            required
                            ref={register(registerOptions)}
                        />
                        <HookTextInput
                            errors={errors}
                            label='Presentation Length'
                            name='presentation_length'
                            extra='How long will the Houston Botanic Garden have to speak?'
                            required
                            ref={register(registerOptions)}
                        />
                        <HookTextInput
                            errors={errors}
                            label='Presentation Format'
                            name='presentation_format'
                            extra='Please note any necessary details about the format of the presentation.  Will there be an opportunity for Q&amp;A?'
                            required
                            ref={register(registerOptions)}
                        />
                        <HookTextInput
                            errors={errors}
                            label='Expected Audience'
                            extra='Size and Description.'
                            name='expected_audience'
                            required
                            ref={register(registerOptions)}
                        />
                        <InputWrap>
                            <FormLabel>A/V Equipment Available</FormLabel>
                            <ExtraInfo>Please select all equipment you will have available for HBG to use. If there is anything we need to provide, please note that in the comments section below.</ExtraInfo>
                            <Checkbox>
                                <input type='checkbox' name='equipment' value='Projector' id='projector' ref={register} />
                                <label htmlFor='projector'>Projector</label>
                            </Checkbox>
                            <Checkbox>
                                <input type='checkbox' name='equipment' value='Laptop' id='laptop' ref={register} />
                                <label htmlFor='laptop'>Laptop</label>
                            </Checkbox>
                            <Checkbox>
                                <input type='checkbox' name='equipment' value='Speakers/Microphone' id='speakers' ref={register} />
                                <label htmlFor='speakers'>Speakers/Microphone</label>
                            </Checkbox>
                        </InputWrap>
                        <HookTextInput
                            errors={errors}
                            label='Additional Comments'
                            type='textarea'
                            name='additional_comments'
                            ref={register}
                        />
                        <PrimaryButton type='submit' onClick={handleSubmit(onSubmit)}>Submit</PrimaryButton>
                    </Form>
                )}
        </>
    )
}

const Form = styled.form`
    display: flex;
    flex-direction: column;
    input {
        &:not([type="checkbox"]) {
            color: ${({ color = colors.darkGreen }) => color};
            border: 1px solid ${colors.darkGreen};
            padding: 15px 20px 14px;
            margin: 20px 0;
            height: 43px;
            border-radius: 0;
            width: 90%;

            &::placeholder, &::-webkit-input-placeholder {
                color: ${({ color = colors.darkGreen }) => color};
                opacity: .5;
            }
        }
    }

    textarea {
        width: 90%;
        margin: 20px 0;
    }

    button[type="submit"] {
        width: max-content;
    }
`

const SpeakerRequestForm = () => <PageWithMenu ChildComponent={SpeakerForm} />
export default SpeakerRequestForm
