import React, { useState } from 'react'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { loadExhibitionList } from '../../_actions'
import colors from '../../css/colors.scss'
import {
    UpperHeader,
    Header,
    SecondaryButton,
    devices,
} from '../Common'


const ExhibtionListItem = ({first, exhibition: {name, description, image, date, slug, alt_text}}) => (
    <ExhibitionCard first={first ? 1 : 0} to={`/visit/exhibitions/${slug}/`}>
        <ExhibitionImage background={image} alt={alt_text}/>
        <ExhibitionInfo>
            <Header fontSize={47}>{name}</Header>
            <ExhibitionDate>{date}</ExhibitionDate>
            <ExhibitionDescription>{description}</ExhibitionDescription>
        </ExhibitionInfo>
    </ExhibitionCard>
)

export const UpcomingExhibitions = () => {
    const dispatch = useDispatch()
    const { exhibitionUpcomingList, totalUpcoming, exhibitionPageNumber } = useSelector(state => state.event, shallowEqual)
    const [display, setDisplay] = useState(3)
    const loadMore = () => {
        const newDisplay = display + 3
        setDisplay(newDisplay)
        if (exhibitionUpcomingList.length < totalUpcoming && newDisplay + 3 >= exhibitionUpcomingList.length) {
            dispatch(loadExhibitionList(exhibitionPageNumber))
        }
    }
    return (
        <ExhibitionContainer>
            <UpperHeader marginbottom={20} margintop={60}>Upcoming Exhibitions</UpperHeader>
            {exhibitionUpcomingList.slice(0, display).map((e, i) => <ExhibtionListItem key={i} first={i === 0} exhibition={e} />)}
            {exhibitionUpcomingList.length > display ? <ButtonContainer onClick={loadMore}><SecondaryButton>Load More</SecondaryButton></ButtonContainer> : ''}
        </ExhibitionContainer>
    )
}

export const PastExhibitions = () => {
    const dispatch = useDispatch()
    const { exhibitionPastList, totalPast, exhibitionPageNumber } = useSelector(state => state.event, shallowEqual)
    const [display, setDisplay] = useState(3)
    const loadMore = () => {
        const newDisplay = display + 3
        setDisplay(newDisplay)
        if (exhibitionPastList.length < totalPast && newDisplay + 3 >= exhibitionPastList.length) {
            dispatch(loadExhibitionList(exhibitionPageNumber))
        }
    }
    return (
        <ExhibitionContainer>
            <UpperHeader marginbottom={20} margintop={60}>Past Exhibitions</UpperHeader>
            {exhibitionPastList.slice(0, display).map((e, i) => <ExhibtionListItem key={i} first={i === 0} exhibition={e} />)}
            {exhibitionPastList.length > display ? <ButtonContainer onClick={loadMore}><SecondaryButton>Load More</SecondaryButton></ButtonContainer> : ''}
        </ExhibitionContainer>
    )
}

export const FeaturedExhibitions = () => {
    const dispatch = useDispatch()
    const { exhibitionList, exhibitionPageNumber, totalCurrent } = useSelector(state => state.event, shallowEqual)
    const [display, setDisplay] = useState(4)
    const loadMore = () => {
        const newDisplay = display + 2
        setDisplay(newDisplay)
        if (exhibitionList.length < totalCurrent && newDisplay + 2 >= exhibitionList.length) {
            dispatch(loadExhibitionList(exhibitionPageNumber))
        }
    }
    return (
        <FeaturedContainer>
            {exhibitionList.slice(0, display).map((e, i) => (
                <FeaturedCard key={e.name} to={`/visit/exhibitions/${e.slug}/`}>
                    <FeaturedImage background={e.image} alt={e.alt_text}/>
                    <FeaturedInfo i={i}>
                        <FeaturedName>{e.name}</FeaturedName>
                        <FeaturedDate>{e.date}</FeaturedDate>
                    </FeaturedInfo>
                </FeaturedCard>
            ))}
            {exhibitionList.length > display ? <ButtonContainer onClick={loadMore}><SecondaryButton>Load More</SecondaryButton></ButtonContainer> : ''}
        </FeaturedContainer>
    )
}

const ExhibitionCard = styled(Link)`
    display: flex;
    border-bottom: 1px solid ${colors.darkGreen};
    padding: 30px 0;

    @media ${devices.tablet} {
        flex-direction: column;
    }

    ${({first}) => first && `border-top: 1px solid ${colors.darkGreen};`}
`

const ExhibitionImage = styled.div`
    height: 252px;
    width: 252px;
    min-height: 252px;
    min-width: 252px;
    background: center / cover no-repeat url(${({background}) => background});
    margin-right: 30px;
    @media ${devices.tablet} {
        width: 100%;
    }
`

const ExhibitionInfo = styled.div`
    @media ${devices.tablet} {
        margin-top: 15px;
    }
`

const ExhibitionDate = styled.div`
    color: ${colors.darkGreen};
    margin-top: 10px;
    margin-bottom: 30px;
`

const ExhibitionDescription = styled.div`
    color: ${colors.darkGreen};
`

const ExhibitionContainer = styled.div``

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 40px;

    @media ${devices.small} {
        margin-top: 20px;
        margin-bottom: 10px;
    }
`

const FeaturedContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`

const FeaturedCard = styled(Link)`
    width: calc(50% - 10px);
    height: 535px;
    margin-bottom: 20px;
    @media ${devices.tablet} {
        width: 100%;
        height: 445px;
    }
`

const FeaturedImage = styled.div`
    background: center / cover no-repeat url(${({background}) => background});
    width: 100%;
    height: 415px;
    @media ${devices.tablet} {
        height: 335px;
    }
`

const FeaturedInfo = styled.div`
    height: 120px;
    background-color: ${({i}) => {
        if (i % 4 === 0) return colors.red
        else if (i % 4 === 1) return colors.yellow
        else if (i % 4 === 2) return colors.blue
        else if (i % 4 === 3) return colors.darkGreen
    }};
    color: ${colors.white};
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media ${devices.tablet} {
        height: 100px;
    }
`

const FeaturedName = styled.div`
    font-family: Canela, sans-serif;
    width: 100%;
    font-size: 31px;
`

const FeaturedDate = styled.div`
    font-size: 15px;
`
