import React, { useState } from 'react'

import styled from 'styled-components'

import colors from '../../css/colors.scss'
import { devices, margins, paddings, richtext, Container } from './'


const Block = styled.div`
    ${margins}
    ${paddings}
    &:before, &:after {
        content: '';
        display: table;
        clear: both;
    }

    ${({ anchorcolor }) => anchorcolor ? `
        a {
            color: ${anchorcolor};
            text-decoration: underline;
        }
    ` : ''}

    ${({ center }) => center ? 'max-width: 80%;text-align: center;' : ''}

    p:not(:last-of-type) {
        margin: 0 0 15px;
    }

    &.section-qa + .section-qa > div {
        padding-top: 0 !important;
        &:before {
            border-top: 0 !important;
        }
    }
`

const ImageCard = styled.div`
    ${margins}
    width: 100%;
    height: ${({ height = 200 }) => height}px;
    ${({ ratio }) => ratio}
    background: center / ${props => props.orientation || 'cover'} no-repeat url(${props => props.background});
    & + * {
        margin-top: 20px;
    }
    @media ${devices.small} {
        height: 0;
        padding-bottom: ${({ responsive }) => responsive ? '56.25%' : '100%'};
    }
`

const SlideImageCardComp = styled(ImageCard)`
    height: 440px;
    margin-bottom: 0;
    background-position: top;
    @media ${devices.small} {
        height: 300px;
    }
`

const SlideCaption = styled.div`
    font-size: 8px;
    padding-top: 10px;
    padding-right: 40%;
`

const SlideImageCard = styled(({ caption, background }) => {
    return <div>
        <SlideImageCardComp background={background} />
        {caption && <SlideCaption>{caption}</SlideCaption>}
    </div>
})

const Section = styled(Container)`
    padding-top: 12px;
    padding-bottom: 10px;
    margin-top: ${({ margintop = 0 }) => margintop}px;
    margin-bottom: ${({ marginbottom = 0 }) => marginbottom}px;
`

const ArticleWrap = styled.div`
    max-width: ${props => props.maxwidth || 'unset'};
    padding-bottom: 30px;
    @media ${devices.small} {
        padding-left: 0;
        padding-right: 0;
    }
`

const ArticleTextWrap = styled.div`
    ${margins}
    ${richtext}
    ${props => !props.nopadding && 'padding-right: 60px;'}
    overflow: hidden;
    @media ${devices.small} {
        padding-right: 0;
    }
`

const Hr = styled.div`
    ${margins}
    width: 100%;
    height: 1px;
    background-color: ${({ color = colors.darkGreen }) => color};
`

const ArticleInfo = styled(Block)`
    padding: 36px 25px;
    border: 1px solid #414042;
`

const Button = styled.button`
    display: table;
    width: auto;
    ${margins}
    padding: 15px 40px 14px;
    background-color: transparent;
    border-radius: 25px;
    user-select: none;
    font-family: inherit;
    font-size: 17px;
    line-height: 14px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    position: relative;
    white-space: nowrap;
    cursor: pointer;

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }

    @media ${devices.medium} {
        padding: 15px 25px 14px;
    }
`

export const PrimaryButton = styled(Button)`
    background-color: ${colors.red};
    color: ${colors.white};
    border: 1px solid ${colors.red};
`
export const SecondaryButton = styled(Button)`
    ${({ color = colors.darkGreen }) => `
        color: ${color};
        border: 1px solid ${color};
    `}
`

const MenuItem = styled(Block)`
    font-size: 16px;
    margin-bottom: 10px;
    cursor: pointer;
    font-weight: 600;
`

const IntroText = styled(ArticleTextWrap)`
    padding-bottom: 50px;

    @media ${devices.small} {
        padding-bottom: 20px;
    }
`

export const SelectButton = (props) => {
    const [expand, setExpand] = useState(false)

    return (
        <SelectButtonWrap margintop={props.margintop} open={expand}>
            <SBInner background={props.background} open={expand} onClick={() => setExpand(!expand)}>Suggested Search</SBInner>
            <SBDropdown background={props.background} open={expand}>
                <SBLink href={'#'}>Edible Plants</SBLink>
                <SBLink href={'#'}>Local / Regional Plants</SBLink>
                <SBLink href={'#'}>Regions of the World</SBLink>
                <SBLink href={'#'}>Pollinator-Friendly Plants</SBLink>
            </SBDropdown>
        </SelectButtonWrap>
    )
}

const SelectButtonWrap = styled.div`
    display: inline-block;
    position: relative;
    margin: ${props => props.margintop ? props.margintop : '0'}px 0 0 0;
    font-family: inherit;
    font-size: 17px;
    line-height: 14px;
    font-weight: bold;
    text-align: left;
    text-decoration: none;
    text-shadow: none;
    color: ${colors.darkGreen};
`

const SBInner = styled.div`
    padding: 15px 80px 14px 20px;
    position: relative;
    background-color: ${props => props.background ? props.background : 'transparent'};
    border: 1px solid ${colors.darkGreen};
    ${props => props.open ? `
        border-radius: 25px 25px 0 0;
    ` : `
        border-radius: 25px;
    `};
    user-select: none;
    cursor: pointer;

    &:before {
        content: "";
        display: block;
        width: 29px;
        height: 29px;
        background: url(/static/images/sprite.svg) no-repeat -30px -400px;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        right: 12px;
        margin-top: -14px;
    }

    ${props => props.open ? `
        &:before {
            transform: rotate(180deg);
        }` : ``};
`

const SBDropdown = styled.div`
    display: ${props => props.open ? 'block' : 'none'};
    width: 100%;
    background-color: ${props => props.background ? props.background : 'transparent'};
    border: 1px solid ${colors.darkGreen};
    border-top: 0;
    border-radius: 0 0 25px 25px;
    position: absolute;
    top: auto;
    left: 0;
    z-index: 1;
`

const SBLink = styled.a`
    display: block;
    padding: 15px 20px 14px 20px;
    border-bottom: 1px solid ${colors.darkGreen};
    text-decoration: none;
    color: inherit;

    &:last-child {
        border-bottom: 0;
    }
`

export {
    Block,
    Button,
    ImageCard,
    SlideImageCard,
    ArticleWrap,
    ArticleInfo,
    Hr,
    Section,
    ArticleTextWrap,
    MenuItem,
    IntroText,
}
