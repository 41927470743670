import React, { useState } from 'react'

import styled from 'styled-components'

import { SupportEmailForm } from '../'
import {
    Program,
    Rental,
    Volunteer
} from '../'
import colors from '../../css/colors.scss'
import { PrimaryButton, SecondaryButton, devices, richtext } from '../Common'


const AccordionItems = ({ data, type, first, category }) => {
    const [clicked, setClicked] = useState(false)

    return (
        <AccordionItem clicked={clicked} firstbordertop={type !== 'question' && type !== 'program'}>
            <AccordionTitle onClick={() => setClicked(!clicked)} paddingtop={type === 'question' && first ? 0 : 15}><h3>{data.question ? data.question : data.title}</h3></AccordionTitle>
            <AccordionContent>
                {type === 'question' && <Answer dangerouslySetInnerHTML={{__html: data.answer}} />}
                {type === 'program' && <Program data={data} />}
                {type === 'rental' && <Rental data={data} />}
                {type === 'volunteer' && <Volunteer data={data} />}
                {type === 'contact' &&
                    <ContactWrap>
                        <PrimaryButton as='a' href={`mailto:${category.contact_email}?subject=Volunteer Information`}>Send an Email</PrimaryButton>
                        <FormBreak>OR</FormBreak>
                        <SupportEmailForm
                            selectCategories={category.volunteers.map(e => [e.title, e.title])}
                            toEmail={category.contact_email}
                            subject='Volunteer Information'
                            fromName='hbg.org Volunteer'
                        />
                    </ContactWrap>
                }
            </AccordionContent>
        </AccordionItem>
    )
}

export const Accordion = ({ pageBlock, items, type, category }) => {
    return (
        <AccordionWrap className='accordion'>
            {pageBlock && pageBlock.title && pageBlock.show_title ? (
                <Header>{pageBlock.title}</Header>
            ) : ''}
            {items && items.map((data, i) =>
                <AccordionItems key={i} data={data} type={type} category={category} first={i === 0}/>
            )}
        </AccordionWrap>
    )
}

const AccordionWrap = styled.div`
    & + div:not(.accordion) > div:before {
        display: none;
    }
`

const AccordionTitle = styled.div`
    padding: ${({paddingtop}) => paddingtop}px 60px 10px 0px;
    position: relative;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &:before {
        content: "";
        display: block;
        width: 40px;
        height: 40px;
        background: url(/static/images/sprite.svg) -1px -502px;
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -20px;
    }

    h3 {
        line-height: 1.2;
    }

    @media ${devices.small} {
        h3 {
            width: calc(100% - 40px);
            font-size: 29px;
        }
    }
`

const AccordionContent = styled.div`
    height: 0;
    overflow: hidden;
    & > div {
        padding: 30px 0 60px;
    }
    ${richtext}
    ${SecondaryButton} {
        margin: 50px 0 0;
    }
`

const AccordionItem = styled.div`
    border-bottom: 1px solid ${colors.darkGreen};

    ${props => (props.firstbordertop ? `
        &:first-child {
            border-top: 1px solid ${colors.darkGreen};
        }
    `:'')};

    ${AccordionTitle} {
        &:before {
            transform: ${({ clicked }) => clicked && 'rotate(180deg)'};
        }
    }

    ${AccordionContent} {
        height: ${({ clicked }) => clicked && 'auto'};
    }
`

const Header = styled.h3`
    margin: 0 0 30px;
    text-align: center;
`

const Answer = styled.div`
    padding: 10px 0 30px !important;

    a {
        color: ${colors.darkGreen};
        text-decoration: underline;
    }
`

const FormBreak = styled.div`
    margin: 40px 0 30px;
    padding: 0 !important;
`

const ContactWrap = styled.div`
    padding-top: 5px !important;
    padding-bottom: 30px !important;
`
