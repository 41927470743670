import styled from 'styled-components'

import colors from '../../css/colors.scss'
import { devices, margins } from './'


const Header = styled.div`
    font-family: 'Canela';
    max-width: ${({unsetmaxwidth}) => unsetmaxwidth ? 'unset' : '690px'};
    line-height: 1.2;
    font-size: ${({fontSize=48}) => fontSize}px;
    ${margins}
    color: ${({color=colors.darkGreen}) => color};
    ${({center}) => center ? 'text-align: center;' : ''}
    @media ${devices.medium} {
       font-size: calc(48px * .85);
    }
    @media ${devices.small} {
       font-size: calc(48px * .7);
    }
`

const UpperHeader = styled.h2`
    font-family: 'NB International Pro';
    font-size: ${({fontSize=17}) => fontSize}px;
    color: ${({color='inherit'}) => color};
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    ${margins}

    @media ${devices.medium} {
       font-size: calc(17px * .9);
    }

    @media ${devices.small} {
       margin-bottom: 20px;
       font-size: calc(17px * .8);
    }
`

const UnstyledLink = styled.a`
    cursor: pointer;
    color: inherit;
    text-decoration: none;
    &:hover, &:focus, &:active, &:visited {
        color: inherit;
    }
`

const MenuLink = styled(UnstyledLink)`
    color: ${({active}) => active ? colors.red : colors.darkGreen};
    text-decoration: none;
`

export {
    UpperHeader,
    Header,
    UnstyledLink,
    margins,
    MenuLink,
}
