import axios from 'axios'

export const Pages = {
    get: url => axios.get(`/api/page/${encodeURIComponent(encodeURIComponent(`${url}${url[url.length - 1] !== '/' ? '/' : ''}`))}/`),
}

export const Menu = {
    list: () => axios.get(`/api/menu/`),
}

export const Newsletter = {
    post: email => axios.post(`/api/newsletter/`, { email }),
}

export const Weather = {
    get: () => axios.get('/api/weather/'),
}

export const News = {
    list: () => axios.get(`/api/news/`),
}

export const Video = {
    list: () => axios.get(`/api/videos/`),
}

export const Search = {
    get: (query) => axios.get(`/api/search/?text=${query}`),
}
