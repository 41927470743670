import { createConstants } from '../_helpers/utils'

export const eventTypes = createConstants(
    'EVENT_DATA_REQUEST',
    'EVENT_DATA_SUCCESS',
    'EVENT_LIST_REQUEST',
    'EVENT_LIST_SUCCESS',
    'EVENT_SEARCH_REQUEST',
    'EVENT_SEARCH_SUCCESS',
    'CHANGE_CURRENT',
    'SET_FILTERED_EVENTS_QUERY',
    'CLEAR_FILTERED_EVENTS',
    'EXHIBITION_DATA_REQUEST',
    'EXHIBITION_DATA_SUCCESS',
    'EXHIBITION_LIST_REQUEST',
    'EXHIBITION_LIST_SUCCESS',
    'PROGRAM_LIST_REQUEST',
    'PROGRAM_LIST_SUCCESS',
    'RENTAL_LIST_REQUEST',
    'RENTAL_LIST_SUCCESS',
    'CLEAR_EVENT',
)
