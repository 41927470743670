import axios from 'axios'

export const Donation = {
    list: () => axios.get(`/api/donation/`),
}

export const Membership = {
    list: () => axios.get(`/api/membership/`),
}

export const Sponsorship = {
    list: () => axios.get(`/api/sponsorship/`),
    postEmail: data => axios.post('/api/sponsorship-email/', JSON.stringify(data)),
}

export const Volunteer = {
    get: slug => axios.get(`/api/volunteer/${slug}/`),
    list: () => axios.get('/api/volunteer/'),
}

export const Job = {
    list: () => axios.get(`/api/employment/`),
}

export const Staff = {
    list: () => axios.get(`/api/staff/`),
}

export const Department = {
    list: () => axios.get(`/api/department/`),
}
