import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import { useEventListener, scrollTo } from '../../_helpers'
import colors from '../../css/colors.scss'
import { devices } from '../Common'


export const BackTop = () => {
    const [show_button, setShowButton] = useState(false)

    const scrollTop = () => {
        scrollTo(0,600)
    }

    const showButton = () => {
        const distanceY = window.pageYOffset || document.documentElement.scrollTop
        if (distanceY >= 1500) {
            setShowButton(true)
        } else {
            setShowButton(false)
        }
    }

    useEventListener('scroll', showButton)

    useEffect(() => {
    }, [show_button])

    return (
        <BackTopButton role="button" aria-label="Back to Top" show_button={show_button} onClick={scrollTop}/>
    )
}

const BackTopButton = styled.div`
    width: 50px;
    height: 50px;
    background: ${colors.green};
    border-radius: 100%;
    box-shadow: 0 1px 4px rgba(0,0,0,.3);
    position: fixed;
    right: 15px;
    bottom: 15px;
    z-index: 3000;
    opacity: ${props => props.show_button ? '1' : '0'};
    pointer-events: ${props => props.show_button ? 'auto' : 'none'};
    cursor: pointer;
    transition: opacity .3s;
    &:before {
        content: "";
        display: block;
        width: 50px;
        height: 50px;
        background: url('/static/images/sprite.svg') no-repeat 25px -799px;
        transform: scale(.75) translate(-7px,-7px);
    }
    @media ${devices.small} {
        width: 35px;
        height: 35px;
        &:before {
            width: 35px;
            height: 25px;
            background-position: 12px -564px;
            background-size: 700px 700px;
            transform: scale(1) translate(0,2px);
        }
    }
`
