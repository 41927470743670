import { Map, View } from 'ol'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import { defaults } from 'ol/interaction'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import Overlay from 'ol/Overlay'
import Projection from 'ol/proj/Projection'
import VectorSource from 'ol/source/Vector'
import Zoomify from 'ol/source/Zoomify'
import { Fill, Circle, Text, Icon, Style } from 'ol/style'

import { scrollToAnchor } from '../_helpers'
// import { click, pointerMove } from 'ol/events/condition'
import colors from '../css/colors.scss'


export function CreateMap(page, locationList) {
    const createTextLabel = (feature) => {
        return new Text({
            text: feature.title,
            textAlign: 'center',
            textBaseline: 'bottom',
            font: '12px NB International Pro',
            offsetX: 0,
            offsetY: 0,
            fill: new Fill({
                color: colors.white
            }),
            backgroundFill: new Fill({
                color: colors.blue,
            }),
            padding: [5, 5, 5, 5],
        })
    }

    const createTextNumber = (feature) => {
        return new Text({
            text: feature.number,
            textAlign: 'center',
            textBaseline: 'middle',
            font: 'bold 16px NB International Pro',
            offsetX: .5,
            offsetY: 0,
            fill: new Fill({
                color: colors.white
            }),
        })
    }

    const createStyle = (i, feature, type) => {
        let style

        if (type === 'marker') {
            style = {
                text: createTextNumber(feature),
                image: new Circle({
                    radius: 15,
                    fill: new Fill({
                        color: colors.blue
                    }),
                }),
                zIndex: i,
            }
        } else if (type === 'label') {
            style = {
                text: createTextLabel(feature),
                zIndex: i,
            }
        } else if (type === 'key') {
            style = {
                image: new Icon({
                    anchor: [24, 24],
                    size: [48, 48],
                    scale: 0.5,
                    anchorXUnits: 'pixels',
                    anchorYUnits: 'pixels',
                    src: `/static/images/${feature.image}`,
                })
            }
        }

        return new Style(style)
    }

    const map_width = 6978
    const map_height = 9000
    const zoomify_url = 'https://hbg.org/static/images/zoomify-v1/'

    const retina_pixel_ratio = 2

    const zoomify_source = new Zoomify({
        url: zoomify_url,
        size: [map_width, map_height],
        crossOrigin: 'anonymous',
        cacheSize: 500,
        zDirection: -1,
        tilePixelRatio: retina_pixel_ratio,
        tileSize: 512 / retina_pixel_ratio,
        transition: 100,
    })

    const extent = zoomify_source.getTileGrid().getExtent()

    const projection = new Projection({
        units: 'pixels',
        extent: extent,
    })

    const zoomify_layer = new TileLayer({
        source: zoomify_source,
    })

    const gardenActiveState = (e, markers, state) => {
        const current_marker = e.target.closest('.marker')

        if (e.pointerType === 'touch') {
            for (const marker of markers) {
                if (marker !== current_marker) {
                    marker.classList.remove('open', 'active')
                    marker.closest('.ol-overlay-container').style.zIndex = ''
                }
            }
        }

        if (!current_marker.classList.contains('open')) {
            current_marker.classList.add('open')
            current_marker.closest('.ol-overlay-container').style.zIndex = '200'

            setTimeout(() => {
                current_marker.classList.add('active')
            }, 200)
        }

        if (state === 'pointerleave') {
            if (e.pointerType === 'touch') return

            if (current_marker.classList.contains('open')) {
                current_marker.classList.remove('open')
                current_marker.closest('.ol-overlay-container').style.zIndex = ''
            }
        }
    }

    const processLandmarkData = (items, garden = false) => {
        const count = items.length
        let markers = []

        for (let i = 0; i < count; i++) {
            const label_el = document.createElement('div')
            label_el.setAttribute('class', 'marker-landmark')
            label_el.innerHTML = `
            <div class="marker">
                ${garden ? `<a href="#${items[i].slug}">` : ''}
                    ${i + 1}
                ${garden ? `</a>` : ''}
            </div>
            <div class="label ${items[i].label_position}">
                <div>
                    <div class="name">${items[i].title}</div>
                    ${garden ? `<div class="desc">${items[i].description}</div>` : ''}
                </div>
            </div>`

            const label_overlay = new Overlay({
                position: [map_width * items[i].left, (map_height * items[i].top)-map_height],
                positioning: 'center-center',
                offset: [0, 0],
                element: label_el,
                stopEvent: false,
            })

            if (garden) {
                markers.push(label_el.querySelector('.marker'))

                label_el.querySelector('.marker').addEventListener('pointerup', (e) => {
                    gardenActiveState(e, markers)
                })

                label_el.querySelector('.marker').addEventListener('pointerover', (e) => {
                    gardenActiveState(e, markers)
                })

                label_el.querySelector('.marker').addEventListener('pointerleave', (e) => {
                    gardenActiveState(e, markers, 'pointerleave')
                })
            }

            map.addOverlay(label_overlay)
        }
    }

    const processKeyData = items => {
        const features = []
        let count = 0
        for (let i = 0; i < items.length; i++) {
            for (let j = 0; j < items[i].locations.length; j++) {
                const coordinates = [map_width * items[i].locations[j].left, map_height * items[i].locations[j].top]
                const feature = new Feature({
                    geometry: new Point(coordinates),
                })
                const style = {
                    coordinates,
                    image: `map-key-${items[i].display_color}.png`,
                    name: items[i].title,
                }
                feature.setStyle([createStyle(count, style, 'key')])
                features.push(feature)
                count++
            }
        }
        vectorLayer.getSource().addFeatures(features)
    }

    const vectorLayer = new VectorLayer({
        source: new VectorSource({}),
        //declutter: true,
    })

    const map = new Map({
        target: 'map',
        layers: [zoomify_layer, vectorLayer],
        view: new View({
            // extent: extent,
            projection: projection,
            center: [map_width * 0.5, map_height * -0.38],
            zoom: 3,
            minZoom: 2,
            maxZoom: 4,
            renderer: 'webgl',
            // constrainResolution: true,
        }),
        interactions: defaults({
            dragPan: true,
            mouseWheelZoom: false,
            doubleClickZoom: false,
            pinchZoom: true,
            altShiftDragRotate: false,
            pinchRotate: false,
        })
    })

    processLandmarkData(locationList.locations, page !== 'visit')
    if (page !== 'visit') processKeyData(locationList.keys)

    for (const link of document.querySelectorAll('#map .marker a')) {
        link.addEventListener('click', (e) => {
            e.preventDefault()
            scrollToAnchor(link.getAttribute('href'))
        })
    }

    /*
    map.on('click', (e) => {
        map.forEachFeatureAtPixel(e.pixel, (feature) => {
            console.log(feature.values_.title)
        })
    })
    */
}
