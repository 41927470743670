import { eventTypes } from '../_constants'

const initialState = {
    event: {},

    eventList: [],
    filteredEventList: [],
    current: true,
    noResults: false,
    nextFilteredPage: 0,
    totalFilteredEvents: 0,
    filteredEventListQuery: '',
    featuredEvents: [],
    eventCategories: [],
    eventLocations: [],
    nextEventPage: 0,
    totalEvents: 0,

    exhibition: {},
    exhibitionList: [],
    totalCurrent: 0,
    exhibitionUpcomingList: [],
    totalUpcoming: 0,
    exhibitionPastList: [],
    totalPast: 0,
    exhibitionFeaturedList: [],
    totalFeatured: 0,
    exhibitionPageNumber: 0,

    programList: [],

    rentalList: [],
}

export function event(state=initialState, action) {
    switch (action.type) {
        case eventTypes.EVENT_DATA_SUCCESS:
            return {
                ...state,
                event: action.payload.event,
                featuredEvents: action.payload.featured,
            }
        case eventTypes.CLEAR_EVENT:
            return {
                ...state,
                event: {},
            }
        case eventTypes.EVENT_LIST_SUCCESS:
            return {
                ...state,
                nextEventPage: action.payload.next_page,
                totalEvents: action.payload.count,
                featuredEvents: action.payload.featured,
                eventList: [...state.eventList, ...action.payload.events],
                eventCategories: action.payload.categories,
                eventLocations: action.payload.locations,
            }
        case eventTypes.EVENT_SEARCH_SUCCESS:
            return {
                ...state,
                nextFilteredPage: action.payload.next_page,
                totalFilteredEvents: action.payload.count,
                noResults: action.payload.count === 0,
                filteredEventList: action.payload.next_page === 2 || action.payload.count <= 12 ? action.payload.events : [...state.filteredEventList, ...action.payload.events],
            }
        case eventTypes.CLEAR_FILTERED_EVENTS:
            return {
                ...state,
                nextFilteredPage: 0,
                totalFilteredEvents: 0,
                noResults: false,
                filteredEventList: [],
                filteredEventListQuery: '',
            }
        case eventTypes.SET_FILTERED_EVENTS_QUERY:
            return {
                ...state,
                filteredEventListQuery: action.payload,
            }
        case eventTypes.EXHIBITION_DATA_SUCCESS:
            return {
                ...state,
                exhibition: action.payload,
            }
        case eventTypes.CHANGE_CURRENT:
            return {
                ...state,
                current: action.payload,
            }
        case eventTypes.EXHIBITION_LIST_SUCCESS:
            return {
                ...state,
                exhibitionPageNumber: state.exhibitionPageNumber + 1,
                exhibitionList: [...state.exhibitionList, ...action.payload.current],
                exhibitionFeaturedList: [...state.exhibitionFeaturedList, ...action.payload.featured],
                exhibitionUpcomingList: [...state.exhibitionUpcomingList, ...action.payload.upcoming],
                exhibitionPastList: [...state.exhibitionPastList, ...action.payload.past],
                totalCurrent: action.payload.total_current,
                totalUpcoming: action.payload.total_upcoming,
                totalPast: action.payload.total_past,
                totalFeatured: action.payload.total_featured,
            }
        case eventTypes.PROGRAM_LIST_SUCCESS:
            return {
                ...state,
                programList: action.payload,
            }
        case eventTypes.RENTAL_LIST_SUCCESS:
            return {
                ...state,
                rentalList: action.payload,
            }
        default:
            return state
    }
}
