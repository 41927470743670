import React, { useState, useEffect } from 'react'

import ReactPlayer from 'react-player'
import styled from 'styled-components'

import colors from '../../../css/colors.scss'


export const VideoModal = props => {
    const [videoProps, setvideoProps] = useState(props)

    useEffect(() => {
        setvideoProps(props)
    }, [props])

    const { url, showModal, closeModal } = videoProps

    return (
        <>
            {showModal && <Modal showModal={showModal}>
                <CloseContainer>
                    <Close onClick={closeModal}>&#xd7;</Close>
                </CloseContainer>
                <ReactPlayer url={url} playing={true} height='100vh' width='100vw' controls={true} />
            </Modal>}
        </>
    )
}

const Modal = styled.div`
    height: 100vh;
    width: 100vw;
    background: black;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;

    iframe {
        height: calc(100vh - 40px) !important;
    }
`

const CloseContainer = styled.div`
    height: 40px;
    width: 40px;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
`

const Close = styled.div`
    color: ${colors.white};
    position: absolute;
    right: 16px;
    font-size: 35px;
`
