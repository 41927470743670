import React from 'react'

import styled from 'styled-components'

import colors from '../../css/colors.scss'
import {
    SecondaryButton,
    Header,
    UpperHeader,
    devices,
} from '../Common'

export const NewsImageItem = ({ news }) => (
    <Item>
        <a href={news.article} target='_blank' rel='noopener noreferrer' style={{ width: '100%' }}>
            <Header fontsize={47}>{news.title}</Header>
            <Publication margintop={10}>{news.publication}</Publication>
            <Date marginbottom={10}>{news.date}</Date>
            <Image background={news.image} />
            <Description dangerouslySetInnerHTML={{ __html: news.description }} />
        </a>
    </Item>
)

export const NewsItem = ({ news }) => (
    <Item bordered={true}>
        <Info>
            <Header fontsize={47}>{news.title}</Header>
            <Publication margintop={10}>{news.publication}</Publication>
            <Date>{news.date}</Date>
            <Description dangerouslySetInnerHTML={{ __html: news.description }} />
        </Info>
        <FullArticle>
            <SecondaryButton as='a' href={news.article} target='_blank' rel='noopener noreferrer'>Read Full Article</SecondaryButton>
        </FullArticle>
    </Item>
)

const Publication = styled(UpperHeader)`
    @media ${devices.small} {
        margin-bottom: 0;
    }
`

const Item = styled.div`
    display: flex;

    ${({ bordered }) => bordered ? `
        margin: 0 0 50px;
        padding: 0 0 50px;
        border-bottom: 1px solid ${colors.darkGreen};

        &:last-child {
            margin: 0;
            padding: 0;
            border-bottom: 0;
        }
    ` : `
        margin: 0 0 50px;

        &:last-child {
            margin: 0;
        }
    `}

    a {
        display: block;
        color: inherit;
        text-decoration: none;
    }
`

const Info = styled.div`
    width: 80%;
    padding: 0 30px 0 0;

    @media ${devices.small} {
        width: 100%;
        padding: 0;
    }
`

const FullArticle = styled.div`
    min-width: 215px;

    @media ${devices.small} {
        margin: 20px auto 0;
    }
`

const Description = styled.div`
    margin-top: 15px;
`

const Date = styled.p`
    margin-bottom: 20px;
`

const Image = styled.div`
    background: center / cover no-repeat url(${({ background }) => background});
    padding-bottom: 53%;

    @media ${devices.small} {
        padding-bottom: 100%;
    }
`
