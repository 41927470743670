/* eslint-disable react/display-name */
import React from 'react'

import { Checkbox, FormLabel, ExtraInfo, InputWrap } from '../../Common'

export const CheckList = React.forwardRef(({ label, extra, options, name }, ref) => (
    <InputWrap>
        <FormLabel>{label}</FormLabel>
        {extra && <ExtraInfo>{extra}</ExtraInfo>}
        {options.map(o =>
            <Checkbox key={o.id}>
                <input
                    type='checkbox'
                    name={name}
                    value={o.label}
                    id={o.id}
                    ref={ref}
                />
                <label htmlFor={o.id}>{o.label}</label>
            </Checkbox>
        )}
    </InputWrap>
))
