import React, { useRef, useState, useCallback, Fragment } from 'react'

import { Link, useHistory } from 'react-router-dom'
import Slider from 'react-slick'
import styled from 'styled-components'

import colors from '../../css/colors.scss'
import { Header, SecondaryButton, devices } from '../Common'


export const DetailEventList = ({ events }) => {
    return events.map((e,i) =>
        <DetailContainer key={i}>
            <DetailImage background={e.image} aria-label={e.alt_text} />
            <Center>
                <Header fontSize={31}>{e.title}</Header>
                <DateAndCategory detailpage={true}>
                    <div>{e.date[1]}&nbsp;{e.date[0]},&nbsp;{e.date[2]}</div>
                    {e.category}
                </DateAndCategory>
            </Center>
        </DetailContainer>
    )
}

export const FeaturedEvents = ({ title, events, detailPage, mobile }) => {
    const history = useHistory()

    const sliderRef = useRef()

    const [swiped, setSwiped] = useState(false)

    const handleSwiped = useCallback(() => {
        setSwiped(true)
    }, [setSwiped])

    const handleOnItemClick = useCallback(
        (e) => {
            if (swiped) {
                e.stopPropagation()
                e.preventDefault()
                setSwiped(false)
            }
        },
        [swiped],
    )

    const sliderSettings = {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        arrows: true,
        dots: false,
        touchThreshold: 20,
        responsive: [
            {
                breakpoint: 740,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                }
            },
        ],
        onSwipe: () => {
            handleSwiped()
        },
    }

    // useEffect(() => {
    //     const target = sliderRef.current.querySelector('.slick-track')
    //     const wrapper = document.createElement('div')
    //     wrapper.classList.add('slick-frame')
    //     target.parentNode.insertBefore(wrapper, target)
    //     wrapper.appendChild(target)
    // }, [sliderRef])

    return (
        <Fragment>
            {!mobile && <Header fontSize={68} marginbottom={30}>{title}</Header>}
            <SliderWrap ref={sliderRef}>
                <StyledCarousel {...sliderSettings}>
                    {events.map((event, i) => (
                        <Slide key={i} onClickCapture={handleOnItemClick}>
                            <EventCard onClick={detailPage ? () => history.push(event.url) : undefined} style={{ cursor: `${detailPage ? 'pointer' : 'default'}` }} src={event.image}>
                                <Image image={event.image} aria-label={event.alt_text} />
                                {detailPage ? (
                                    <Center>
                                        <Header fontSize={31}>{event.title}</Header>
                                        <DateAndCategory detailpage={detailPage && 1}>
                                            <div>{event.date[1]}&nbsp;{event.date[0]},&nbsp;{event.date[2]}</div>
                                            {event.category}
                                        </DateAndCategory>
                                    </Center>
                                ) : (
                                    <InfoContainer>
                                        <Header>{event.title}</Header>
                                        <Subtitle>{event.subtitle}</Subtitle>
                                        <DateAndCategory>
                                            {event.date}<br />
                                            {event.category}
                                        </DateAndCategory>
                                        <Button
                                            as={Link}
                                            to={event.url}
                                            color={mobile ? colors.white : colors.darkGreen}
                                        >
                                            Learn More
                                        </Button>
                                    </InfoContainer>
                                )}
                            </EventCard>
                        </Slide>
                    ))}
                </StyledCarousel>
            </SliderWrap>
        </Fragment>
    )
}

const SliderWrap = styled.div``

const StyledCarousel = styled(Slider)`
    &.slick-slider {
        position: relative;
    }
    .slick-list {
        //overflow: hidden;
        margin: 0 -10px;
        @media ${devices.small} {
            margin: 0;
        }
    }
    .slick-track {
        display: flex !important;
    }
    .slick-slide {
        float: none !important;
        padding: 0 10px;
        @media ${devices.small} {
            padding: 0;
        }
    }
    .slick-prev,
    .slick-next {
        width: 30px;
        height: 60px;
        background: url('/static/images/sprite.svg') no-repeat 1px -299px;
        position: absolute;
        top: 12vw;
        z-index: 1;
        transform: translate(0,0);
        transition: opacity .3s;
        &.slick-disabled {
            opacity: 0;
            pointer-events: none;
        }
        @media (min-width:1220px) {
            top: 165px;
        }
        @media ${devices.medium} {
            width: 20px;
            height: 31px;
            background-position: -141px -313px;
            top: 14vw;
        }
    }
    .slick-prev {
        left: -50px;
        @media ${devices.large} {
            left: -35px;
        }
        @media ${devices.medium} {
            left: -20px;
        }
    }
    .slick-next {
        right: -50px;
        transform: translate(0,0) rotate(180deg);
        @media ${devices.large} {
            right: -35px;
        }
        @media ${devices.medium} {
            right: -20px;
        }
    }
    .slick-dots {
        margin: 26px 0 0;
        position: unset;
        li {
            display: inline-block;
            vertical-align: top;
            width: 16px;
            height: 16px;
            margin: 0 17px 0 0;
            border-radius: 16px;
            border: 1px solid ${colors.darkGreen};
            cursor: pointer;
            &.slick-active {
                background: ${colors.yellow};
            }
        }
    }
    @media ${devices.small} {
        .slick-dots {
            margin: 0;
            position: absolute;
            bottom: 30px;
            white-space: nowrap;
            li {
                border: 1px solid ${colors.white};
            }
        }
    }
`

const InfoContainer = styled.div``

const DetailContainer = styled.div`
    padding: 30px 0;
    &:not(:last-child) {
        border-bottom: 1px solid ${colors.darkGreen};
    }
`

const Image = styled.div`
    height: 0;
    background: url(${({ image }) => image}) no-repeat 50% 50%/cover;
    padding-bottom: 100%;
    margin: 0 0 20px;
`

const DetailImage = styled.div`
    height: 0;
    padding-bottom: 100%;
    background: url(${({ background }) => background}) no-repeat 50% 50%/cover;
    margin: 0 0 10px;
`

const Subtitle = styled.div`
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 700;
`

const DateAndCategory = styled.div`
    font-size: 16px;
    margin-bottom: 15px;

    ${({ detailpage }) => detailpage ? `
        margin-bottom: 0;
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
    ` : ''}
`

const Button = styled(SecondaryButton)`
    width: 220px;
    @media ${devices.medium} {
        width: 200px;
    }
`

const Center = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`

const EventCard = styled.div`
    position: relative;
`

const Slide = styled.div`
    display: block !important;
    @media ${devices.small} {
        & {
            height: calc(100vh - 60px);
        }
        ${EventCard} {
            height: 100%;
        }
        ${Image} {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            padding: unset;
            margin: unset;
        }
        ${InfoContainer} {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
            height: 100%;
            padding: 20px 20px 80px;
            position: relative;
            z-index: 1;
            background-image: linear-gradient(-180deg, rgba(0,0,0,0) 0%, ${colors.darkGreen} 100%);
            * {
                color: ${colors.white};
            }
        }
    }
`
