import { gardenTypes } from '../_constants'

const initialState = {
    locationList: [],
    plantList: [],
    tourList: [],
}

export function garden(state = initialState, action) {
    switch (action.type) {
        case gardenTypes.LOCATION_LIST_SUCCESS:
            return {
                ...state,
                locationList: action.payload,
            }
        case gardenTypes.PLANT_LIST_SUCCESS:
            return {
                ...state,
                plantList: action.payload,
            }
        case gardenTypes.TOUR_LIST_SUCCESS:
            return {
                ...state,
                tourList: action.payload,
            }
        default:
            return state
    }
}
