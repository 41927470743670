import React, { useState, useEffect } from 'react'

import styled from 'styled-components'

// import colors from '../../css/colors.scss'
import {
    PlantDetail,
} from '../'
import { devices } from '../Common'

let firsthit = false

export const PlantItem = ({ color, plant, months }) => {
    const [showdetail, setShowdetail] = useState(false)
    const { blooming_end_month, blooming_start_month } = plant
    let start = months.indexOf(Number(blooming_start_month)),
        end = months.indexOf(Number(blooming_end_month))
    let delta
    if (plant.year_round_bloom) {
        delta = 2
        start = 0
    } else {
        if (start < 0) start = 0
        if (end < 0) end = 2
        delta = end - start
        // if (delta <= 0) delta = 0
    }

    useEffect(() => {
        if (window.location.hash.substring(1) === plant.slug && !firsthit) {
            firsthit = true
            setShowdetail(true)
        }
    }, [])

    const startPos = (33.3333 * start) + 1, barWidth = (33.3333 * (delta + 1)) - 2

    return (
        <>
            <Plant role="button" onClick={() => setShowdetail(!showdetail)}>
                <PlantLabel>{plant.scientific_name || 'Plant Name'}</PlantLabel>
                <PlantPeriod><Bar color={color} start={startPos} width={barWidth} /></PlantPeriod>
            </Plant>
            {showdetail && <PlantDetailOffset id={window.location.hash.substring(1)}/>}
            {showdetail && <PlantDetail plant={plant} setShowdetail={setShowdetail} color={color} />}
        </>
    )
}

const PlantDetailOffset = styled.div`
    position: relative;
    top: 20px;
    pointer-events: none;
`

const PlantLabel = styled.div`
    position: relative;
    top: 2px;
    font-size: 18px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;

    @media not all and (min-resolution:.001dpcm) { @supports (-webkit-appearance:none) and (display:flow-root) {
        & {
            hyphens: none;
        }
    }}

    @media ${devices.small} {
        font-size: 14px;
    }
`

const Plant = styled.div`
    display: flex;
    justify-content: center;
    padding: 0 0 10px;
    cursor: pointer;
    ${PlantLabel} {
        width: 200px;
        padding: 0 0 0 20px;
    }

    @media ${devices.small} {
        ${PlantLabel} {
            width: 55%;
            padding: 0 10px 0 10px;
        }
    }
`

const PlantPeriod = styled.div`
    width: calc(100% - 200px);
    position: relative;

    @media ${devices.small} {
        width: 45%;
    }
`

const Bar = styled.div`
    height: 28px;
    border-radius: 100em;
    background: ${props => props.color || '#000'};
    position: absolute;
    left: ${props => props.start}%;
    width: ${props => props.width}%;

    @media ${devices.small} {
        height: 20px;
    }
`
