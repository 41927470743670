import { eventTypes } from '../_constants/'
import { createAsyncAction, createAction } from '../_helpers/utils'
import {
    Event,
    Exhibition,
    Program,
    Rental,
} from '../_services'

export const loadEvent = createAsyncAction(
    eventTypes.EVENT_DATA_REQUEST,
    eventTypes.EVENT_DATA_SUCCESS,
    Event.get,
)

export const clearEvent = createAction(eventTypes.CLEAR_EVENT)

export const loadEventList = createAsyncAction(
    eventTypes.EVENT_LIST_REQUEST,
    eventTypes.EVENT_LIST_SUCCESS,
    Event.list,
)

export const searchEvents = createAsyncAction(
    eventTypes.EVENT_SEARCH_REQUEST,
    eventTypes.EVENT_SEARCH_SUCCESS,
    Event.search,
)

export const loadProgramList = createAsyncAction(
    eventTypes.PROGRAM_LIST_REQUEST,
    eventTypes.PROGRAM_LIST_SUCCESS,
    Program.list
)

export const loadRentalList = createAsyncAction(
    eventTypes.RENTAL_LIST_REQUEST,
    eventTypes.RENTAL_LIST_SUCCESS,
    Rental.list,
)

export const loadExhibition = createAsyncAction(
    eventTypes.EXHIBITION_DATA_REQUEST,
    eventTypes.EXHIBITION_DATA_SUCCESS,
    Exhibition.get,
)

export const loadExhibitionList = createAsyncAction(
    eventTypes.EXHIBITION_LIST_REQUEST,
    eventTypes.EXHIBITION_LIST_SUCCESS,
    Exhibition.list,
)

export const setFilteredEventsQuery = createAction(eventTypes.SET_FILTERED_EVENTS_QUERY)
export const clearFilteredEvents = createAction(eventTypes.CLEAR_FILTERED_EVENTS)
export const changeCurrent = createAction(eventTypes.CHANGE_CURRENT)
