import React, { Fragment } from 'react'

import styled from 'styled-components'

import colors from '../../../css/colors.scss'
import {
    minDevices,
    devices,
} from '../../Common'


export const SocialIcons = () => (
    <Fragment>
        <SocialItem type={'instagram'} as='a' href='https://instagram.com/houstonbotanic' target='_blank' rel='noopener noreferrer'></SocialItem>
        <SocialItem type={'twitter'} as='a' href='https://twitter.com/houstonbotanic' target='_blank' rel='noopener noreferrer'></SocialItem>
        <SocialItem type={'facebook'} as='a' href='https://facebook.com/houstonbotanicgarden' target='_blank' rel='noopener noreferrer'></SocialItem>
    </Fragment>
)

const MenuItem = styled.div`
    /* color: #fff; */
    text-transform: uppercase;
    text-decoration: none;
    font-size: 15px;
    padding: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    ${({ hideMobile }) => hideMobile && `
        @media ${devices.small} {
            display: none;
        }
    `}
    @media ${minDevices.small} {
        /* &:last-child {
            padding-right: 25px !important;
        } */
    }
`

const SocialItem = styled(MenuItem)`
    display: block;
    width: 50px;
    height: 50px;
    margin: 0 10px 0 0;
    padding: 0;
    border: 1px solid ${colors.darkGreen};
    border-radius: 100%;
    background: url('/static/images/sprite.svg') no-repeat 5px -207px;
    ${props => props.type === 'twitter' ? 'background-position: -46px -206px;' : props.type === 'facebook' ? 'background-position: -96px -207px;' : ''}

    @media ${devices.small} {
        margin: 0 10px;
    }
`
