import React, { Component, lazy } from 'react'

import ReactGA from 'react-ga'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import styled from 'styled-components'

import { clearPage } from './_actions'
import { history } from './_helpers'
import { errorServices } from './_services'
import {
    Footer,
    Header,
    SideNav,
    VideoList,
    BackTop,
} from './Components'
import './css/App.scss'


ReactGA.initialize('UA-97174384-1', { debug: false })
Modal.setAppElement('#root')

const ExhibitionList = lazy(() => import(/* webpackChunkName: "ExhibitionList" */ './Components/Exhibitions/ExhibitionList'))
const ExhibitionDetail = lazy(() => import(/* webpackChunkName: "ExhibitionDetail" */ './Components/Exhibitions/ExhibitionDetail'))
const Home = lazy(() => import(/* webpackChunkName: "Home" */ './Components/Home/Home'))
const PageView = lazy(() => import(/* webpackChunkName: "PageView" */ './Components/Content/PageView'))
const Gardens = lazy(() => import(/* webpackChunkName: "Gardens" */ './Components/Garden/Gardens'))
const SponsorshipList = lazy(() => import(/* webpackChunkName: "SponsorshipList" */ './Components/Support/SponsorshipList'))
const VolunteerList = lazy(() => import(/* webpackChunkName: "VolunteerList" */ './Components/Support/VolunteerList'))
const EventCalendar = lazy(() => import(/* webpackChunkName: "EventCalendar" */ './Components/Event/EventCalendar'))
const ProgramList = lazy(() => import(/* webpackChunkName: "ProgramList" */ './Components/Event/ProgramList'))
const RentalList = lazy(() => import(/* webpackChunkName: "RentalList" */ './Components/Event/RentalList'))
const EventDetail = lazy(() => import(/* webpackChunkName: "EventDetail" */ './Components/Event/EventDetail'))
const TourList = lazy(() => import(/* webpackChunkName: "TourList" */ './Components/Tours/TourList'))
const BloomList = lazy(() => import(/* webpackChunkName: "BloomList" */ './Components/Bloom/BloomList'))
const Employment = lazy(() => import(/* webpackChunkName: "Employment" */ './Components/Support/Employment'))
const VisitMap = lazy(() => import(/* webpackChunkName: "VisitMap" */ './Components/Map/VisitMap'))
const SpeakerRequestForm = lazy(() => import(/* webpackChunkName: "SpeakerRequestForm" */ './Components/Learn/SpeakerRequestForm'))
const TourRequestForm = lazy(() => import(/* webpackChunkName: "TourRequestForm" */ './Components/Forms/TourRequestForm'))
const Search = lazy(() => import(/* webpackChunkName: "Search" */ './Components/Search/Search'))


class ErrorBoundary extends Component {
    state = {
        hasError: false,
    }

    componentDidCatch(error, info) {
        if (process.env.NODE_ENV === 'production') {
            errorServices.sendError(
                error.message,
                info.componentStack,
                window.location.href
            )
        } else {
            console.error(error, info)
        }
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    render() {
        if (this.state.hasError) return <h1>Something went wrong.</h1>
        return this.props.children
    }
}

const PageRoute = ({ component: Component, path, exact, ...props }) => (
    <Route
        exact={exact}
        path={path}
        render={() => <Component {...props} key={props.location.key} match={props.match || props.computedMatch} />}
    />
)


class App extends Component {
    state = {
        GAStarted: false
    }

    componentDidMount() {
        const { dispatch } = this.props
        this.sendGA(window.location.pathname)
        history.listen(location => {
            dispatch(clearPage())
            window.scrollTo(0, 0)
            this.sendGA(location.pathname)
        })
    }

    sendGA = pathname => {
        if (process.env.NODE_ENV === 'production') {
            ReactGA.pageview(pathname)
        }
    }

    render() {
        const { alert } = this.props.page
        return (
            <ErrorBoundary>
                <Router history={history}>
                    <MainPage>
                        <Route path='*' component={Header} />
                        <Route path='*' component={SideNav} />
                        <PageWrap activeAlert={!!alert}>
                            <Switch>
                                <Route exact strict path='/:url*' render={props => <Redirect to={`${props.location.pathname}/`} />} />
                                <PageRoute exact path='/' component={Home} />
                                <PageRoute exact path='/events/' component={EventCalendar} />
                                <PageRoute exact path='/about/employment/' component={Employment} />
                                <PageRoute exact path='/events/rentals/' component={RentalList} />
                                <PageRoute exact path='/events/:slug/' component={EventDetail} />
                                <PageRoute exact path='/gardens/' component={Gardens} />
                                <PageRoute exact path='/support/sponsor/' component={SponsorshipList} />
                                <PageRoute exact path='/support/volunteer/' component={VolunteerList} />
                                <PageRoute exact path='/visit/exhibitions/' component={ExhibitionList} />
                                <PageRoute exact path='/visit/exhibitions/:slug/' component={ExhibitionDetail} />
                                <PageRoute exact path='/visit/tours' component={TourList} />
                                <PageRoute exact path='/visit/tours/request-tour/' component={TourRequestForm} />
                                <PageRoute exact path='/visit/blooming/' component={BloomList} />
                                <PageRoute exact path='/visit/map/' component={VisitMap} />
                                <PageRoute exact path='/learn/programs/' component={ProgramList} />
                                <PageRoute exact path='/learn/watch/' component={VideoList} />
                                <PageRoute exact path='/learn/request-a-speaker/' component={SpeakerRequestForm} />
                                <PageRoute exact path='/search/:query' component={Search} />

                                {/* Last route to catch all pages*/}
                                <Route component={PageView} />
                            </Switch>
                        </PageWrap>
                        <Route path='*' component={Footer} />
                        <Route path='*' component={BackTop} />
                    </MainPage>
                </Router>
            </ErrorBoundary>
        )
    }
}

const PageWrap = styled.main`
    flex: 1;

    > div:first-child:before {
        content: "";
        display: table;
    }
`

const MainPage = styled.div`
    /* display: ${props => props.ie ? 'block' : 'flex'};
    flex-direction: column;
    min-height: 100vh;
    max-height: 100vh;
    max-width: 100vw; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
`

function mapStateToProps(state) {
    return {
        page: state.content.page,
    }
}
const connectedApp = connect(mapStateToProps)(App)
export { connectedApp as App }
