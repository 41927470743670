import React, { useState } from 'react'

import styled from 'styled-components'

import colors from '../../../css/colors.scss'


export const ToggleSwitch = ({ name, label1, label2, callBack }) => {
    const [switchstate, setSwitchstate] = useState(false)

    const handleChange = event => {
        setSwitchstate(event.target.value === label2)
        callBack(event.target.value)
    }

    return (
        <Switch>
            <label>
                <input
                    type='radio'
                    name={name}
                    value={label1}
                    defaultChecked
                    onChange={handleChange}
                />
                <div>{label1}</div>
            </label>

            <label>
                <input
                    type='radio'
                    name={name}
                    value={label2}
                    onChange={handleChange}
                />
                <div>{label2}</div>
            </label>

            <ActiveIndicator switchstate={switchstate}/>
        </Switch>
    )
}

const Switch = styled.div`
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    border: 1px solid ${colors.darkGreen};
    border-radius: 100em;

    label {
        position: relative;
        z-index: 2;
        cursor: pointer;
        white-space: nowrap;
        font-size: 16px;
        line-height: 1.2;
        font-weight: bold;
        text-align: center;
        // margin: auto;

        input {
            position: absolute;
            opacity: 0;
            pointer-events: none;

            &:checked + div {
                color: ${colors.white};
            }
        }

        div {
            width: 100%;
            padding: 10px 14px 8px;
            color: ${colors.darkGreen};
            pointer-events: none;
            transition: color .2s;
        }
    }
`

const ActiveIndicator = styled.div`
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: ${colors.darkGreen};
    border-radius: 100em;
    transform: ${({ switchstate }) => switchstate ? 'translate3d(100%,0,0)' : 'translate3d(0,0,0)'};
    pointer-events: none;
    transition: transform .3s;
`
