import React, { Fragment, useEffect } from 'react'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'

import {
    NotFound,
    HeroCarousel,
    SimpleCarousel,
    SideMenu,
    GardenCollectionHeader,
} from '../'
import { loadPage } from '../../_actions'
import { scrollToAnchor, scrollTimer, useWindowSize } from '../../_helpers'
import {
    Header,
    ArticleWrap,
    Container,
    Row,
    Meta,
    UpperHeader,
    ContentWrap,
    Hero,
    ArticleWrapColumn,
    IntroText,
    SecondaryButton,
} from '../Common'
import { DropdownNav } from '../Global/DropdownNav'


export const PageWithMenu = ({ ChildComponent, pathnameOverride, scrollOffset, hideIntro }) => {
    const dispatch = useDispatch()
    const { page, notFound, ie } = useSelector(state => state.content, shallowEqual)
    const { subCategory, category } = useParams()
    let location = useLocation()
    const pathname = pathnameOverride || location.pathname
    const { width } = useWindowSize()

    useEffect(() => {
        dispatch(loadPage(pathname))
        location.hash = decodeURIComponent(location.hash)
        scrollTimer(scrollOffset)
    }, [pathname])

    useEffect(() => {
        scrollToAnchor()
    }, [location.hash])

    const showMenu = page.page_menu && page.page_menu.length > 0

    if (notFound) return <NotFound />

    const buildMenu = () => {
        let firstLevel = []
        if (page.page_menu && page.page_menu.length) {
            firstLevel = page.page_menu.map(a => ({
                ...a,
                pathname: pathname.replace(subCategory, a.slug),
                active: a.url === pathname
            }))
        }
        else if (subCategory && page.parents && page.parents.length) {
            firstLevel = page.parents.map(a => ({
                ...a,
                pathname: pathname.replace(category, a.slug).replace(subCategory, ''),
                secondLevel: a.slug,
            }))
        }
        return firstLevel
    }
    const menu = buildMenu()
    const activePage = menu.find(m => m.active === true)

    return (
        <Fragment>
            <Meta page={page} />

            {location.pathname === '/gardens/' ? (
                <GardenCollectionHeader left={page.left_hero} right={page.right_hero} />
            ) : ''}

            {location.pathname === '/lightscape/' && page.hero_carousel && page.hero_carousel.length > 0 ? (
                <HeroCarousel slides={page.hero_carousel} gradient={false} />
            ) : ''}

            <Container>
                <Row margintop={50} marginbottom={50}>
                    {menu.length > 0 && width > 740 && <SideMenu menu={menu} />}
                    <ContentWrap has_menu={menu.length ? true : false}>
                        {location.pathname !== '/gardens/' ? (
                            <>
                                {location.pathname !== '/lightscape/' ? (
                                    <>
                                        <Header fontSize={68} unsetmaxwidth>
                                            {width <= 740 ? page.sub_title : page.title}
                                        </Header>
                                        {
                                            width <= 740 ? <DropdownNav menu={menu} active={activePage ? activePage : ''} />
                                                : <UpperHeader marginbottom={30}>{page.sub_title}</UpperHeader>
                                        }
                                    </>
                                ) : ''}
                                <>
                                    {location.pathname !== '/lightscape/' && page.hero_carousel && page.hero_carousel.length > 0 ? (
                                        <SimpleCarousel slides={page.hero_carousel} />
                                    ) : ''}
                                    {page.intro_image ? (
                                        <Hero background={page.intro_image} />
                                    ) : ''}
                                </>
                                {page.intro && !hideIntro ? <IntroText shorten={true} dangerouslySetInnerHTML={{ __html: page.intro }} marginbottom={0} /> : ''}
                                {page.intro_file && <SecondaryButton as='a' href={page.intro_file} target='_blank' marginbottom={60}>{page.intro_file_text ? page.intro_file_text : 'View File'}</SecondaryButton>}
                            </>
                        ) : ''}
                        <ArticleWrapColumn margintop={page.intro ? 0 : 40}>
                            <ArticleWrap>
                                <ChildComponent page={page} showMenu={showMenu} ie={ie} />
                            </ArticleWrap>
                        </ArticleWrapColumn>
                    </ContentWrap>
                </Row>
            </Container>
        </Fragment>
    )
}
