import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation, Redirect } from 'react-router-dom'
import styled from 'styled-components'

import { loadMenu, toggleMenu, loadWeather, search } from '../../_actions'
import { useWindowSize } from '../../_helpers'
import colors from '../../css/colors.scss'
import {
    UnstyledLink,
    devices,
    Hr,
    TextInput,
} from '../Common'
import { SocialIcons } from '../Content'
import { AlertBanner } from './Header'


const NavigationItem = ({ toggle, header, links, open, closeMenu, URL }) => (
    <NavItem>
        <NavItemHeader
            as={links.length === 0 ? Link : ''}
            to={links.length === 0 ? URL : ''}
            onClick={links.length === 0 ? closeMenu(URL) : toggle}>
            {header}
        </NavItemHeader>
        {links.length > 0 ? (
            <NavItemContent open={open}>
                {links.map(item =>
                    <NavItemContentItem key={item.title}>
                        <UnstyledLink
                            as={item.url.match(/^https?/) ? 'a' : Link}
                            to={item.url.match(/^https?/) ? undefined : `${item.url}${item.hash ? `#${item.hash}` : ''}`}
                            href={item.url.match(/^https?/) ? item.url : undefined}
                            onClick={closeMenu(item.linked_page)}
                            rel={item.external_url ? 'noopener noreferrer' : ''}
                            target={item.external_url ? '_blank' : ''}
                        >
                            {item.title}
                        </UnstyledLink>
                    </NavItemContentItem>
                )}
            </NavItemContent>
        ) : ''}
    </NavItem>
)

const WeatherContainer = ({ weather, open, closePanel }) => {
    const { width } = useWindowSize()
    const dispatch = useDispatch()
    // const { query } = useSelector(state => state.content)
    const mobile = width <= 740
    const { condition, temp } = weather
    const [cartEmpty, setCartEmpty] = useState(true)
    const [submit, setSubmit] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    
    const stringObj = (str) => {
        return str.split(/[;] */).reduce(function(result, pairStr) {
            var arr = pairStr.split('=')
            if (arr.length === 2) {result[arr[0]] = arr[1]}
            return result
        }, {})
    }
    
    const performSearch = (e) => {
        e.preventDefault()
        if (searchValue) {
            setSubmit(true)
            dispatch(search(searchValue))
        }
    }

    useEffect(() => {
        if (stringObj(document.cookie)['cart_empty'] === 'false') {
            setCartEmpty(false)
        } else {
            setCartEmpty(true)
        }
    }, [])

    return (
        <InfoBox>
            <Column>
                <HoursAndWeather>
                    <p>Today&apos;s Weather</p>
                    <p>{condition} | {temp}&deg;F</p>
                </HoursAndWeather>
                <HoursAndWeather>
                    <p>{open ? 'Open' : 'Closed'} Today</p>
                    {open ? <p>{open}</p> : ''}
                </HoursAndWeather>
                { submit ? <Redirect to={ `/search/${searchValue}` } /> : '' }
                <TextInput placeholder={'Search'} onSubmit={performSearch} onChange={ (e) => setSearchValue(e.target.value) } />
            </Column>
            {mobile &&
                <Column>
                    <AccountLink href="https://secure.hbg.org/account/login?ReturnUrl=%2faccount%2fupcomingevents">My Account</AccountLink>
                    <CartLink href="https://secure.hbg.org/cart/details/" aria-label="View Cart" cartEmpty={cartEmpty}/>
                </Column>
            }
            {closePanel && <CloseButton role='button' aria-label='Close Menu' onClick={closePanel} border />}
        </InfoBox>
    )
}

export const SideNav = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()
    const { menu, menuOpen, weather } = useSelector(state => state.content)
    const { open, alert } = useSelector(state => state.content.page)
    const { width } = useWindowSize()
    const mobile = width <= 740

    const [openNavItem, setOpenNavItem] = useState('')

    useEffect(() => {
        dispatch(loadMenu())
        dispatch(loadWeather())
    }, [])

    const changeLocation = location => e => {
        e.preventDefault()
        if (!location) return
        if (location !== history.location.pathname) {
            history.push(location)
        }
        dispatch(toggleMenu(false))
        document.body.style.overflow = 'visible'
    }

    const toggleNavItem = title => () => setOpenNavItem(title === openNavItem ? '' : title)

    // close individual menuItem
    const closeMenu = location => e => {
        dispatch(toggleMenu(false))
        document.body.style.overflow = 'visible'
        setOpenNavItem('')
        if (!location || e.metaKey) return
    }

    // Hide the entire side menu
    const closePanel = () => {
        dispatch(toggleMenu(false))
        document.body.style.overflow = 'visible'
        setOpenNavItem('')
    }

    useEffect(() => {
        closePanel()
    }, [location])

    return (
        <NavigationWrap open={menuOpen}>
            {mobile && !!alert && <AlertBanner dangerouslySetInnerHTML={{ __html: alert }} />}
            {mobile &&
                <MobileControls alert={!!alert}>
                    <MobileLogo />
                    <CloseButton role='button' aria-label='Close Menu' onClick={closePanel} />
                </MobileControls>
            }
            <Menu open={menuOpen}>
                {mobile && <WeatherContainer weather={weather} open={open} />}
                {menu.map(link =>
                    <NavigationItem
                        key={link.title}
                        header={link.title}
                        changeLocation={changeLocation}
                        closeMenu={closeMenu}
                        toggle={toggleNavItem(link.title)}
                        open={openNavItem === link.title}
                        links={link.children}
                        URL={link.url}
                    />
                )}
                <Hr margintop={mobile ? 0 : 20} marginbottom={mobile ? 20 : 20} />
                <NavItem>
                    <NavItemHeader as={Link} to='/support/membership/' onClick={closeMenu('/support/membership/')}>Become a Member</NavItemHeader>
                </NavItem>
                <NavItem>
                    <NavItemHeader as={Link} to='/support/' onClick={closeMenu('/support/')}>Donate</NavItemHeader>
                </NavItem>
                <Hr margintop={mobile ? 0 : 20} marginbottom={mobile ? 20 : 20} />
                <IconContainer>
                    <SocialIcons />
                </IconContainer>
            </Menu>
            {!mobile && <WeatherContainer weather={weather} open={open} closePanel={closePanel} />}
        </NavigationWrap>
    )
}

const MobileLogo = styled.div`
    background: url('/static/images/sprite.svg') no-repeat -220px -210px;
    width: 223px;
    height: 30px;
`

const IconContainer = styled.div`
    display: flex;

    @media ${devices.small} {
        justify-content: center;
        margin: 30px 0;
    }
`

const HoursAndWeather = styled.div`
    margin-bottom: 20px;
    p:first-child {
        font-weight: bold;
    }
`

const CloseButton = styled.div`
    width: 50px;
    height: 50px;
    background: url('/static/images/sprite.svg') no-repeat  13px -444px;
    ${({ border }) => border && `border: 1px solid ${colors.darkGreen};`}
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
`

const NavItem = styled.div`
    position: relative;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    @media ${devices.small} {
        margin-bottom: 0;
        padding-bottom: 20px;
    }
`

const NavigationWrap = styled.div`
    display: flex;
    flex-direction: row;
    width: 50vw;
    min-width: 840px;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    flex: 1;
    z-index: 9999999;
    padding: 50px;
    background: ${colors.pink};
    color: ${colors.darkGreen};
    transform: translate3d(${({ open }) => open ? 0 : 100}%,0,0);
    transition: transform .4s;

    @media ${devices.medium} {
        min-width: 640px;
        padding: 25px;
    }

    @media ${devices.small} {
        padding: 10px 15px 0;
        width: 100vw;
        min-width: auto;
        flex-direction: column;
        overflow: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        ${AlertBanner} {
            width: 100vw;
            margin: -10px 0 20px -15px;
            padding: 10px 15px;
        }
    }
`

const Menu = styled.div`
    @keyframes repaint_large {
        from {
            width: calc(100% - 1px);
        }
        to {
            width: 100%;
        }
    }
    @keyframes repaint_small {
        from {
            width: calc(100vw - 1px);
        }
        to {
            width: 100vw;
        }
    }
    flex: 1;
    margin: -50px 0 -50px -50px;
    padding: 50px;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    animation: ${({ open }) => open ? `repaint_large .2s` : ``};

    ${NavItem} {
        width: 100%;
    }

    @media ${devices.medium} {
        margin: -25px 0 -25px -25px;
        padding: 25px;
    }

    @media ${devices.small} {
        width: 100vw;
        margin: 0 0 0 -15px;
        padding: 0 15px;
        animation: ${({ open }) => open ? `repaint_small .2s` : ``};
    }
`

const InfoBox = styled.div`
    width: 300px;
    position: relative;
    padding-right: 60px;
    font-size: 15px;

    @media ${devices.medium} {
        width: 220px;
        font-size: calc(15px * .95);
    }

    @media ${devices.small} {
        display: flex;
        width: 100%;
        padding: 5px 0 0 0;
        margin-bottom: 23px;
        border-bottom: 1px solid ${colors.darkGreen};
        font-size: calc(15px * .9);
    }
`

const Column = styled.div`
    @media ${devices.small} {
        width: 50%;
        &:nth-child(2) {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
        }
    }
`

const AccountLink = styled.a`
    display: inline-block;
    margin: 0 20px 0 0;
    color: ${colors.darkGreen};
    text-decoration: none;
    font-weight: bold;
`

const CartLink = styled.a.attrs(({ cartEmpty }) => ({
    style: {
        'backgroundPosition': cartEmpty ? '' : '-304px -23px',
    }
}))`
    display: inline-block;
    width: 30px;
    height: 30px;
    background: url('/static/images/sprite.svg') no-repeat -304px 7px;
    margin: -5px -5px 0 0;
`

const NavItemHeader = styled.div`
    font-family: 'Canela';
    font-size: 40px;
    line-height: 1.2;
    text-decoration: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    div {
        display: inline;
    }

    @media ${devices.medium} {
        font-size: calc(40px * .9);
    }

    @media ${devices.small} {
        font-size: calc(40px * .8);
    }
`

const NavItemContent = styled.div`
    display: ${({ open }) => open ? 'block' : 'none'};
    padding-left: 30px;

    @media ${devices.small} {
        padding-left: 0;
    }
`

const NavItemContentItem = styled.div`
    display: block;
    margin: 0 0 5px;
    font-weight: bold;
    &:last-child {
        margin: 0;
    }
    a {
        display: table;
    }
`

const MobileControls = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 15px;

    ${CloseButton} {
        position: relative;
        right: -16px;
        top: 1px;
    }
`
