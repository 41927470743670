import React from 'react'

import styled from 'styled-components'

import colors from '../../css/colors.scss'
import { SecondaryButton } from '../Common'


export const Program = ({data: {description, file}}) => (
    <ProgramContainer file={!file}>
        <ProgramDescription dangerouslySetInnerHTML={{__html: description}} />
        {file && <SecondaryButton as='a' href={file} target='_blank' color={colors.darkGreen}>Download PDF</SecondaryButton>}
    </ProgramContainer>
)

const ProgramContainer = styled.div`
    ${({file}) => file ? 'padding-bottom: 30px !important;' : ''}
    padding-top: 5px !important;
`

const ProgramDescription = styled.div``
