import React, { forwardRef } from 'react'

import DatePicker from 'react-date-picker'
import { Controller } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'

import { phonenumber } from '../../../_helpers'
import {
    Input,
    FormError,
    TextArea,
    ExtraInfo,
    InputWrap,
    FormLabel,
} from '../../Common'


const TextInput = ({ label, type, name, extra, errors, required, itallicTitle, control}, ref) => (
    <InputWrap>
        <FormLabel style={itallicTitle && {fontStyle: 'italic'}}>{label}{required && '*'}</FormLabel>
        {errors[name] && <FormError>{errors[name].message}</FormError>}
        {extra && <ExtraInfo dangerouslySetInnerHTML={{ __html: extra }} />}
        {type === 'textarea'
            ? <TextArea aria-label={label} name={name} ref={ref} />
            : type === 'tel'
            ? <Controller
                render={props => (
                    <PhoneInput
                        onlyCountries={['us']}
                        country='us'
                        inputProps={{ required }}
                        placeholder=''
                        disableCountryCode
                        disableDropdown
                        specialLabel=''
                        name={name}
                        onChange={value => props.onChange(value)}
                        onBlur={props.onBlur}
                        error={!!errors.contact_phone}
                        inputStyle={{ backgroundColor: 'transparent' }}
                    />
                )}
                defaultValue=''
                name={name}
                control={control}
                rules={{ required: required && 'This field is required', validate: val => phonenumber(val) }}
            />
            : type === 'date'
            ? <Controller
                defaultValue=''
                name={name}
                control={control}
                rules={{ required: required && 'This field is required' }}
                render={({value, onChange}) => (
                    <DatePicker
                        value={value}
                        onChange={value => onChange(value)}
                        calendarIcon={null}
                        clearIcon={null}
                        name={name}
                        minDate={new Date()}
                    />
                )}
            />
            : <Input type={type || 'text'} aria-label={label} name={name} ref={ref} />
        }
    </InputWrap>
)

export const HookTextInput = forwardRef(TextInput)
