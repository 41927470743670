import React from 'react'

import styled from 'styled-components'

import { Grid, devices } from '../../Common'


export const PhotoGrid = ({ title, show_title, gridItems }) => {
    const imageSponsors = gridItems.filter(g => g.image !== null)
    const textSponsors = gridItems.filter(g => g.image === null)

    return (
        <>
        {title && show_title ? (
            <Header as={location.pathname === '/lightscape/' ? 'h3' : ''}
                    center={location.pathname === '/lightscape/'}>{title}</Header>
        ) : ''}

            <SponsorGrid columns={1} isSingle={imageSponsors.length === 1}>
                {imageSponsors && imageSponsors.map((e, i) =>
                    <ImageContainer key={i}>
                        {e.title && <ImageTitle>{e.title}</ImageTitle>}
                        <Image
                            hastitle={!!e.title}
                            image={e.image}
                            isSingle={imageSponsors.length === 1}
                            as={e.external_url ? 'a' : 'div'}
                            href={e.external_url || undefined}
                            target={e.external_url ? '_blank' : undefined}
                            rel={e.external_url ? 'noopener noreferrer' : undefined}
                        />
                    </ImageContainer>
                )}
                {textSponsors && textSponsors.map((t, i) => <TextSponsor key={i}>{t.title}</TextSponsor>)}
            </SponsorGrid>
        </>
    )
}


const Header = styled.h2`
    ${props => (props.center ? `
        text-align: center;
    ` : '')};
    @media ${devices.small} {
        font-size: 50px;
        text-align: center;
    }
`

const SponsorGrid = styled(Grid)`
    flex-wrap: wrap;
    ${props => (props.isSingle ? `
        justify-content: center;
    `:`
        justify-content: space-between;
    `)};

    @media only screen and (max-width: 650px) {
        padding: 0 20px;
        align-items: center;
        flex-direction: row;
    }
`

const TextSponsor = styled.p`
    max-width: calc(33% - 10px);
    text-align: center;
    margin: 20px 0;
    width: 100%;

    @media ${devices.small} {
        max-width: 100%;
    }
`

const ImageTitle = styled.div`
    margin: 20px 0 5px;
    width: 100%;
    font-weight: bold;
    text-align: center;
`

const ImageContainer = styled.div`
    width: 100%;
    max-width: calc(33% - 10px);
    margin-top: 20px;
    margin-bottom: 20px;

    @media ${devices.small} {
        max-width: 100%;
        padding-bottom: 50%;
    }
`

const Image = styled.div`
    background: url(${({ image }) => image});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: ${({hastitle, isSingle}) => hastitle ? 40 : isSingle ? 40 : 20}%;
`
