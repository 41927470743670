import React from 'react'

import { Link } from 'react-router-dom'

import { MenuColumn, MenuItem, MenuLink } from '../Common'


export const SideMenu = ({ menu }) => {
    return (
        <MenuColumn>
            {menu.map((menuPage, i) =>
                <MenuItem key={i}>
                    <MenuLink
                        active={menuPage.active ? 1 : 0}
                        as={menuPage.url.match(/^https?/) ? 'a' : Link}
                        to={menuPage.url.match(/^https?/) ? undefined : menuPage.url}
                        href={menuPage.url.match(/^https?/) ? menuPage.url : undefined}
                        rel={menuPage.external_url ? 'noopener noreferrer' : ''}
                        target={menuPage.external_url ? '_blank' : ''}
                    >
                        {menuPage.title}
                    </MenuLink>
                </MenuItem>
            )}
        </MenuColumn>
    )
}
