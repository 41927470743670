import React from 'react'

import styled from 'styled-components'

import { PrimaryButton, UpperHeader, devices } from '../Common'


export const Volunteer = ({data}) => (
    <VolunteerWrap>
        {data.image && <ImageCard background={data.image} orientation={data.image_orientation}/>}
        <ItemDetails>
            <UpperHeader>{data.title}</UpperHeader>
            <ItemDescription dangerouslySetInnerHTML={{__html: data.description}} />
            {data.link && <PrimaryButton as='a' target='_blank' href={data.link} width='200px' marginright={20}>Sign Up</PrimaryButton>}
        </ItemDetails>
    </VolunteerWrap>
)

const VolunteerWrap = styled.div`
    display: flex;

    @media ${devices.small} {
        display: block;
    }
`

const ImageCard = styled.div`
    width: 340px;
    height: ${({orientation}) => orientation === 'landscape' ? 250 : 480}px;
    margin: 0 30px 0 0;
    background: url(${({background}) => background}) no-repeat 50% 50%/cover;

    @media ${devices.small} {
        width: 100%;
        height: 0;
        margin: 0 0 30px 0;
        padding-bottom: 100%;
    }
`

const ItemDetails = styled.div`
    flex: 1;

    @media ${devices.small} {
        display: flex;
        flex-direction: column;

        ${PrimaryButton} {
            margin: 0 auto;
        }
    }
`

const ItemDescription = styled.div`
    margin-top: 10px;

    & + * {
        margin-top: 30px;
    }
`
