import React from 'react'

import styled from 'styled-components'

import colors from '../../css/colors.scss'
import {
    UpperHeader,
    devices,
    PrimaryButton,
} from '../Common'


const MembershipCard = ({ color, membership: { title, price, url, description, best_value } }) => (
    <Membership color={color}>
        <Info>
            {best_value && <BestValue>Best Value</BestValue>}
            <Header marginbottom={20}>{title}</Header>
            <Price>{`$${price.toLocaleString()}`}</Price>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
        </Info>
        <PrimaryButton as='a' href={url} width={'200px'}>Join / Renew</PrimaryButton>
    </Membership>
)

export const FeaturedMembership = ({ title, memberships, color }) => {
    return (
        <CardsContainer>
            <Title>{title}</Title>
            <StyledCards>
                {memberships.map((m, i) => <MembershipCard key={i} membership={m} color={color} />)}
            </StyledCards>
        </CardsContainer>
    )
}

const Info = styled.div`
    & + * {
        margin-top: 30px;
    }
`

const Header = styled(UpperHeader)`
    font-size: 17px;

    @media ${devices.small} {
        font-size: calc(17px * .9);
    }
`

const Title = styled.h2`
    margin-bottom: 50px;
`

const Price = styled.h2`
    font-size: 31px;

    @media ${devices.small} {
        font-size: calc(31px * .9);
    }
`

const CardsContainer = styled.div`
    width: ${({ carouselwidth }) => carouselwidth}px;
`

const StyledCards = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -15px -30px;

    @media ${devices.small} {
        margin-left: 0;
        margin-right: 0;
    }
`

const Membership = styled.div`
    display: grid;
    grid-template-rows: repeat(1, minmax(0, 1fr));
    justify-items: center;
    width: calc(33.3333% - 30px);
    min-width: 240px;
    height: auto;
    position: relative;
    padding: 30px 20px 30px;
    margin: 0 15px 30px;
    background: ${({ color }) => color || colors.green};
    filter: drop-shadow(0 1px 4px rgba(0,0,0,.3));
    text-align: center;

    &:after, &:before {
        content: "";
        background-color: ${colors.darkGreen};
        position: absolute;
        display: block;
    }

    a:not(${PrimaryButton}) {
        color: ${colors.darkGreen};
        text-decoration: underline;
    }

    @media ${devices.small} {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
`

const Description = styled.div`
    max-width: 280px;
    margin: 20px auto 0;
    font-size: 14px;
    line-height: 1.3;

    p {
        margin: 0 0 8px;
        &:last-child {
            margin: 0;
        }
    }

    @media ${devices.small} {
        font-size: calc(14px * .9);
    }
`

const BestValue = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 22px;
    padding: 1px 0 0;
    position: absolute;
    top: -22px;
    right: 0;
    background: ${colors.yellow};
    font-size: 12px;
    line-height: 1;
    font-weight: bold;

    @media ${devices.small} {
        left: 0;
        margin: 0 auto;
    }
`
