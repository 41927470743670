import { gardenTypes } from '../_constants/'
import { createAsyncAction } from '../_helpers/utils'
import {
    Location,
    Plant,
    Tour,
} from '../_services'


export const loadLocationList = createAsyncAction(
    gardenTypes.LOCATION_LIST_REQUEST,
    gardenTypes.LOCATION_LIST_SUCCESS,
    Location.list,
)

export const loadPlantList = createAsyncAction(
    gardenTypes.PLANT_LIST_REQUEST,
    gardenTypes.PLANT_LIST_SUCCESS,
    Plant.list
)

export const loadTourList = createAsyncAction(
    gardenTypes.TOUR_LIST_REQUEST,
    gardenTypes.TOUR_LIST_SUCCESS,
    Tour.list,
)
