import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

import { content } from './content.reducers'
import { event } from './event.reducers'
import { form as customForm } from './forms.reducers'
import { garden } from './garden.reducers'
import { lightscape } from './lightscape.reducers'
import { support } from './support.reducers'


const rootReducer = combineReducers({
    content,
    event,
    form,
    garden,
    support,
    lightscape,
    customForm,
})

export default rootReducer
