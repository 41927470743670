import { supportTypes } from '../_constants/'
import { createAsyncAction, createAction } from '../_helpers/utils'
import {
    Donation,
    Membership,
    Sponsorship,
    Volunteer,
    Job,
    Staff,
    Department,
} from '../_services'

export const loadDonationList = createAsyncAction(
    supportTypes.DONATION_LIST_REQUEST,
    supportTypes.DONATION_LIST_SUCCESS,
    Donation.list
)

export const loadMembershipList = createAsyncAction(
    supportTypes.MEMBERSHIP_LIST_REQUEST,
    supportTypes.MEMBERSHIP_LIST_SUCCESS,
    Membership.list,
)

export const loadSponsorshipList = createAsyncAction(
    supportTypes.SPONSORSHIP_LIST_REQUEST,
    supportTypes.SPONSORSHIP_LIST_SUCCESS,
    Sponsorship.list,
)

export const loadVolunteer = createAsyncAction(
    supportTypes.VOLUNTEER_DATA_REQUEST,
    supportTypes.VOLUNTEER_DATA_SUCCESS,
    Volunteer.get,
)

export const loadVolunteerList = createAsyncAction(
    supportTypes.VOLUNTEER_LIST_REQUEST,
    supportTypes.VOLUNTEER_LIST_SUCCESS,
    Volunteer.list,
)

export const submitSupportForm = createAsyncAction(
    supportTypes.SPONSORSHIP_EMAIL_POST_REQUEST,
    supportTypes.SPONSORSHIP_EMAIL_POST_SUCCESS,
    Sponsorship.postEmail,
    supportTypes.SPONSORSHIP_EMAIL_POST_FAILURE,
)

export const loadJobList = createAsyncAction(
    supportTypes.JOB_LIST_REQUEST,
    supportTypes.JOB_LIST_SUCCESS,
    Job.list,
)

export const loadStaffList = createAsyncAction(
    supportTypes.STAFF_LIST_REQUEST,
    supportTypes.STAFF_LIST_SUCCESS,
    Staff.list,
)

export const loadDepartmentList = createAsyncAction(
    supportTypes.DEPARTMENT_LIST_REQUEST,
    supportTypes.DEPARTMENT_LIST_SUCCESS,
    Department.list,
)

export const resetSupportEmailMessage = createAction(supportTypes.SPONSORSHIP_EMAIL_POST_RESET_MESSAGES)
