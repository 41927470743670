import React from 'react'

import styled from 'styled-components'

import colors from '../../../css/colors.scss'
import { devices, UpperHeader } from '../../Common'


const Card = ({ item }) => (
    <Membership>
        <Info>
            <Header marginbottom={20}>{item.title}</Header>
            <div dangerouslySetInnerHTML={{ __html: item.conditions.replace(/\n/g,'<br/>') }}/>
            <Price dangerouslySetInnerHTML={{ __html: item.rate.replace(/\n/g,'<br/>') }}/>
            <Desc dangerouslySetInnerHTML={{ __html: item.description.replace(/\n/g,'<br/>') }}/>
        </Info>
        <Extra>
            <Capacity>Cocktail Style Capacity: {item.cocktail_capacity ? item.cocktail_capacity : 'X'}</Capacity>
            <Capacity>Seated Capacity: {item.seated_capacity ? item.seated_capacity : 'X'}</Capacity>
        </Extra>
    </Membership>
)

export const PrivateVenueRentals = ({ pageBlock }) => {
    return (
        <CardsContainer>
            {pageBlock.title && pageBlock.show_title && <Title>{pageBlock.title}</Title>}
            <StyledCards>
                {pageBlock.subblocks.map((item, i) => <Card key={i} item={item}/>)}
            </StyledCards>
        </CardsContainer>
    )
}

const CardsContainer = styled.div`
    font-size: 14px;
    line-height: 1.3;
`

const Title = styled.h2`
    margin-bottom: 50px;
`

const Header = styled(UpperHeader)`
    font-size: 17px;
    @media ${devices.small} {
        font-size: calc(17px * .9);
    }
`

const Extra = styled.div``

const Price = styled.h2`
    margin: 20px 0 0;
    font-size: 31px;
    line-height: 1.4;
    @media ${devices.small} {
        font-size: calc(31px * .9);
    }
`

const Desc = styled.div``

const Capacity = styled.div`
    &:not(:last-child) {
        margin: 0 0 10px;
    }
    div:nth-child(2) {
        font-weight: 700;
    }
`

const StyledCards = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -15px -30px;

    @media ${devices.small} {
        margin-left: 0;
        margin-right: 0;
    }
`

const Membership = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: calc(33.3333% - 30px);
    min-width: 240px;
    height: auto;
    position: relative;
    padding: 30px 20px 30px;
    margin: 0 15px 30px;
    background: ${colors.green};
    filter: drop-shadow(0 1px 4px rgba(0,0,0,.3));
    text-align: center;

    &:after, &:before {
        content: "";
        background-color: ${colors.darkGreen};
        position: absolute;
        display: block;
    }

    @media ${devices.small} {
        display: block;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
`

const Info = styled.div`
    & + * {
        margin-top: 30px;
    }
`
