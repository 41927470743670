import React, { useEffect } from 'react'

import styled from 'styled-components'

import { CreateMap } from '../../_helpers'
import colors from '../../css/colors.scss'
import { SecondaryButton, UpperHeader, IntroText, devices } from '../Common'


export const GardenMap = ({ locationList, page }) => {

    useEffect(() => {
        CreateMap('garden', locationList)
    }, [])

    return (
        <>
            <MapWrap>
                <div id="map"></div>
                <MapCompass/>
            </MapWrap>
            <MapLegend>
                <MapLegendTitle>Map Key</MapLegendTitle>
                <MapLegendKeys>
                    <MapLegendKeysWrap>
                        {locationList.keys.length && locationList.keys.map((data, i) =>
                            <MapLegendKey key={i}>
                                <MapLegendKeyImage image={data.icon} />
                                {data.title}
                            </MapLegendKey>
                        )}
                    </MapLegendKeysWrap>
                    <MapLegendKeysText>All walking trails are ADA-compliant.</MapLegendKeysText>
                </MapLegendKeys>
            </MapLegend>
            <MapDesc dangerouslySetInnerHTML={{__html: page.intro}} />
            {page.intro_file && <Button as='a' href={page.intro_file} target='_blank'>{page.intro_file_text ? page.intro_file_text : 'View File'}</Button>}
        </>
    )
}

const MapWrap = styled.div`
    width: 100%;
    height: 0;
    padding-bottom: 66.6666%;
    position: relative;
    margin: 0 0 30px;
    #map {
        width: 100%;
        height: 100%;
        position: absolute;
        background: #F5F1E5;
        .ol-hidden {
            display: none;
        }
        .ol-overlaycontainer-stopevent {
            pointer-events: none;
            .ol-zoom {
                display: inline-flex;
                flex-direction: column;
                position: absolute;
                top: 15px;
                left: 15px;
                pointer-events: auto;
                button.ol-zoom-in,
                button.ol-zoom-out {
                    width: 30px;
                    height: 30px;
                    margin: 0;
                    padding: 0;
                    background: rgba(255,255,255,.8) url('/static/images/sprite.svg') no-repeat 0 -753px;
                    backdrop-filter: blur(4px);
                    border: 0;
                    border-radius: 0;
                    color: rgba(0,0,0,0);
                    cursor: pointer;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                }
                button.ol-zoom-out {
                    margin: 6px 0 0;
                    background-position: -30px -753px;
                }
            }
        }
        .marker-landmark {
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: default;

            .marker {
                width: 30px;
                height: 30px;
                background: ${colors.blue};
                border-radius: 100%;
                font-family: 'NB International Pro';
                font-size: 16px;
                line-height: 32px;
                font-weight: bold;
                color: ${colors.white};
                text-align: center;
                cursor: pointer;

                > a {
                    display: block;
                    width: 30px;
                    height: 30px;
                    border-radius: 100%;
                    text-decoration: none;
                    pointer-events: none;
                }

                &.open {
                    & + .label {
                        display: flex;
                    }
                }

                &.active {
                    > a {
                        pointer-events: auto;
                    }
                }
            }
            .label {
                display: none;
                justify-content: flex-start;
                width: 240px;
                position: absolute;
                left: 24px;
                right: auto;
                top: 24px;
                bottom: auto;
                pointer-events: none;
                > div {
                    display: inline-flex;
                    flex-direction: column;
                    max-width: 240px;
                    padding: 10px 12px;
                    background: ${colors.blue};
                    border-radius: 12px;
                    font-family: 'NB International Pro';
                    font-size: 12px;
                    line-height: 1.4;
                    color: ${colors.white};
                }
                .name {
                    margin: 0 0 5px;
                    font-size: 16px;
                    line-height: 1.2;
                    font-weight: bold;
                }
                .desc {}
                &.top-left {
                    left: auto;
                    right: 24px;
                    top: auto;
                    bottom: 24px;
                }
                &.top-right {
                    left: 24px;
                    right: auto;
                    top: auto;
                    bottom: 24px;
                }
                &.bottom-left {
                    left: auto;
                    right: 24px;
                    top: 24px;
                    bottom: auto;
                }
                &.bottom-right {
                    left: 24px;
                    right: auto;
                    top: 24px;
                    bottom: auto;
                }
            }
        }
    }

    @media ${devices.small} {
        width: 100vw;
        margin-left: -15px;
        padding-bottom: calc(100vh - 60px);
    }
`

const MapCompass = styled.div`
    width: 68px;
    height: 68px;
    background: url('/static/images/sprite.svg') no-repeat 0 -887px;
    position: absolute;
    bottom: 15px;
    right: 15px;
    pointer-events: none;
`

const MapLegend = styled.div`
    width: 100%;
    margin: 0 0 30px;
`

const MapLegendTitle = styled(UpperHeader)``

const MapLegendKeys = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    margin: 10px 0 0;
    padding: 10px 0;
    border-top: 1px solid ${colors.darkGreen};
    border-bottom: 1px solid ${colors.darkGreen};
    font-size: 13px;
    line-height: 1.4;
    color: ${colors.black};

    @media ${devices.small} {
        display: block;
        margin: -10px 0 0;
    }
`

const MapLegendKeysWrap = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px 20px;
    margin: -10px -15px;

    @media ${devices.medium} {
        padding: 10px 0;
    }
`

const MapLegendKeysText = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-left: 1px solid ${colors.darkGreen};

    @media ${devices.medium} {
        padding: 10px 0 10px 20px;
    }

    @media ${devices.small} {
        display: block;
        margin: 10px 0 0;
        padding: 10px 0 0;
        border-left: 0;
        border-top: 1px solid ${colors.darkGreen};
    }
`

const MapLegendKey = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 15px;
    padding-top: 2px;

    @media ${devices.small} {
        width: calc(50% - 30px);
    }
`

const MapLegendKeyImage = styled.div`
    flex-shrink: 0;
    width: 26px;
    height: 26px;
    margin: -2px 8px 0 0;
    background-size: 26px 26px;
    background-image: url('${image => image.image}');
`

const MapDesc = styled(IntroText)`
    margin: 0 0 30px;
    padding: 0;
`

const Button = styled(SecondaryButton)``
