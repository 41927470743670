import React from 'react'

import styled from 'styled-components'

import { SimpleCarousel } from '../'
import colors from '../../css/colors.scss'
import { PrimaryButton, devices } from '../Common'


export const Rental = ({data: {description, booking_url, images, info}}) => {
    return (
        <ContentContainer>
            <ColumnLeft>
                <Description dangerouslySetInnerHTML={{__html: description}} />
                <BookButton as='a' href={booking_url} targe='_blank' color={colors.red}>Book Now</BookButton>
            </ColumnLeft>
            <ColumnRight>
                <SimpleCarousel slides={images} />
                <Stats>
                    {info.map((e, i) => (
                        <Stat key={i}>
                            <StatTitle>{e.title}</StatTitle>
                            <StatDetail>{e.description}</StatDetail>
                        </Stat>
                    ))}
                </Stats>
            </ColumnRight>
        </ContentContainer>
    )
}

const ContentContainer = styled.div`
    display: flex;
    padding-top: 5px !important;

    @media ${devices.small} {
        flex-direction: column-reverse;
    }
`

const ColumnLeft = styled.div`
    width: 270px;
    padding: 0 30px 0 0;

    @media ${devices.small} {
        width: 100%;
        padding: 0;
        /* margin-top: 20px; */
    }
`

const ColumnRight = styled.div`
    flex: 1;
    width: 100%;
`

const BookButton = styled(PrimaryButton)`
    margin-top: 40px;
    white-space: nowrap;
`

const Description = styled.div``

const Stats = styled.div`
    columns: 2;
    column-gap: 30px;
    margin: 0 0 -30px;
    font-size: 16px;

    @media ${devices.small} {
        margin: 20px 0;
    }
`

const Stat = styled.div`
    display: inline-block;
    width: 100%;
    margin: 0 0 30px;
`

const StatTitle = styled.div`
    font-weight: bold;
`

const StatDetail = styled.div``
