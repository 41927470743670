import React from 'react'

import styled from 'styled-components'

import { SimpleCarousel } from '../'
import colors from '../../css/colors.scss'
import { PrimaryButton, UpperHeader, IntroText, devices } from '../Common'


export const GardenBlock = ({ block }) => {
    const { title, url, images, description, blocks, slug } = block
    return (
        <GardenBlockWrap id={slug}>
            <BlockTitleWrap>
                <BlockTitle>{title}</BlockTitle>
                {url && <BlockTitleButton as={'a'} href={url}>Explore this Area</BlockTitleButton>}
            </BlockTitleWrap>

            <BlockIntro dangerouslySetInnerHTML={{ __html: description }} />
            <SimpleCarousel slides={images} />

            <BlockText>
                {blocks.map((b, i) =>
                    <Row key={i}>
                    {b.format !== 'TEXT-WRAP' ?
                        <>
                            <Column1>
                                {b.format !== 'TWO-COLUMN-LEFT-IMAGE' && b.left_title && <Subhead>{b.left_title}</Subhead>}
                                {b.format !== 'TWO-COLUMN-LEFT-IMAGE' && b.left_content && <div dangerouslySetInnerHTML={{ __html: b.left_content }} />}
                                {b.format === 'TWO-COLUMN-LEFT-IMAGE' && <SimpleCarousel slides={b.images} />}
                            </Column1>
                            <Column2>
                                {b.format !== 'TWO-COLUMN-RIGHT-IMAGE' && b.right_title && <Subhead>{b.right_title}</Subhead>}
                                {b.format !== 'TWO-COLUMN-RIGHT-IMAGE' && b.right_content && <div dangerouslySetInnerHTML={{ __html: b.right_content }} />}
                                {b.format === 'TWO-COLUMN-RIGHT-IMAGE' && <SimpleCarousel slides={b.images} />}
                            </Column2>
                        </>
                        :
                        <div>
                            <Subhead>{b.left_title}</Subhead>
                            <ColumnWrap dangerouslySetInnerHTML={{ __html: b.left_content }} />
                        </div>
                    }
                    </Row>
                )}
            </BlockText>
        </GardenBlockWrap>
    )
}

const ColumnWrap = styled.div`
    -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2;
    -webkit-column-gap: 60px;
    -moz-column-gap: 60px;
    column-gap: 60px;

    @media ${devices.small} {
        -webkit-columns: 1;
        -moz-columns: 1;
        columns: 1;
        -webkit-column-gap: 0;
        -moz-column-gap: 0;
        column-gap: 0;
    }
`

const Row = styled.div`
    display: flex;
    margin-bottom: 40px;

    @media ${devices.small} {
        flex-direction: column;
    }
`

const GardenBlockWrap = styled.div`
    margin: 60px 0 0;
    padding: 60px 0 0;
    border-top: 1px solid ${colors.darkGreen};
`

const BlockTitleWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 30px;

    @media ${devices.small} {
        flex-direction: column;
    }
`

const BlockTitle = styled('h3')`
    flex: 1;
`

const BlockTitleButton = styled(PrimaryButton)`
    display: table;
`

const BlockIntro = styled(IntroText)`
    margin: 0 0 50px;
    padding: 0;
`

const BlockText = styled.div`
    display: flex;
    flex-direction: column;
    p {
        margin: 0 0 15px;
        &:last-child {
            margin: 0;
        }
        & + *:not(p) {
            margin-top: 15px;
        }
    }
`

const Column = styled.div`
    width: 50%;
    > *:first-child {
        margin-top: 0 !important;
    }

    @media ${devices.small} {
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
    }
`

const Column1 = styled(Column)`
    padding-right: 30px;
`

const Column2 = styled(Column)`
    padding-left: 30px;
`

const Subhead = styled(UpperHeader)`
    margin: 0 0 5px !important;
`
