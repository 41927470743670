import React, { useState, useEffect } from 'react'

import styled from 'styled-components'

import {
    PlantItem,
} from '../'
import { useWindowSize } from '../../_helpers'
import colors from '../../css/colors.scss'
import { devices } from '../Common'


let firsthit = false

export const PlantCategory = ({ color, category_name, plants, months }) => {
    const [open, setOpen] = useState(false)
    const { width } = useWindowSize()
    const mobile = width <= 740

    useEffect(() => {
        if (firsthit) return
        const hash = window.location.hash.substring(1)

        for (const plant of plants) {
            if (plant.slug === hash) {
                firsthit = true
                setOpen(true)
            }
        }
    }, [])

    return (
        <Category open={open}>
            <PeriodLines></PeriodLines>
            <CategoryLabel onClick={() => mobile && setOpen(!open)}>{category_name || 'Category Name'}</CategoryLabel>
            <PlantList>
                {plants.map((p, i) => <PlantItem key={i} color={color} plant={p} months={months} />)}
            </PlantList>
        </Category>
    )
}

const PeriodLines = styled.div`
    width: calc(100% - 200px);
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    pointer-events: none;
    &:before,
    &:after {
        content: "";
        display: block;
        width: calc(33.3333%);
        height: 100%;
        position: absolute;
        top: 0;
    }
    &:before {
        border-right: 1px solid ${colors.darkGreen};
        left: 0;
    }
    &:after {
        border-left: 1px solid ${colors.darkGreen};
        right: 0;
    }

    @media ${devices.small} {
        width: 45%;
        &:before {
            border-left: 1px solid ${colors.darkGreen};
        }

        &:after {
            border-right: 1px solid ${colors.darkGreen};
        }
    }
`

const CategoryLabel = styled.div`
    margin: 0;
    padding: 10px 0 10px;
    font-size: 18px;
    font-weight: 700;

    @media ${devices.small} {
        width: 55%;
        position: relative;
        padding: 10px;
        font-size: 14px;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &:before {
            content: "";
            border: solid ${colors.darkGreen};
            border-width: 0 1px 1px 0;
            display: block;
            padding: 4px;
            transform: rotate(45deg);
            position: absolute;
            top: calc(50% - 8px);
            left: -8px;
        }
    }
`

const PlantList = styled.div`
    @media ${devices.small} {
        display: none;
    }
`

const Category = styled.div`
    position: relative;
    &:last-child {
        padding: 0 0 20px;
    }

    @media ${devices.small} {
        ${PlantList} {
            display: ${({ open }) => open && 'block'};
        }

        ${CategoryLabel} {
            &:before {
                transform: ${({ open }) => open && 'rotate(225deg)'};
            }
        }
    }
`
