import React from 'react'

import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { useWindowSize } from '../../_helpers'
import colors from '../../css/colors.scss'
import {
    SecondaryButton,
    Image,
    PrimaryButton,
    UpperHeader,
    devices,
} from '../Common'


const DesktopFeaturedPlant = ({ title, plantInfo }) => {
    const { scientific_name, description, url, images } = plantInfo
    return (
        <FeaturedPlantContainer>
            <h2>{title}</h2>
            <ImageGrid>
                {images.length >= 1 && <LargeImage src={images[0].image} alt={images[0].alt_text} />}
                {images.length >= 2 && <SmallImage src={images[1].image} alt={images[1].alt_text} />}
                {images.length === 3 && <SmallImage src={images[2].image} alt={images[2].alt_text} />}
                <InfoBox>
                    <h3>{scientific_name}</h3>
                    <Description dangerouslySetInnerHTML={{ __html: description }} />
                    <SecondaryButton as={Link} to={url}>Learn More</SecondaryButton>
                </InfoBox>
            </ImageGrid>
        </FeaturedPlantContainer>
    )
}

const MobileFeaturedPlant = ({ title, plantInfo }) => {
    const { scientific_name, description, url, images } = plantInfo
    return (
        <MobilePlantContainer bg={images[0].image}>
            <MobileText>
                <h2 style={{ marginBottom: '20px' }}>{title}</h2>
                <UpperHeader>{scientific_name}</UpperHeader>
                <Description dangerouslySetInnerHTML={{ __html: description }} />
                <PrimaryButton as={Link} to={url}>Learn More</PrimaryButton>
            </MobileText>
        </MobilePlantContainer>
    )
}

export const FeaturedPlant = ({ title, plantInfo }) => {
    const { width } = useWindowSize()
    return width <= 740 ? <MobileFeaturedPlant title={title} plantInfo={plantInfo} /> : <DesktopFeaturedPlant title={title} plantInfo={plantInfo} />
}

const ImageGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    position: relative;
    margin-top: 20px;
`

const FeaturedPlantContainer = styled.div`
    padding: 50px 0 0 0;
`

const InfoBox = styled.div`
    position: relative;
    background-color: ${colors.green};
    color: ${colors.darkGreen};
    padding: 30px 35px;
    grid-column: 1 / span 5;
    z-index: 2;
    margin-top: -50%;

    @media ${devices.medium_portrait} {
        padding: 15px;
        grid-column: 1 / span 7;
    }
`

const Description = styled.div`
    font-size: 18px;
    margin-bottom: 20px;
`

const LargeImage = styled(Image)`
    z-index: 0;
    grid-column: 2 / span 10;
    grid-row: 1;
    width: 100%;
`

const SmallImage = styled(Image)`
    width: calc(100% - 20px);
    z-index: 1;
    grid-column: 8 / -1;
    grid-row: 1;
    padding-top: 70%;
    margin-left: 20px;
`

const MobilePlantContainer = styled.div`
    background: url(${({ bg }) => bg}) center/cover no-repeat;
    height: 100vh;
`

const MobileText = styled.div`
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    height: 100%;
    background-image: linear-gradient(-180deg, rgba(0,0,0,0) 0%, ${colors.darkGreen} 100%);

    ${PrimaryButton} {
        align-self: center;
    }
`
