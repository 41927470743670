import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { SecondaryButton, VideoModal, VideoThumbnail, VideoPlayButton, PageWithMenu } from '../'
import { loadLearnVideoList } from '../../_actions'
import colors from '../../css/colors.scss'
import {
    Header,
    devices,
} from '../Common'


const Video = ({ video, openModal }) => (
    <Item bordered={true}>
        <Info>
            <Header fontsize={47}>{video.title}</Header>
            <Description dangerouslySetInnerHTML={{ __html: video.description }} />
        </Info>
        <Thumbnail onClick={() => openModal(video.video_url)} image={video.video_thumbnail || video.youtube_thumbnail || video.vimeo_thumbnail} height={400}>
            <VideoPlayButton />
        </Thumbnail>
    </Item>
)

const VideoList = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const [display, setDisplay] = useState(3)
    const [showModal, setShowModal] = useState(false)
    const [videoURL, setVideoURL] = useState('')
    const openModal = video_url => {
        setShowModal(true)
        setVideoURL(video_url)
    }
    const closeModal = () => {
        setShowModal(false)
        setVideoURL('')
    }
    const loadMore = () => setDisplay(display + 3)
    const { videos } = useSelector(state => state.content, shallowEqual)

    useEffect(() => {
        if (!videos.length) dispatch(loadLearnVideoList())
    }, [location.pathname])

    return (
        <Videos>
            {videos.slice(0, display).map(e => <Video key={e.title} video={e} openModal={openModal} />)}
            {videos.length > display ? <ButtonContainer onClick={loadMore}><SecondaryButton>Load More</SecondaryButton></ButtonContainer> : ''}
            <VideoModal
                url={videoURL}
                showModal={showModal}
                closeModal={closeModal}
            />
        </Videos>
    )
}

const Videos = styled.div``

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 50px 0;

    &:last-child {
        margin-bottom: 0;
    }
`

const Item = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 50px;
    padding: 0 0 50px;
    border-bottom: 1px solid ${colors.darkGreen};

    &:last-child {
        margin: 0;
        padding: 0;
        border-bottom: 0;
    }
`

const Info = styled.div`
    width: 80%;
    padding: 0 30px 0 0;
    margin-bottom: 20px;

    @media ${devices.small} {
        width: 100%;
        padding: 0;
    }
`

const Description = styled.div`
    margin-top: 15px;
`

const Thumbnail = styled(VideoThumbnail)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: unset;
    padding-bottom: 53%;
    position: relative;

    ${VideoPlayButton} {
        position: absolute;
        top: calc(50% - 26px);
    }
`

const VideoListPage = () => <PageWithMenu ChildComponent={VideoList} />
export { VideoListPage as VideoList }
