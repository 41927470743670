import React, { forwardRef } from 'react'

import styled from 'styled-components'

import {
    FormError,
    InputWrap,
    FormLabel,
    Select,
    Option,
    SelectWrap,
} from '../../Common'

const SelectInput = ({ label, options, name, errors, required }, ref) => (
    <SelectInputWrap>
        <FormLabel>{label}{required ? '*' : ''}</FormLabel>
        {errors[name] && <FormError>{errors[name].message}</FormError>}
        <SelectWrap>
            <SquareSelect required ref={ref} name={name}>
                <Option value=''>---------</Option>
                {options.map(e => <Option key={e[0]} value={e[0]}>{e[1]}</Option>)}
            </SquareSelect>
        </SelectWrap>
    </SelectInputWrap>
)

const SelectInputWrap = styled(InputWrap)`
    width: 90%;
`

const SquareSelect = styled(Select)`
    border-radius: 0;
    padding: 15px 40px 14px 20px;
`

export const HookSelect = forwardRef(SelectInput)
