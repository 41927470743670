import React, { Fragment } from 'react'

import styled from 'styled-components'

import {
    Header,
    ArticleTextWrap,
} from '../../Common'


export const List = ({ pageBlock: { title, content, show_title } }) => (
    <Fragment>
        {title && show_title ? (
            <Header fontSize={60} unsetmaxwidth>{title}</Header>
        ) : ''}
        <UnPaddedList dangerouslySetInnerHTML={{__html: content}} />
    </Fragment>
)

const UnPaddedList = styled(ArticleTextWrap)`
    ul {
        padding: 0;
        padding-left: 20px;
    }

    padding-right: 0;
`
