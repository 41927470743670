import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { NewsImageItem, NewsItem, SecondaryButton } from '../'
import { loadNewsList } from '../../_actions'


export const NewsList = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const [imageDisplay, setImageDisplay] = useState(2)
    const [display, setDisplay] = useState(3)
    const loadMoreImages = () => setImageDisplay(imageDisplay + 2)
    const loadMore = () => setDisplay(display + 3)
    const { news } = useSelector(state => state.content, shallowEqual)
    const newsImages = news.filter(e => e.image)
    const newsNoImages = news.filter(e => !e.image)

    useEffect(() => {
        if (!news.length) dispatch(loadNewsList())
    }, [location.pathname])

    return (
        <News>
            {newsImages.slice(0, imageDisplay).map(e => <NewsImageItem key={e.title} news={e} />)}
            {newsImages.length > imageDisplay ? <ButtonContainer onClick={loadMoreImages}><SecondaryButton>Load More</SecondaryButton></ButtonContainer> : ''}

            {newsNoImages.slice(0, display).map((e, i) => <NewsItem key={e.title} news={e} last={(i === newsNoImages.slice(0, display).length - 1) && newsNoImages.length > display}/>)}
            {newsNoImages.length > display ? <ButtonContainer bordertop={true} onClick={loadMore}><SecondaryButton>Load More</SecondaryButton></ButtonContainer> : ''}
        </News>
    )
}

const News = styled.div``

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 50px 0;

    &:last-child {
        margin-bottom: 0;
    }
`
