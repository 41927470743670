/* globals dafModule */
import React from 'react'

import { Link, useLocation } from 'react-router-dom'
import { Link as LinkScroll } from 'react-scroll'
import styled from 'styled-components'

import colors from '../../../css/colors.scss'
import {
    Header,
    ImageCard,
    ArticleTextWrap,
    Block,
    Grid,
    devices,
    SecondaryButton,
    PrimaryButton,
} from '../../Common'


const ArticleContent = ({ pageBlock, siblings }) => {
    return (
        <>
            {pageBlock.title && pageBlock.show_title ? (
                <Header margintop={10} fontSize={60} marginbottom={20} unsetmaxwidth>{pageBlock.title}</Header>
            ) : ''}

            {pageBlock.intro ? (
                <Intro dangerouslySetInnerHTML={{ __html: pageBlock.intro }} />
            ) : ''}

            {pageBlock.image ? (
                <ImageCard
                    background={pageBlock.image}
                    alt={pageBlock.alt_text}
                    height={540}
                    marginbottom={20}
                    responsive={location.pathname === '/visit/'}
                />
            ) : ''}

            <ArticleTextWrap
                shorten={!siblings}
                nopadding={pageBlock.type === 'SIDEBAR'}
                dangerouslySetInnerHTML={{ __html: pageBlock.content }}
            />

            {pageBlock.link_text ? (
                <PrimaryButton
                    as={pageBlock.linked_page ? Link : 'a'}
                    to={pageBlock.linked_page ? pageBlock.linked_page : ''}
                    href={pageBlock.external_url ? pageBlock.external_url.replace('mailto://', 'mailto:') : pageBlock.linked_file ? pageBlock.linked_file : ''}
                    target={pageBlock.external_url || pageBlock.linked_file ? '_blank' : undefined}
                    rel={pageBlock.external_url ? 'noopener noreferrer' : ''}
                    style={{ width: 'auto' }}
                    margintop={30}
                >
                    {pageBlock.link_text}
                </PrimaryButton>
            ) : ''}

            {pageBlock.donate_button_text ? (
                <>
                    <PrimaryButton
                        as="button"
                        style={{ width: 'auto' }}
                        margintop={30}
                        onClick={e => dafModule.openModal(e)}
                    >
                        {pageBlock.donate_button_text}
                    </PrimaryButton>
                </>
            ) : ''}
        </>
    )
}

const BlockComp = ({ pageBlock, id, textPadding, columns }) => {
    const location = useLocation()
    const showTitle = pageBlock[`title${id}`] && pageBlock[`title${id}`] !== 'None' && ((pageBlock.format === '3-COLUMN' && pageBlock.title2) || pageBlock.format !== '3-COLUMN') || (pageBlock.format === 'LEFT-IMAGE' && id === '2')
    const title = pageBlock.format === 'LEFT-IMAGE' ? id === '2' ? pageBlock.title : '' : pageBlock[`title${id}`]
    const calcRatio = (format) => {
        if (format === '3-COLUMN') {
            return 'height: 0;padding-bottom: 100%;'
        } else if (format === 'LEFT-IMAGE' || format === 'RIGHT-IMAGE') {
            return 'height: 0;padding-bottom: 60%;'
        } else {
            return 'height: 0;padding-bottom: 66.6666%;'
        }
    }

    return (
        <FlexColumn
            key={`#${id}`}
            paddingleft={30}
            nomargin={location.pathname === '/about/team/'}
            center={pageBlock.format === '3-COLUMN' && pageBlock[`link_text${id}`]}
        >
            {pageBlock[`image${id}`] &&
                <ImageCard
                    background={pageBlock[`image${id}`]}
                    margintop={0}
                    ratio={calcRatio(pageBlock.format)}
                    role='img'
                    aria-label={pageBlock[`alt_text${id}`]}
                />
            }
            <TextContent
                paddingRight={textPadding}
                center={pageBlock.format === '3-COLUMN' && pageBlock[`link_text${id}`]}
                isLightscape={location.pathname === '/lightscape/'}
            >
                {pageBlock.show_title && showTitle &&
                    <Header>
                        {title}
                    </Header>
                }
                {pageBlock[`content${id}`] &&
                    <Block
                        paddingbottom={location.pathname !== '/about/team/' ? 20 : 0}
                        paddingtop={pageBlock.format === '3-COLUMN' && location.pathname !== '/about/team/' ? 30 : 0}
                        dangerouslySetInnerHTML={{ __html: pageBlock[`content${id}`] }}
                        anchorcolor={colors.darkGreen}
                        center={pageBlock.format === '3-COLUMN' && pageBlock.title2}
                    />
                }
                {pageBlock[`link_text${id}`] ? pageBlock.format === '3-COLUMN' ? (
                    <PButton
                        as={pageBlock[`linked_page${id}`] ? Link : 'a'}
                        to={pageBlock[`linked_page${id}`] ? pageBlock[`linked_page${id}`] : ''}
                        href={pageBlock[`external_url${id}`]}
                    >
                        {pageBlock[`link_text${id}`]}
                    </PButton>
                ) : (
                    location.pathname === '/lightscape/' && pageBlock[`linked_page${id}`] ? (
                        <SButton
                            as={LinkScroll}
                            to={'lightscapefaq'}
                            smooth={true} duration={500} offset={-50}
                            href={pageBlock[`external_url${id}`]}
                        >
                            {pageBlock[`link_text${id}`]}
                        </SButton>
                    ) : (
                        <SButton
                            as={pageBlock[`linked_page${id}`] ? Link : 'a'}
                            to={pageBlock[`linked_page${id}`] ? pageBlock[`linked_page${id}`] : ''}
                            href={pageBlock[`external_url${id}`]}
                        >
                            {pageBlock[`link_text${id}`]}
                        </SButton>
                    )
                ) : ''}
            </TextContent>
        </FlexColumn>
    )
}

export const SimpleBlock = props => props.columns === 1 ? <ArticleContent {...props} /> : <BlockComp {...props} />

export const FlexColumn = styled(Grid.Column)`
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: inherit;
    ${({ center }) => center ? 'align-items: center;' : ''}
    padding-left: ${props => props.paddingleft}px;
    @media ${devices.small} {
        padding-left: 0;
        margin-bottom: ${props => props.nomargin ? '0' : '30px'};
    }
`

const TextContent = styled.article`
    padding-right: ${({ paddingRight = '2rem' }) => paddingRight};
    height: 100%;
    ${({ center }) => center ? 'align-items: center;' : ''}
    flex: 1;
    display: flex;
    flex-direction: column;
    ${props => (props.isLightscape ? `
    `:`
        justify-content: space-between;
    `)};
    .before-top {
        margin-bottom: auto;
    }
`

const PButton = styled(PrimaryButton)`
    width: 220px;
    margin-top: 15px;

    @media ${devices.medium} {
        width: 200px;
    }
`

const SButton = styled(SecondaryButton)`
    width: 220px;
    margin-top: 15px;

    @media ${devices.medium} {
        width: 200px;
    }
`

const Intro = styled.h3`
    text-align: center;
    line-height: 1.2;
    & + * {
        margin: 40px 0 0;
        padding: 50px 0 0;
        border-top: 1px solid ${colors.darkGreen};
        font-size: 20px;
        text-align: center;
        p:not(:last-of-type) {
            margin: 0 0 40px;
        }
    }
`
