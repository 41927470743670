import { createConstants } from '../_helpers/utils'

export const supportTypes = createConstants(
    'DONATION_LIST_REQUEST',
    'DONATION_LIST_SUCCESS',
    'MEMBERSHIP_LIST_REQUEST',
    'MEMBERSHIP_LIST_SUCCESS',
    'SPONSORSHIP_LIST_REQUEST',
    'SPONSORSHIP_LIST_SUCCESS',
    'VOLUNTEER_DATA_REQUEST',
    'VOLUNTEER_DATA_SUCCESS',
    'VOLUNTEER_LIST_REQUEST',
    'VOLUNTEER_LIST_SUCCESS',
    'SPONSORSHIP_EMAIL_POST_REQUEST',
    'SPONSORSHIP_EMAIL_POST_SUCCESS',
    'SPONSORSHIP_EMAIL_POST_FAILURE',
    'SPONSORSHIP_EMAIL_POST_RESET_MESSAGES',
    'JOB_LIST_REQUEST',
    'JOB_LIST_SUCCESS',
    'STAFF_LIST_REQUEST',
    'STAFF_LIST_SUCCESS',
    'DEPARTMENT_LIST_REQUEST',
    'DEPARTMENT_LIST_SUCCESS',
)
