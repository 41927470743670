import React from 'react'

import {
    InputWrap,
    FormLabel,
    InputContainer,
    Input,
    InlineFormError
} from '../../'


export const TextField = ({input, type, label, required, auto, meta: {touched, error}}) => (
    <InputWrap>
        <FormLabel>{label}{required ? '*' : ''} {touched && error && <InlineFormError>{error}</InlineFormError>}</FormLabel>
        <InputContainer>
            <Input {...input} type={type} autoComplete={auto} required={required} />
        </InputContainer>
    </InputWrap>
)
