import React from 'react'

import {
    SelectWrap,
    InputWrap,
    FormLabel,
    InputContainer,
    Select,
    Option,
    InlineFormError,
} from '../../'


const SelectField = ({input, type, label, required, options, meta: {touched, error}}) => (
    <InputWrap>
        <FormLabel>{label}{required ? '*' : ''} {touched && error && <InlineFormError>{error}</InlineFormError>}</FormLabel>
        <InputContainer>
            <SelectWrap>
                <Select {...input} type={type} required={required}>
                    <Option value=''>---------</Option>
                    {options.map(e => <Option key={e[0]} value={e[0]}>{e[1]}</Option>)}
                </Select>
            </SelectWrap>
        </InputContainer>
    </InputWrap>
)

export { SelectField }
