import React, { /*useState*/ } from 'react'

import styled from 'styled-components'

import { scrollToAnchor } from '../../_helpers'
// import colors from '../../css/colors.scss'
import {
    // Container,
    SecondaryButton,
    // SelectButton,
    UpperHeader,
    devices,
} from '../Common'

export const GardenCollectionHeader = ({ left, right }) => {
    //const [show1, setShow1] = useState(false)
    //const [show2, setShow2] = useState(true)
    let show1 = false
    let show2 = true

    /*
    const enterArea = (side) => {
        if (side === 'left') {
            setShow1(false)
            setShow2(true)
        } else if (side === 'right') {
            setShow1(true)
            setShow2(false)
        }
    }

    const leaveArea = () => {
        setShow1(false)
        setShow2(false)
    }
    */

    const scroll = (e) => {
        e.preventDefault()
        scrollToAnchor(e.target.getAttribute('href'))
    }

    return (
        <HeaderWrap>
            <Column
                // onMouseEnter={() => enterArea('left')}
                // onMouseLeave={leaveArea}
                color={left && left.color}
            >
                {left &&
                    <>
                        <SubHead>Experience</SubHead>
                        <Title>{left.title}</Title>
                        <Desc dangerouslySetInnerHTML={{ __html: left.description }} />
                        <Button as={'a'} href={'#map'} onClick={scroll}>Explore Now</Button>
                        <ImageLeft show={show1} bg={left.hero_image} color={left && left.color}/>
                    </>
                }
            </Column>
            <Column
                // onMouseEnter={() => enterArea('right')}
                // onMouseLeave={leaveArea}
                color={left && left.color}
            >
                {right &&
                    <>
                        {/* <SubHead>Experience</SubHead>
                        <Title>{right.title}</Title>
                        <Desc dangerouslySetInnerHTML={{ __html: right.description }} />
                        <Button as={'a'} href={'#map'} onClick={scroll}>Explore Now</Button>
                        <SelectButton background={colors.yellow} margintop={15} /> */}
                        {/* <ImageRight show={show2} bg={right.hero_image} color={right && right.color}/> */}
                        <ImageRight show={show2} bg={right.hero_image} color={left && left.color}/>
                    </>
                }
            </Column>
        </HeaderWrap>
    )
}

const HeaderWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 145px - 40px);
    @media ${devices.small} {
        flex-direction: column;
        height: auto;
    }
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    position: relative;
    padding: 15px 15px;
    text-align: center;
    background-color: ${props => `#${props.color}`};

    @media ${devices.small} {
        width: 100%;
        padding: 30px 20px;
        &:nth-child(2) {
            padding-bottom: 100%;
        }
    }
`

const SubHead = styled(UpperHeader)`
    margin: 0 0 20px;
`

const Title = styled('h2')``

const Desc = styled.div`
    max-width: 500px;
    margin: 25px auto 40px;
    font-size: 20px;
`

const Button = styled(SecondaryButton)``

const Image = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: no-repeat 50% 50%/cover;
    background-image: ${props => `url(${props.bg})`};
    background-color: ${props => `url(${props.color})`};
    opacity: 0;
    transition: opacity .3s, transform .3s;
    pointer-events: none;
    ${props => props.show ? `
        opacity: 1;
        transform: scale(1) translate3d(0,0,0);
        pointer-events: auto;
    ` : ``};
`

const ImageLeft = styled(Image)`
    transform: scale(1) translate3d(-30px,0,0);
    ${props => props.show ? `
        transform: scale(1) translate3d(0,0,0);
    ` : ``};
`

const ImageRight = styled(Image)`
    opacity: 1 !important;
    transform: scale(1) translate3d(30px,0,0);
    ${props => props.show ? `
        transform: scale(1) translate3d(0,0,0);
    ` : ``};
`
