import { createConstants } from '../_helpers/utils'

export const contentTypes = createConstants(
    'PAGE_DATA_REQUEST',
    'PAGE_DATA_SUCCESS',
    'PAGE_DATA_FAILURE',
    'MENU_DATA_REQUEST',
    'MENU_DATA_SUCCESS',
    'SUBSCRIBE_REQUEST',
    'SUBSCRIBE_SUCCESS',
    'SUBSCRIBE_FAILURE',
    'TOGGLE_MENU',
    'WEATHER_REQUEST',
    'WEATHER_SUCCESS',
    'NEWS_LIST_REQUEST',
    'NEWS_LIST_SUCCESS',
    'CLEAR_PAGE',
    'LEARN_VIDEO_LIST_REQUEST',
    'LEARN_VIDEO_LIST_SUCCESS',
    'SEARCH_REQUEST',
    'SEARCH_SUCCESS',
    'UPDATE_QUERY',
)
