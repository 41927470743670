import { contentTypes } from '../_constants/'
import { createAction, createAsyncAction } from '../_helpers/utils'
import {
    Newsletter,
    Menu,
    Pages,
    Weather,
    News,
    Video,
    Search,
} from '../_services'


export const loadPage = createAsyncAction(
    contentTypes.PAGE_DATA_REQUEST,
    contentTypes.PAGE_DATA_SUCCESS,
    Pages.get,
    contentTypes.PAGE_DATA_FAILURE,
)

export const clearPage = createAction(contentTypes.CLEAR_PAGE)

export const loadMenu = createAsyncAction(
    contentTypes.MENU_DATA_REQUEST,
    contentTypes.MENU_DATA_SUCCESS,
    Menu.list
)

export const subscribe = createAsyncAction(
    contentTypes.SUBSCRIBE_REQUEST,
    contentTypes.SUBSCRIBE_SUCCESS,
    Newsletter.post,
    contentTypes.SUBSCRIBE_FAILURE
)

export const toggleMenu = createAction(contentTypes.TOGGLE_MENU)

export const loadWeather = createAsyncAction(
    contentTypes.WEATHER_REQUEST,
    contentTypes.WEATHER_SUCCESS,
    Weather.get
)

export const loadNewsList = createAsyncAction(
    contentTypes.NEWS_LIST_REQUEST,
    contentTypes.NEWS_LIST_SUCCESS,
    News.list
)

export const loadLearnVideoList = createAsyncAction(
    contentTypes.LEARN_VIDEO_LIST_REQUEST,
    contentTypes.LEARN_VIDEO_LIST_SUCCESS,
    Video.list
)

export const search = (query) => {
    return (dispatch) => {
        Search.get(query).then((search) => dispatch(success(search.data)))
    }
    function success(search) {
        return { type: contentTypes.SEARCH_SUCCESS, search, query }
    }
}

export const updateQuery = createAction(contentTypes.UPDATE_QUERY)