import axios from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

export * from './content.services'
export * from './error.services'
export * from './event.services'
export * from './garden.services'
export * from './support.services'
export * from './forms.services'
export * from './lightscape.services'
