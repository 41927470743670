import { lightscapeTypes } from '../_constants/'
import { createAsyncAction } from '../_helpers/utils'
import {
    POI,
} from '../_services'


export const loadPOIList = createAsyncAction(
    lightscapeTypes.POI_LIST_REQUEST,
    lightscapeTypes.POI_LIST_SUCCESS,
    POI.list,
)
