import React, { useEffect } from 'react'

import { Map, View } from 'ol'
// import Feature from 'ol/Feature'
// import Point from 'ol/geom/Point'
import { defaults } from 'ol/interaction'
import TileLayer from 'ol/layer/Tile'
import Overlay from 'ol/Overlay'
import Projection from 'ol/proj/Projection'
import Zoomify from 'ol/source/Zoomify'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import styled from 'styled-components'

import { loadPOIList } from '../../_actions'
import colors from '../../css/colors.scss'
import { devices } from '../Common'


export const LightscapeMap = ({ title }) => {
    const dispatch = useDispatch()
    const { poiList } = useSelector(state => state.lightscape, shallowEqual)

    const CreateMap = () => {
        const map_width = 10000
        const map_height = 10000
        const zoomify_url = 'https://hbg.org/static/images/lightscape-zoomify-v1/'

        const retina_pixel_ratio = 2

        const zoomify_source = new Zoomify({
            url: zoomify_url,
            size: [map_width, map_height],
            crossOrigin: 'anonymous',
            cacheSize: 500,
            zDirection: -1,
            tilePixelRatio: retina_pixel_ratio,
            tileSize: 512 / retina_pixel_ratio,
            transition: 100,
        })

        const extent = zoomify_source.getTileGrid().getExtent()

        const projection = new Projection({
            units: 'pixels',
            extent: extent,
        })

        const zoomify_layer = new TileLayer({
            source: zoomify_source,
            preload: Infinity,
        })

        const map = new Map({
            target: 'map',
            layers: [zoomify_layer],
            view: new View({
                extent: extent,
                projection: projection,
                center: [map_width * 0.5, map_height * -0.6],
                zoom: 3,
                minZoom: 2,
                maxZoom: 4,
                renderer: 'webgl',
                constrainResolution: true,
            }),
            interactions: defaults({
                dragPan: true,
                mouseWheelZoom: false,
                doubleClickZoom: false,
                pinchZoom: true,
                altShiftDragRotate: false,
                pinchRotate: false,
            })
        })

        const addMarker = ({ left, top, title, type, label_position }) => {
            const x = left
            const y = top * -1
            const marker = document.createElement('div')
            marker.setAttribute('class', `marker ${type ? type : ''}`)
            marker.innerHTML = `<div>${title ? title : ''}</div>`

            const marker_overlay = new Overlay({
                position: [x, y],
                positioning: label_position ? label_position : 'center-left',
                offset: [0, 0],
                element: marker,
                stopEvent: false,
            })

            marker_overlay.element.style.zIndex = Math.round(top*-10000)

            map.addOverlay(marker_overlay)
        }

        poiList.pois.map(e => addMarker({
            left: e.left,
            top: e.top,
            label_position: e.label_position,
            title: e.title,
            type: e.type
        }))
    }

    useEffect(() => {
        if (!poiList.pois) dispatch(loadPOIList())
    }, [])

    useEffect(() => {
        if (poiList.pois) {
            CreateMap()
        }
    }, [poiList])

    return (
        <>
            <Header as={location.pathname === '/lightscape/' ? 'h3' : ''} center={location.pathname === '/lightscape/'}>{title}</Header>
            <MapWrap>
                <div id="map"></div>
                <MapCompass/>
            </MapWrap>
        </>
    )
}

const Header = styled.h2`
    margin: 0 0 30px;
    ${props => (props.center ? `
        text-align: center;
    ` : '')};
    @media ${devices.small} {
        font-size: 50px;
        text-align: center;
    }
`

const MapWrap = styled.div`
    width: 100%;
    height: 0;
    padding-bottom: 66.6666%;
    position: relative;
    margin: 0 0 30px;
    #map {
        width: 100%;
        height: 100%;
        position: absolute;
        background: #192A22;
        .ol-hidden {
            display: none;
        }
        .ol-overlaycontainer-stopevent {
            pointer-events: none;
            .ol-zoom {
                display: inline-flex;
                flex-direction: column;
                position: absolute;
                top: 15px;
                left: 15px;
                pointer-events: auto;
                button.ol-zoom-in,
                button.ol-zoom-out {
                    width: 30px;
                    height: 30px;
                    margin: 0;
                    padding: 0;
                    background: rgba(255,255,255,.8) url('/static/images/sprite.svg') no-repeat 0 -753px;
                    backdrop-filter: blur(4px);
                    border: 0;
                    border-radius: 0;
                    color: rgba(0,0,0,0);
                    cursor: pointer;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                }
                button.ol-zoom-out {
                    margin: 6px 0 0;
                    background-position: -30px -753px;
                }
            }
        }
        .marker {
            display: inline-flex;
            width: auto;
            div {
                display: table;
                width: auto;
                padding: 4px 12px;
                background: #ED746B;
                border-radius: 12px;
                font-family: 'NB International Pro';
                font-size: 12px;
                line-height: 1.4;
                text-align: center;
                color: ${colors.white};
            }
            &.food {
                div {
                    background: #F26522;
                }
                &:before {
                    content: "";
                    display: block;
                    width: 24px;
                    height: 24px;
                    margin-right: 4px;
                    border-radius: 100%;
                    background: #F26522 url('/static/images/marker-food.svg') no-repeat 50% 50%;
                    background-size: 21px 21px;
                }
            }
            &.yellow {
                div {
                    background: #FFC643;
                }
            }
            &.parking {
                div {
                    display: block;
                    width: 50px;
                    height: 50px;
                    padding: 0;
                    border-radius: 100%;
                    overflow: hidden;
                    background: #FFC643 url('/static/images/marker-parking.svg?v=1') no-repeat 50% 50%/46px 46px !important;
                    color: rgba(0,0,0,0);
                }
            }
            &.bathroom {
                div {
                    display: block;
                    width: 50px;
                    height: 50px;
                    padding: 0;
                    border-radius: 100%;
                    overflow: hidden;
                    background: #ED746B url('/static/images/marker-bathroom.svg') no-repeat 50% 50%;
                    color: rgba(0,0,0,0);
                }
            }
            &.firegarden {
                div {
                    display: block;
                    width: 50px;
                    height: 50px;
                    padding: 0;
                    border-radius: 100%;
                    overflow: hidden;
                    background: #FFC643 url('/static/images/marker-fire.svg') no-repeat 50% 50%;
                    color: rgba(0,0,0,0);
                }
            }
            &.medic {
                flex-direction: column;
                align-items: center;
                width: 100px;
                div {
                    background: rgba(0,0,0,0);
                }
                &:before {
                    content: "";
                    display: block;
                    width: 32px;
                    height: 32px;
                    background: url('/static/images/marker-med.svg') no-repeat 50% 50% ;
                }
            }
            &.water {
                div {
                    display: block;
                    width: 18px;
                    height: 18px;
                    padding: 0;
                    border-radius: 100%;
                    overflow: hidden;
                    background: #ED746B url('/static/images/marker-water.svg') no-repeat 50% 50%;
                    color: rgba(0,0,0,0);
                }
            }
        }
    }

    @media ${devices.small} {
        width: 100vw;
        margin-left: -15px;
        padding-bottom: calc(100vh - 60px);
    }
`

const MapCompass = styled.div`
    width: 68px;
    height: 68px;
    background: url('/static/images/sprite.svg') no-repeat -80px -887px;
    position: absolute;
    bottom: 15px;
    left: 15px;
    pointer-events: none;
`
