import React from 'react'

import { useForm } from 'react-hook-form'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import styled from 'styled-components'

import { PageWithMenu, HookTextInput, HookSelect } from '../'
import { postTourRequestForm } from '../../_actions'
import { email } from '../../_helpers'
import colors from '../../css/colors.scss'
import {
    PrimaryButton,
    ExtraInfo,
    InputWrap,
    FormLabel,
    Checkbox,
} from '../Common'


const TourForm = () => {
    const dispatch = useDispatch()
    const { message, error } = useSelector(state => state.customForm, shallowEqual)
    const { register, handleSubmit, errors, control } = useForm({ mode: 'onBlur' })
    const onSubmit = data => dispatch(postTourRequestForm(data))

    const registerOptions = { required: 'This field is required' }

    return (
        <>
            {message || error ? (
                <div>
                    {message || error}
                </div>
            ) : (
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <HookTextInput
                            errors={errors}
                            label='Organization Requesting Tour'
                            name='organization'
                            required
                            ref={register(registerOptions)}
                        />
                        <HookTextInput
                            errors={errors}
                            label='Contact Name'
                            name='contact_name'
                            required
                            ref={register(registerOptions)}
                        />
                        <HookTextInput
                            errors={errors}
                            label='Contact Email Address'
                            type='email'
                            name='contact_email'
                            required
                            ref={register({ ...registerOptions, validate: val => email(val) })}
                        />
                        <HookTextInput
                            errors={errors}
                            label='Contact Phone Number'
                            type='tel'
                            name='contact_phone'
                            control={control}
                            required
                        />
                        <HookTextInput
                            errors={errors}
                            label='Group Description'
                            type='textarea'
                            name='group_description'
                            extra='Please include a brief description of the group requesting a private tour.'
                            required
                            ref={register(registerOptions)}
                        />
                        <HookTextInput
                            errors={errors}
                            type='date'
                            label='Desired Tour Date'
                            name='requested_date'
                            extra='Please provide at least 30 days advanced notice.'
                            control={control}
                            required
                            ref={register(registerOptions)}
                        />
                        <InputWrap>
                            <FormLabel>Please list two additional dates that would work if the first preference is unavailable</FormLabel>
                            <FormGroup>
                                <HorizontalGroup>
                                    <HookTextInput
                                        type='date'
                                        errors={errors}
                                        label='Date 1'
                                        name='requested_date2'
                                        control={control}
                                        required
                                        itallicTitle
                                        ref={register(registerOptions)}
                                    />
                                </HorizontalGroup>
                                <HorizontalGroup>
                                    <HookTextInput
                                        type='date'
                                        errors={errors}
                                        label='Date 2'
                                        name='requested_date3'
                                        control={control}
                                        required
                                        itallicTitle
                                        ref={register(registerOptions)}
                                    />
                                </HorizontalGroup>
                            </FormGroup>
                        </InputWrap>
                        <HookSelect
                            errors={errors}
                            label='Desired Tour'
                            name='tour_type'
                            required
                            ref={register(registerOptions)}
                            options={[
                                ['Docent', '1-hour Docent-led $75 per private tour'],
                                ['Horticultural Staff', '1-hour Horticultural Staff-led $150 per private tour']
                            ]}
                        />
                        <InputWrap>
                            <FormLabel>Choose all available times for the tour</FormLabel>
                            <ExtraInfo>The last tour on Saturdays and Sundays is at 11:00 am (multiple can be selected).</ExtraInfo>
                            <FormGroup>
                                <HorizontalGroup>
                                    <Checkbox>
                                        <input type='checkbox' name='time-1' value='10:00 am' ref={register} />
                                        <label htmlFor='time-1'>10:00 am</label>
                                    </Checkbox>
                                    <Checkbox>
                                        <input type='checkbox' name='time-2' value='10:30 am' ref={register} />
                                        <label htmlFor='time-2'>10:30 am</label>
                                    </Checkbox>
                                    <Checkbox>
                                        <input type='checkbox' name='time-3' value='11:00 am' ref={register} />
                                        <label htmlFor='time-3'>11:00 am</label>
                                    </Checkbox>
                                </HorizontalGroup>
                                <HorizontalGroup>
                                    <Checkbox>
                                        <input type='checkbox' name='time-4' value='1:00 pm' ref={register} />
                                        <label htmlFor='time4'>1:00 pm (not available on weekends)</label>
                                    </Checkbox>
                                    <Checkbox>
                                        <input type='checkbox' name='time-5' value='2:00 pm' ref={register} />
                                        <label htmlFor='time-5'>2:00 pm (not available on weekends)</label>
                                    </Checkbox>
                                    <Checkbox>
                                        <input type='checkbox' name='time-6' value='3:00 pm' ref={register} />
                                        <label htmlFor='time-6'>3:00 pm (not available on weekends)</label>
                                    </Checkbox>
                                </HorizontalGroup>
                            </FormGroup>
                        </InputWrap>
                        <HookTextInput
                            errors={errors}
                            label='Expected Number of Attendees'
                            name='number_of_attendees'
                            extra='Maximum of 12 people per tour (not including the docent). The Gardens can offer up to two private tours simultaneously'
                            required
                            ref={register(registerOptions)}
                        />
                        <HookTextInput
                            errors={errors}
                            label='Additional Comments'
                            type='textarea'
                            name='additional_comments'
                            ref={register}
                        />
                        <PrimaryButton type='submit' onClick={handleSubmit(onSubmit)}>Submit</PrimaryButton>
                    </Form>
                )}
        </>
    )
}

const Form = styled.form`
    display: flex;
    flex-direction: column;
    input {
        &:not([type="checkbox"]) {
            color: ${({ color = colors.darkGreen }) => color};
            border: 1px solid ${colors.darkGreen};
            padding: 15px 20px 14px;
            margin: 20px 0;
            height: 43px;
            border-radius: 0;
            width: 90%;

            &::placeholder, &::-webkit-input-placeholder {
                color: ${({ color = colors.darkGreen }) => color};
                opacity: .5;
            }
        }
    }

    textarea {
        width: 90%;
        margin: 20px 0;
    }

    button[type="submit"] {
        width: max-content;
    }
`

const FormGroup = styled.div`
    display: flex;
    justify-content: space-between;
    padding-right: 10%;
`

const HorizontalGroup = styled.div`
    width: 50%;

    input:not([type="checkbox"]) {
        width: 95%;

        &:last-of-type {
            width: 100%;
        }
    }

    &:first-child {
        margin-right: 5%;
    }
`

const TourRequestForm = () => <PageWithMenu ChildComponent={TourForm} />
export default TourRequestForm
