import React, { Fragment } from 'react'

import styled from 'styled-components'

import colors from '../../../css/colors.scss'
import {
    Header,
    SecondaryButton,
    devices,
} from '../../Common'


export const Contact = ({ pageBlock: { title, content, content2, linked_file, link_text3 } }) => (
    <Fragment>
        <Header fontSize={60} marginbottom={20} unsetmaxwidth>{title}</Header>
        <ContactContainer>
            <Intro dangerouslySetInnerHTML={{__html: content}} />
            <Details>
                <ContactInfo dangerouslySetInnerHTML={{__html: content2}} />
                {linked_file &&
                <div>
                    <SecondaryButton as='a' target='_blank' href={linked_file}>
                        {link_text3 ? link_text3 : 'View File'}
                    </SecondaryButton>
                </div>
                }
            </Details>
        </ContactContainer>
    </Fragment>
)

const Intro = styled.div`
    margin-bottom: 40px;
    a {
        color: ${colors.darkGreen};
        text-decoration: underline;
    }
`

const ContactContainer = styled.div``

const Details = styled.div`
    display: inline-flex;
    justify-content: space-between;
    width: 100%;

    @media ${devices.small} {
        flex-direction: column;
    }
`

const ContactInfo = styled.div`
    a {
        color: ${colors.darkGreen};
        text-decoration: underline;
    }

    @media ${devices.small} {
        margin-bottom: 30px;
    }
`
