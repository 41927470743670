export const scrollTo = (Y, duration) => {
    const startY = window.pageYOffset
    const diff = Y - startY
    let start

    const step = timestamp => {
        if (!start) {
            start = timestamp
        }
        const time = timestamp - start
        const percent = Math.min(time / duration, 1)
        window.scrollTo(0, startY + diff * percent)
        if (time < duration) {
            window.requestAnimationFrame(step)
        }
    }
    window.requestAnimationFrame(step)
}

let loaded = 0

export const scrollToAnchor = (custom_hash) => {
    let offset
    if (window.matchMedia('(max-width: 740px)').matches) {
        offset = 60 - 1
    } else {
        offset = 80 - 1
    }

    const hashParts = window.location.hash.split('#')

    if (custom_hash) {
        const distanceY = window.pageYOffset || document.documentElement.scrollTop
        const element = document.getElementById(custom_hash.substring(1))
        if (element) scrollTo(element.getBoundingClientRect().top - offset + distanceY, 600)
        history.replaceState('','',custom_hash)
    } else {
        if (hashParts.length > 1 && loaded === 0) {
            let readystate = setInterval(() => {
                const hash = hashParts.slice(-1)[0]
                const element = document.getElementById(hash)
                if (element) {
                    clearInterval(readystate)
                    setTimeout(() => {
                        scrollTo(element.getBoundingClientRect().top - offset, 1)
                    }, 100)
                }
            }, 100)
            loaded = loaded+1
        }
    }
}

export const scrollTimer = (offset, count=10) => {
    count--
    if (count < 0) return

    setTimeout(() => {
        try {
            scrollToAnchor()
        } catch (e) {
            scrollTimer(offset, count)
        }
    }, 1000)
}
