import axios from 'axios'

export const Event = {
    get: slug => axios.get(`/api/events/${slug}/`),
    list: page => axios.get(`/api/events/${page ? `?page=${page}` : ''}`),
    search: ({query, page}) => axios.get(`/api/events/${query}${page ? `&page=${page}` : ''}`),
}

export const Exhibition = {
    get: slug => axios.get(`/api/exhibitions/${slug}/`),
    list: page => axios.get(`/api/exhibitions/${page ? `?page=${page}` : ''}`),
}

export const Program = {
    list: () => axios.get(`/api/programs/`),
}

export const Rental = {
    list: () => axios.get(`/api/rentals/`),
}
