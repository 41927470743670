import React, { Fragment, useState, useRef, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { toggleMenu } from '../../_actions'
import { useEventListener, useWindowSize } from '../../_helpers'
import colors from '../../css/colors.scss'
import { SecondaryButton, devices } from '../Common'


export const Header = () => {
    const dispatch = useDispatch()
    const { alert } = useSelector(state => state.content.page)
    const [small, setSmall] = useState(false)
    const [cartEmpty, setCartEmpty] = useState(true)
    const { width } = useWindowSize()
    const mobile = width <= 740

    const openMenuPanel = () => {
        dispatch(toggleMenu(true))
        document.body.style.overflow = 'hidden'
    }

    const closeMenu = location => e => {
        if (!location || e.metaKey) return
        dispatch(toggleMenu(false))
        document.body.style.overflow = 'visible'
    }

    const shrinkHeader = () => {
        const distanceY = window.pageYOffset || document.documentElement.scrollTop
        if (!mobile) setSmall(distanceY > 200)
    }

    useEventListener('scroll', shrinkHeader)

    const alertRef = useRef()

    const [alertHeight, setalertHeight] = useState(0)

    const getAlertHeight = () => {
        const alertheight = alertRef.current.getBoundingClientRect().height
        setalertHeight(alertheight)
    }

    const stringObj = (str) => {
        return str.split(/[;] */).reduce(function(result, pairStr) {
            var arr = pairStr.split('=')
            if (arr.length === 2) {result[arr[0]] = arr[1]}
            return result
        }, {})
    }

    useEffect(() => {
        if (stringObj(document.cookie)['cart_empty'] === 'false') {
            setCartEmpty(false)
        } else {
            setCartEmpty(true)
        }
    }, [])

    useEffect(() => {
        alertRef.current && getAlertHeight()
    }, [alert, width])

    return (
        <Fragment>
            {alert ? (
                <AlertBannerSpacer>
                    <AlertBannerSpacerInner dangerouslySetInnerHTML={{__html: alert}} />
                </AlertBannerSpacer>
            ) : (
                <AlertBannerSpacer/>
            )}
            <FixedMenu activeAlert={!!alert} small={small} alert_offset={alertHeight}>
                {!!alert && !mobile && <AlertBanner ref={alertRef} dangerouslySetInnerHTML={{__html: alert}} />}
                <Menu>
                    <HeaderContainer>
                        <LogoBig href='/' onClick={closeMenu('/')} small={small || mobile}></LogoBig>
                        <LogoSmall href='/' onClick={closeMenu('/')} small={small || mobile}></LogoSmall>
                        {mobile && <MenuButton role='button' onClick={openMenuPanel} small={small}>
                            <div><div/><div/><div/></div>
                        </MenuButton>}
                        <InnerMenu>
                            {!mobile && <InnerMenuTop small={small}>
                                <AccountLink href="https://secure.hbg.org/account/login?ReturnUrl=%2faccount%2fupcomingevents">My Account</AccountLink>
                                <CartLink href="https://secure.hbg.org/cart/details/" aria-label="View Cart" cartEmpty={cartEmpty}/>
                                {/*<LanguageToggle>
                                    <Switch active={true} href='#'>EN</Switch> <span></span> <Switch href='#'>ES</Switch>
                                </LanguageToggle>*/}
                            </InnerMenuTop>}
                            {!mobile && <InnerMenuBottom small={small}>
                                <HeaderButton color={colors.white} as='a' href='https://secure.hbg.org/events'>Get Tickets</HeaderButton>
                                <HeaderButton color={colors.white} marginleft={15} marginright={30} as={Link} to='/support/membership/'>Become a Member</HeaderButton>
                                <MenuButton role='button' onClick={openMenuPanel} small={small}>
                                    <div><div/><div/><div/></div>{!mobile && 'Menu'}
                                </MenuButton>
                            </InnerMenuBottom>}
                        </InnerMenu>
                    </HeaderContainer>
                </Menu>
            </FixedMenu>
        </Fragment>
    )
}

export const AlertBanner = styled.div`
    flex: 0 0 auto;
    width: 100%;
    min-height: 40px;
    padding: 12px 15px 10px;
    background: ${colors.red};
    font-size: 13px;
    text-align: center;
    color: ${colors.white};

    @media ${devices.small} {
        padding-left: env(safe-area-inset-left, 15px);
        padding-right: env(safe-area-inset-right, 15px);
    }
`

export const AlertBannerSpacer = styled.div`
    margin-bottom: 145px;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;

    @media ${devices.small} {
        margin-bottom: 60px;
    }
`

const AlertBannerSpacerInner = styled(AlertBanner)`
    @media ${devices.small} {
        display: none;
    }
`

const Menu = styled.div`
    display: flex;
    align-self: center;
    width: 100%;
    height: 145px;
`

const FixedMenu = styled.header.attrs(({ small, alert_offset }) => ({
    style: {
        'transform': small ? `translate3d(0,-${alert_offset + 64}px,0)` : `translate3d(0,0,0)`,
    }
}))`
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 3000;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: ${colors.darkGreen};
    transition: transform .2s;

    @media ${devices.small} {
        height: 60px;
    }
`

const HeaderContainer = styled.div`
    display: flex;
    width: calc(100% - 80px - 80px);
    height: 100%;
    margin: 0 auto;
    position: relative;

    @media ${devices.large} {
        width: calc(100% - 20px - 20px);
    }

    @media ${devices.small} {
        width: 100%;
        margin: 0 15px;
    }
`

const Logo = styled.a`
    display: block;
    background: url('/static/images/sprite.svg?v=200001') no-repeat 0 0;
`

const LogoBig = styled(Logo)`
    width: 188px;
    height: 77px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0,-50%);
    opacity: ${({ small }) => small ? '0' : '1'};
    pointer-events: ${({ small }) => small ? 'none' : 'auto'};
    transition: opacity .2s;
`

const LogoSmall = styled(Logo)`
    width: 318px;
    height: 42px;
    position: absolute;
    left: 0;
    bottom: 20px;
    background-position: 0 -106px;
    background-size: 900px 900px;
    opacity: ${({ small }) => small ? '1' : '0'};
    pointer-events: ${({ small }) => small ? 'auto' : 'none'};
    transition: opacity .2s;

    @media ${devices.small} {
        width: 212px;
        height: 28px;
        top: 16px;
        background-position: 0 -71px;
        background-size: 600px 600px;
    }
`

const HeaderButton = styled(SecondaryButton)`
    padding: 15px 25px 14px;

    ${props => props.size === 'small' ? `
        padding: 12px 15px 9px;
        border-radius: 25px;
        font-size: 15px;
        line-height: 12px;
    ` : ''};

    @media ${devices.medium_portrait} {
        display: none;
    }
`

// const LanguageToggle = styled.div`
//     padding: 2px 0 0;
//     font-size: 15px;
//     line-height: 1;
//     font-weight: bold;
//     color: ${colors.white};
//     span {
//         display: inline-block;
//         width: 1px;
//         height: 15px;
//         margin: 0 1px 0 0;
//         position: relative;
//         top: 2px;
//         border-left: 1px solid ${colors.white};
//     }
// `

const MenuButton = styled.div`
    display: flex;
    height: 34px;
    padding: 1px 0 0;
    cursor: pointer;
    font-family: 'Canela';
    font-size: 38px;
    line-height: 40px;
    color: ${colors.white};

    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 20px;
        height: 35px;
        margin: 0 8px 0 0;

        > div {
            height: 1px;
            margin: 3px 0;
            background: ${colors.white};
        }
    }

    @media ${devices.small} {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        position: absolute;
        top: 15px;
        right: -4px;

        > div {
            height: 21px;
            margin: 0;
        }
    }
`

const InnerMenu = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-left: auto;
`

const InnerMenuTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -3px 0 8px;
    opacity: ${({ small }) => small ? '0' : '1'};
    pointer-events: ${({ small }) => small ? 'none' : 'auto'};
    transition: opacity .2s;
`

const AccountLink = styled.a`
    display: inline-block;
    font-size: 17px;
    color: ${colors.white};
    text-decoration: none;
    margin: 0 20px 0 0;
`

const CartLink = styled.a.attrs(({ cartEmpty }) => ({
    style: {
        'backgroundPosition': cartEmpty ? '' : '-254px -23px',
    }
}))`
    display: inline-block;
    width: 30px;
    height: 30px;
    background: url('/static/images/sprite.svg') no-repeat -254px 7px;
    margin: 0 -5px 0 0;
`

const InnerMenuBottom = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    transform: ${({ small }) => small ? 'translate3d(0,14px,0)' : 'translate3d(0,0,0)'};
    transition: transform .2s;
`
