import { createConstants } from '../_helpers/utils'

export const gardenTypes = createConstants(
    'LOCATION_LIST_REQUEST',
    'LOCATION_LIST_SUCCESS',
    'PLANT_LIST_REQUEST',
    'PLANT_LIST_SUCCESS',
    'TOUR_LIST_REQUEST',
    'TOUR_LIST_SUCCESS',
)
