import React, { useEffect, } from 'react'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import styled from 'styled-components'

import { loadDepartmentList, loadStaffList } from '../../_actions'
import colors from '../../css/colors.scss'
import { devices, UpperHeader } from '../Common'

const StaffList = ({ dept, index }) => {
    const formatPhone = num => `${num.slice(0, 3)}.${num.slice(3, 6)}.${num.slice(6)}`
    const { name, staff_members, email, email2 } = dept

    return (
        <>
            <div style={{ marginBottom: '20px' }}>
                <UpperHeader margintop={index ? 50 : 0}>{name}</UpperHeader>
                {email && <Phone href={`mailto:${email}`}>{email}</Phone>}
                {email2 && <Phone href={`mailto:${email2}`}>{email2}</Phone>}
            </div>
            <StaffGrid>
                {staff_members.map(s =>
                    <StaffMember key={s.name}>
                        {s.image && <StaffImage background={s.image} />}
                        <Name>{s.name}</Name>
                        <Title>{s.title}</Title>
                        <Phone href={`tel:${s.phone_number}${s.phone_number_extension && `,${s.phone_number_extension}`}`}>
                            {formatPhone(s.phone_number)}{s.phone_number_extension && ` ext. ${s.phone_number_extension}`}
                        </Phone> <br />
                        {s.email && <Phone href={`mailto:${s.email}`}>{s.email}</Phone>}
                    </StaffMember>
                )}
            </StaffGrid>
        </>
    )
}

export const StaffByDept = () => {
    const dispatch = useDispatch()
    const { departments } = useSelector(state => state.support, shallowEqual)

    useEffect(() => {
        dispatch(loadDepartmentList())
    }, [])

    return departments.map((dept, i) => <StaffList key={dept.name} dept={dept} index={i} />)
}

export const Staff = ({ title }) => {
    const dispatch = useDispatch()
    const { staff } = useSelector(state => state.support, shallowEqual)

    useEffect(() => {
        dispatch(loadStaffList())
    }, [])

    return <StaffList title={title} staff={staff} />
}

const StaffGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 50px;
    column-gap: 20px;
    padding-bottom: 50px;
    border-bottom: 1px solid ${colors.darkGreen};

    &:last-child {
        padding-bottom: 0;
        border-bottom: none;
    }

    @media ${devices.small} {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        row-gap: 30px;
    }
`

const StaffMember = styled.div`
`

const StaffImage = styled.div`
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background: url(${({ background }) => background}) no-repeat 50% 50%/cover;
    margin: 0 0 20px;
`

const Name = styled.div`
    font-weight: bold;
`

const Title = styled.div`
    font-style: italic;
`

const Phone = styled.a`
    text-decoration: underline;
    color: ${colors.darkGreen};
    display: block;
`
