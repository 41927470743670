import React, { useEffect } from 'react'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { loadLocationList } from '../../_actions'
import { CreateMap } from '../../_helpers'
import colors from '../../css/colors.scss'
import { SecondaryButton, UpperHeader, devices } from '../Common'
import { PageWithMenu } from '../Content'


const VisitMap = () => {
    const dispatch = useDispatch()
    const { locationList } = useSelector(state => state.garden, shallowEqual)

    useEffect(() => {
        if (!locationList.locations) dispatch(loadLocationList())
    }, [])

    useEffect(() => {
        if (locationList.locations) CreateMap('visit', locationList)
    }, [locationList])

    return (
        <>
            <MapWrap>
                <div id="map"></div>
                <MapCompass/>
            </MapWrap>
            <MapLegend>
                <MapLegendTitle>Map Key</MapLegendTitle>
                <MapLegendKeys>
                    <MapLegendKeysWrap>
                        {locationList.keys.length && locationList.keys.map((data, i) =>
                            <MapLegendKey key={i}>
                                <MapLegendKeyImage image={data.icon} />
                                {data.title}
                            </MapLegendKey>
                        )}
                    </MapLegendKeysWrap>
                    <MapLegendKeysText>All walking trails are ADA-compliant.</MapLegendKeysText>
                </MapLegendKeys>
            </MapLegend>

            <Landmarks>
                <LandmarksTitle>Landmark Title</LandmarksTitle>
                <LandmarkColumns>
                    {locationList.locations && locationList.locations.map((data, i) =>
                        <Landmark key={i}>
                            <LandmarkNumber>{i + 1}</LandmarkNumber>
                            <LandmarkInfo>
                                <LandmarkName>{data.title}</LandmarkName>
                                <LandmarkDesc dangerouslySetInnerHTML={{ __html: data.short_description }}></LandmarkDesc>
                                <LandmarkButton as={Link} to={`/gardens/#${data.slug}`}>Learn More</LandmarkButton>
                            </LandmarkInfo>
                        </Landmark>
                    )}
                </LandmarkColumns>
            </Landmarks>
        </>
    )
}

const MapWrap = styled.div`
    width: 100%;
    height: 0;
    padding-bottom: 66.6666%;
    position: relative;
    margin: 0 0 30px;
    #map {
        width: 100%;
        height: 100%;
        position: absolute;
        background: #F5F1E5;
        .ol-hidden {
            display: none;
        }
        .ol-overlaycontainer-stopevent {
            pointer-events: none;
            .ol-zoom {
                display: inline-flex;
                flex-direction: column;
                position: absolute;
                top: 15px;
                left: 15px;
                pointer-events: auto;
                button.ol-zoom-in,
                button.ol-zoom-out {
                    width: 30px;
                    height: 30px;
                    margin: 0;
                    padding: 0;
                    background: rgba(255,255,255,.8) url('/static/images/sprite.svg') no-repeat 0 -753px;
                    backdrop-filter: blur(4px);
                    border: 0;
                    border-radius: 0;
                    color: rgba(0,0,0,0);
                    cursor: pointer;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                }
                button.ol-zoom-out {
                    margin: 6px 0 0;
                    background-position: -30px -753px;
                }
            }
        }
        .marker-landmark {
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: default;
            .marker {
                width: 30px;
                height: 30px;
                background: ${colors.blue};
                border-radius: 100%;
                font-family: 'NB International Pro';
                font-size: 16px;
                line-height: 32px;
                font-weight: bold;
                color: ${colors.white};
                text-align: center;
            }
            .label {
                display: flex;
                width: 240px;
                position: absolute;
                pointer-events: none;
                > div {
                    display: inline-flex;
                    align-items: center;
                    max-width: 240px;
                    padding: 4px 12px 3px;
                    background: ${colors.blue};
                    border-radius: 12px;
                    font-family: 'NB International Pro';
                    font-size: 12px;
                    line-height: 1.4;
                    color: ${colors.white};
                    pointer-events: auto;
                }
                &.top-left {
                    justify-content: flex-end;
                    right: 24px;
                    bottom: 24px;
                }
                &.top-right {
                    justify-content: flex-start;
                    left: 24px;
                    bottom: 24px;
                }
                &.bottom-left {
                    justify-content: flex-end;
                    right: 24px;
                    top: 24px;
                }
                &.bottom-right {
                    justify-content: flex-start;
                    left: 24px;
                    top: 24px;
                }
            }
        }
    }

    @media ${devices.small} {
        width: 100vw;
        margin-left: -15px;
        padding-bottom: calc(100vh - 60px);
    }
`

const MapCompass = styled.div`
    width: 68px;
    height: 68px;
    background: url('/static/images/sprite.svg') no-repeat 0 -887px;
    position: absolute;
    bottom: 15px;
    right: 15px;
    pointer-events: none;
`

const MapLegend = styled.div`
    width: 100%;
    margin: 0 0 30px;
`

const MapLegendTitle = styled(UpperHeader)``

const MapLegendKeys = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    margin: 10px 0 0;
    padding: 10px 0;
    border-top: 1px solid ${colors.darkGreen};
    border-bottom: 1px solid ${colors.darkGreen};
    font-size: 13px;
    line-height: 1.4;
    color: ${colors.black};

    @media ${devices.small} {
        display: block;
        margin: -10px 0 0;
    }
`

const MapLegendKeysWrap = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px 20px;
    margin: -10px -15px;

    @media ${devices.medium} {
        padding: 10px 0;
    }
`

const MapLegendKeysText = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-left: 1px solid ${colors.darkGreen};

    @media ${devices.medium} {
        padding: 10px 0 10px 20px;
    }

    @media ${devices.small} {
        display: block;
        margin: 10px 0 0;
        padding: 10px 0 0;
        border-left: 0;
        border-top: 1px solid ${colors.darkGreen};
    }
`

const MapLegendKey = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 15px;
    padding-top: 2px;

    @media ${devices.small} {
        width: calc(50% - 30px);
    }
`

const MapLegendKeyImage = styled.div`
    flex-shrink: 0;
    width: 26px;
    height: 26px;
    margin: -2px 8px 0 0;
    background-size: 26px 26px;
    background-image: url('${image => image.image}');
`

const Landmarks = styled.div`
    margin: 30px 0 0;
`

const LandmarksTitle = styled(UpperHeader)`
    margin: 0 0 20px;
`

const LandmarkColumns = styled.div`
    margin: 0 0 -30px;
    -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2;
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 30px;

    @media ${devices.small} {
        -webkit-columns: 1;
        -moz-columns: 1;
        columns: 1;
        -webkit-column-gap: 0;
        -moz-column-gap: 0;
        column-gap: 0;
    }
`

const Landmark = styled.div`
    display: inline-block;
    width: 100%;
    margin: 0 0 30px;
    position: relative;
    color: ${colors.blue};
`

const LandmarkNumber = styled.div`
    width: 30px;
    height: 30px;
    position: absolute;
    top: 4px;
    left: 0;
    background: ${colors.blue};
    border-radius: 100%;
    font-family: 'NB International Pro';
    font-size: 16px;
    line-height: 33px;
    font-weight: bold;
    color: ${colors.white};
    text-align: center;
    text-indent: 1px;
`

const LandmarkInfo = styled.div`
    padding: 0 0 0 50px;
`

const LandmarkName = styled.div`
    font-family: Canela, sans-serif;
    font-size: 31px;
`

const LandmarkDesc = styled.div``

const LandmarkButton = styled(SecondaryButton)`
    margin-top: 10px;
    padding: 10px 25px 9px;
    border: 1px solid ${colors.blue};
    color: ${colors.blue};
    font-size: 12px;
`

const VisitMapPage = () => <PageWithMenu ChildComponent={VisitMap} />
export default VisitMapPage
