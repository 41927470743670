import React, { Fragment } from 'react'

import styled from 'styled-components'

import { /*stripTags, */useFormInput } from '../../../_helpers'
import colors from '../../../css/colors.scss'
import {
    ArticleTextWrap,
    PrimaryButton,
    // TextInput,
    Header,
    devices,
} from '../../Common'


export const Donation = ({ pageBlock: { intro, title, /*content, content2, content3*/ } }) => {
    const submit = e => {
        e.preventDefault()
        window.location = `https://secure.hbg.org/donate/contribute2/?amount=${amount.value}`
    }
    const amount = useFormInput('Type in your amount here', '', submit)
    return (
        <Fragment>
            <Header fontSize={60} marginbottom={20} unsetmaxwidth>{title}</Header>
            <ArticleTextWrap dangerouslySetInnerHTML={{ __html: intro }} />
            <DonationContainer>
                <ButtonContainer>
                    <FirstDonation as='a' href='https://secure.hbg.org/donate/q/annual/'>Donate Now</FirstDonation>
                    {/*
                    <FirstDonation as='a' href={`https://secure.hbg.org/donate/contribute2/?amount=${stripTags(content)}`}>${stripTags(content)}</FirstDonation>
                    <SecondDonation as='a' href={`https://secure.hbg.org/donate/contribute2/?amount=${stripTags(content2)}`}>${stripTags(content2)}</SecondDonation>
                    <ThirdDonation as='a' href={`https://secure.hbg.org/donate/contribute2/?amount=${stripTags(content3)}`}>${stripTags(content3)}</ThirdDonation>
                    */}
                </ButtonContainer>

                <MailDonationContainer>
                    <p>To donate by mail, send gifts to:</p>
                    <p>Houston Botanic Garden<br/>
                        8205 N. Bayou Drive<br/>
                        Houston, TX 77017</p>
                </MailDonationContainer>

                {/*
                <OtherDonationContainer>
                    <TextInput {...amount} marginbottom={0} width={width < 1180 ? width > 740 ? '80%' : '100%' : '300px'} type='number' />
                    <Separator>OR</Separator>
                    <PrimaryButton as='a' href='mailto:info@hbg.org?subject=Donation'>Send us an Email</PrimaryButton>
                </OtherDonationContainer>
                */}
            </DonationContainer>
        </Fragment>
    )
}

const DonationContainer = styled.div``

const ButtonContainer = styled.div`
    display: flex;
    margin-top: 30px;

    @media ${devices.small} {
        flex-direction: column;
    }
`

const FirstDonation = styled(PrimaryButton)`
    /*
    background: ${colors.darkGreen};
    border: 1px solid ${colors.darkGreen};
    width: 200px;

    @media ${devices.small} {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
        width: 100%;
    }
    */
`

/*
const SecondDonation = styled(FirstDonation)`
    background: ${colors.yellow};
    border: 1px solid ${colors.yellow};
    margin: 0 60px;
`

const ThirdDonation = styled(FirstDonation)`
    background: ${colors.blue};
    border: 1px solid ${colors.blue};
`

const Separator = styled.div`
    display: flex;
    align-items: center;
    margin: 0 40px;

    @media ${devices.small} {
        margin-top: 20px;
    }
`
*/

const MailDonationContainer = styled.div`
    margin-top: 30px;

    p {
        margin: 0 0 15px;
    }
`

/*
const OtherDonationContainer = styled(ButtonContainer)`
    @media (max-width: 1109px) {
        flex-wrap: wrap;
        justify-content: center;
        ${PrimaryButton} {
            width: 80%;
            margin-top: 20px;
        }

        ${Separator} {
            margin-top: 20px;
        }
    }

    @media ${devices.small} {
        margin-top: 0;
        align-items: center;

        ${PrimaryButton} {
            width: 100%;
            margin-top: 20px;
        }
    }
`
*/
