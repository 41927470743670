import React from 'react'

import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
    SimpleBlock,
    SimpleCarousel,
    FeaturedPlant,
    FeaturedGardens,
    FeaturedMembership,
    PhotoGrid,
    Instagram,
    Hours,
    Tickets,
    Accordion,
    Donation,
    List,
    NewsList,
    Contact,
    FeaturedEvents,
    PrivateVenueRentals,
    LightscapeMap,
} from '../'
import { useWindowSize } from '../../_helpers'
import colors from '../../css/colors.scss'
import {
    Container,
    Grid,
    UpperHeader,
    devices,
    PrimaryButton,
} from '../Common'
import { Staff, StaffByDept } from '../Support/Staff'


export const PageBlock = ({ pageBlock, siblings, border, paddingtop, page_menu }) => {
    const { format, title } = pageBlock
    const { width } = useWindowSize()
    const mobile = width <= 740

    if (mobile && pageBlock.format === 'LEFT-IMAGE') {
        return (
            <MobileImageBlock bg={pageBlock.image}>
                <MobileText>
                    <h2>{title}</h2>
                    <div dangerouslySetInnerHTML={{ __html: pageBlock.content2 }} />
                    {pageBlock.link_text2 ? (
                        <PrimaryButton
                            as={pageBlock.linked_page2 ? Link : 'a'}
                            to={pageBlock.linked_page2 ? pageBlock.linked_page2 : ''}
                            href={pageBlock.external_url2}
                        >
                            {pageBlock.link_text2}
                        </PrimaryButton>
                    ) : ''}
                </MobileText>
            </MobileImageBlock>
        )
    }

    return (
        <DynamicWidthBlock
            paddingtop={border || paddingtop ? 50 : 0}
            paddingbottom={50}
            border={border}
            page_menu={page_menu}
            isFeaturedPlant={format === 'FEATURED-PLANT'}
            id={pageBlock.hash}
        >
            <BlockContainer
                page_menu={page_menu}
            >
                {pageBlock.hash_url ? <div id={pageBlock.hash_url.toLowerCase().replace(/ /g, '_')} /> : ''}
                {format === 'CAROUSEL' ? <SimpleCarousel slides={pageBlock.subblocks} content={pageBlock.content} smallTitle={pageBlock.small_title} /> : ''}
                {format === '1-COLUMN' ? <SimpleBlock pageBlock={pageBlock} siblings={siblings} columns={1} /> : ''}
                {format === '2-COLUMN-LINK' || format === 'LEFT-IMAGE' || format === 'RIGHT-IMAGE' ? (
                    <Grid columns={2} totalmargin={15}>
                        {['', '2'].map(id => (
                            <SimpleBlock
                                key={`#${id}`}
                                id={id}
                                pageBlock={pageBlock}
                                textPadding={'20%'}
                                columns={2}
                            />
                        ))}
                    </Grid>
                ) : ''}
                {format === '3-COLUMN' ? (
                    <>
                        {pageBlock.show_title && !pageBlock.title2 ? <UpperHeader marginbottom={20}>{pageBlock.title}</UpperHeader> : ''}
                        <Grid columns={3} totalmargin={15} margintop={!pageBlock.title2 ? 0 : 0}>
                            {['', '2', '3'].map(id => (
                                <SimpleBlock
                                    key={`#${id}`}
                                    id={id}
                                    pageBlock={pageBlock}
                                    textPadding={!pageBlock.title2 ? '10%' : 0}
                                    columns={3}
                                    hideTitle={true}
                                    square={true}
                                />
                            ))}
                        </Grid>
                    </>
                ) : ''}
                {format === 'GRID' && <PhotoGrid title={pageBlock.title} show_title={pageBlock.show_title} gridItems={pageBlock.subblocks} />}
                {format === 'DONATION' && <Donation pageBlock={pageBlock} />}
                {format === 'INSTAGRAM' && <Instagram title={pageBlock.title} text={pageBlock.content} images={pageBlock.subblocks} mobile={mobile} />}
                {format === 'FEATURED-EVENTS' && <FeaturedEvents title={pageBlock.title} events={pageBlock.subblocks} mobile={mobile} />}
                {format === 'FEATURED-GARDENS' && <FeaturedGardens title={pageBlock.title} gardens={pageBlock.subblocks} page_menu={page_menu} />}
                {['FEATURED-HOURS', 'CAFE-HOURS'].includes(format) && <Hours title={pageBlock.title} cafe={format === 'CAFE-HOURS'} />}
                {format === 'FEATURED-PLANT' && <FeaturedPlant title={pageBlock.title} plantInfo={pageBlock.subblocks} />}
                {format === 'FEATURED-TICKETS' && <Tickets title={pageBlock.title} tickets={pageBlock.subblocks} />}
                {format === 'FEATURED-MEMBERSHIP' && <FeaturedMembership title={pageBlock.title} memberships={pageBlock.subblocks} color='#DCEEDB' />}
                {format === 'FEATURED-GIVING-CIRCLE' && <FeaturedMembership title={pageBlock.title} memberships={pageBlock.subblocks} color='#FDEFEC' />}
                {format === 'FEATURED-BLOOMERS' && <FeaturedMembership title={pageBlock.title} memberships={pageBlock.subblocks} color='#FDEFEC' />}
                {format === 'QA' && <Accordion pageBlock={pageBlock} items={pageBlock.subblocks} type={'question'} />}
                {format == 'LIST-WITHOUT-PADDING' ? <List pageBlock={pageBlock} /> : ''}
                {format == 'NEWS' ? <NewsList /> : ''}
                {format == 'LIGHTSCAPE-MAP' ? <LightscapeMap title={pageBlock.title} /> : ''}
                {format == 'CONTACT' ? <Contact pageBlock={pageBlock} /> : ''}
                {format === 'STAFF' ? <Staff title={pageBlock.title} /> : ''}
                {format === 'STAFF-BY-DEPT' ? <StaffByDept /> : ''}
                {/*{format === 'TIMELINE' && <Timeline />}*/}
                {format === 'PRIVATE-VENUE-RENTALS' ? <PrivateVenueRentals pageBlock={pageBlock} /> : ''}
            </BlockContainer>
        </DynamicWidthBlock>
    )
}

const DynamicWidthBlock = styled.div`
    ${({ paddingtop }) => paddingtop ? `
        padding-top: ${paddingtop}px;
    ` : ''}

    ${({ paddingbottom }) => paddingbottom ? `
        padding-bottom: ${paddingbottom}px;
    ` : ''}

    ${({ border }) => border && `
        &:before {
            content: "";
            display: block;
            position: relative;
            top: -50px;
            border-top: 1px solid ${colors.darkGreen};
        }
    `}

    ${({ border, page_menu }) => border && !page_menu && `
        &:before {
            width: calc(100% - 80px - 80px);
            max-width: 1180px;
            margin-left: auto;
            margin-right: auto;

            @media ${devices.large} {
                width: calc(100% - 40px - 40px);
            }

            @media ${devices.small} {
                width: calc(100% - 15px - 15px);
            }
        }
    `}

    ${({ isFeaturedPlant }) => isFeaturedPlant && `
        @media ${devices.small} {}
        color: ${colors.white};
        background-color: ${colors.darkGreen};
    `}
`

const BlockContainer = styled(Container)`
    ${({ page_menu }) => page_menu && `
        max-width: 100% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    `}
`

const MobileImageBlock = styled.div`
    background: url(${({ bg }) => bg}) center/cover no-repeat;
    height: 100vh;
`

const MobileText = styled.div`
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    color: ${colors.white};
    height: 100%;
    background-image: linear-gradient(-180deg, rgba(0,0,0,0) 0%, ${colors.darkGreen} 100%);

    h2 {
        margin-bottom: 20px;
    }

    ${PrimaryButton} {
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
    }
`
